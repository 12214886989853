import {createPinia} from "pinia";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

const store = createPinia()

/**
 * piniaPluginPersistedstate 是一个用于 Pinia 的插件，它可以帮助你持久化存储你的 Pinia 状态。
 * Pinia 是一个用于 Vue.js 的状态管理库，类似于 Vuex，但是更简单和灵活。
 * 当你使用 store.use(piniaPluginPersistedstate)，
 * 你的 Pinia 应用的状态将被持久化，这意味着即使在用户刷新页面或关闭浏览器后，
 * 应用的状态也会被保存并在下次用户访问时恢复
 */
store.use(piniaPluginPersistedstate)

export const setupStore = (app) => {
    app.use(store)
}

export { store }