<template  id="app" ref="app" style="height: 100%;width: 100%;  background: linear-gradient(to bottom, #4CA1AF, #C4E0E5);">
  <router-view  ref="routerview"  style="width: 100%;height: 100%"></router-view>
</template>
<script>


export default {
  name: 'App'
}

// 解决ERROR ResizeObserver loop completed with undelivered notifications.

//问题的

const debounce = (fn, delay) => {

  let timer = null;

  return function () {

    let context = this;

    let args = arguments;

    clearTimeout(timer);

    timer = setTimeout(function () {

      fn.apply(context, args);

    }, delay);

  }

}

// 解决ERROR ResizeObserver loop completed with undelivered notifications.

//问题的

const _ResizeObserver = window.ResizeObserver;

window.ResizeObserver = class ResizeObserver extends _ResizeObserver {

  constructor(callback) {

    callback = debounce(callback, 16);

    super(callback);

  }

}

</script>

<style>
#app {
  height: 100vh;
  /*每次打开对话框总是会给body加上width: calc(100% -19px)的bug
  */
  width: 100% !important;
}
</style>
