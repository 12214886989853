<!--
标签管理界面
最后编辑人：郑志强
最后编辑时间：2022-3-8
最后编辑细节：由AXIOS对错误信息进行拦截处理，删除每个函数的错误处理。
-->
<template>
  <el-container class="base-container">
    <el-main class="main-container">
      <el-tabs v-model="activeType">
        <el-tab-pane
            :key="item.type"
            v-for="item in typeData"
            :label="item.label"
            :name="item.type"
        >
        </el-tab-pane>
      </el-tabs>
      <el-button type="primary" size="small" icon="Plus" @click="showAdd" style="margin-bottom: 10px;">标签</el-button>
      <el-tabs v-model="activeCata" tabPosition="left">
        <el-tab-pane
            :key="item"
            v-for="item in catalogData"
            :label="item"
            :name="item"
        >
          <el-tag
              :key="tag.tag_id"
              v-for="tag in tags"
              effect="plain"
              class="prop-tag"
              closable
              @click="showEdit(tag)"
              @close="tagDelete(tag)"
              style="cursor: pointer">
            {{tag.tag_name}}
          </el-tag>
        </el-tab-pane>
      </el-tabs>

      <el-dialog :title="dialogTitle" v-model="dialogVisi" width="450px" :close-on-click-modal="false" :close-on-press-escape="false">
        <el-form :model="dialogData" :rules="rules" ref="tagForm" label-width="100px">
          <el-form-item label="标签分类" prop="catalog">
            <el-input v-model="dialogData.catalog" :disabled="isEdit"></el-input>
          </el-form-item>
          <el-form-item label="标签名称" prop="name">
            <el-input v-model="dialogData.name"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="closeDialog">取 消</el-button>
          <el-button type="primary" @click="toSave">保 存</el-button>
        </div>
      </el-dialog>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: "Tag",
  data () {
    return {
      activeType: "unit_tag",
      activeCata: "",
      typeData: [
        {type: "unit_tag", label: "单位属性"},
        {type: "student_tag", label: "学生属性"},
        {type: "subject", label: "科目"}
      ],
      tagData: [],
      catalogData: [],
      tags: [],
      dialogVisi: false,
      dialogTitle: "",
      isEdit: false,
      dialogData: {
        tid: "",
        type: "",
        name: "",
        catalog: ""
      },
      rules: {
        name: [{required: true, message: "请填写标签名称", trigger: "blur"}, {max: 20, message: "不能超过20个字", trigger: "blur"}],
        catalog: [{required: true, message: "请填写标签分类", trigger: "blur"}, {max: 10, message: "不能超过10个字", trigger: "blur"}]
      },
    }
  },
  watch: {
    tagData (val) {
      this.filterCatalog(val)
      if (this.catalogData.length > 0)
        this.activeCata = this.catalogData[0]
      else
        this.activeCata = ""
    },
    activeType () {
      this.filterCatalog(this.tagData)
      if (this.catalogData.length > 0)
        this.activeCata = this.catalogData[0]
      else
        this.activeCata = ""
    },
    activeCata () {
      this.filterTag(this.tagData)
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    //载入标签列表
    loadData () {
      let org = this.activeCata
      this.axios.get("/api/tag/loadalltags")
          .then((response) => {
            if (response.data.success) {
              this.tagData = response.data.result
              if (org === this.activeCata)
                this.filterTag(this.tagData)
            }
          })

    },
    //过滤标签分类
    filterCatalog (val) {
      let catas = []
      val.forEach((v) => {
        if (v.tag_type === this.activeType && catas.indexOf(v.tag_catalog) === -1)
          catas.push(v.tag_catalog)
      })
      this.catalogData = catas
    },
    //过滤标签
    filterTag (val) {
      let ts = []
      if (this.activeCata !== "") {
        val.forEach((v) => {
          if (v.tag_type === this.activeType && v.tag_catalog === this.activeCata)
            ts.push(v)
        })
      }
      this.tags = ts
    },
    //删除标签
    tagDelete (tag) {
      this.$confirm('删除此标签将影响相关单位和用户，确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.post("/api/tag/deltag", {tid: tag.tag_id})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "标签删除成功", type: "success"})
                this.loadData()
              }
            })

      })
    },
    //关闭对话框
    closeDialog () {
      this.$refs["tagForm"].resetFields()
      this.dialogVisi = false
    },
    //弹出新增对话框
    showAdd () {
      this.dialogData.type = this.activeType
      this.dialogData.tid = ""
      this.isEdit = false
      this.dialogTitle = "新增标签"
      this.dialogVisi = true
    },
    //弹出编辑对话框
    showEdit (tag) {
      this.dialogData.type = this.activeType
      this.dialogData.catalog = this.activeCata
      this.dialogData.name = tag.tag_name
      this.dialogData.tid = tag.tag_id
      this.isEdit = true
      this.dialogTitle = "编辑标签"
      this.dialogVisi = true
    },
    //保存
    toSave () {
      this.$refs["tagForm"].validate((valid) => {
        if (valid) {
          this.axios.post("/api/tag/savetag", this.dialogData)
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "标签保存成功", type: "success"})
                  this.loadData()
                  this.closeDialog()
                }
              })
        }
        else
          return false
      })
    }
  }
}
</script>

<style scoped>
.prop-tag {
  margin-right: 15px;
}
</style>
