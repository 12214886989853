<!--
日志查询
最后编辑人：郑志强
最后编辑时间：2022-3-11
最后编辑细节:新增
-->
<template>
  <el-container class="base-container">
    <el-header style="height: 20px;">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>日志管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="main-container">
      <div class="row">
        <div>查找日志：</div>
        <el-input siz="default" placeholder="请输入账号或者用户名" v-model="search.username" @keyup.enter="doSearch"
                  style="width: 200px; min-width: 80px;">
        </el-input>
        <el-input siz="default" placeholder="请输入请求地址" v-model="search.request_url" @keyup.enter="doSearch"
                  style="width: 200px; min-width: 80px;">
        </el-input>
        <el-date-picker
            siz="default"
            v-model="time"
            type="datetimerange"
            range-separator="至"
            value-format="YYYY-MM-DD HH:mm:ss"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
        <el-button siz="default" @click="doSearch" icon="Search">查询</el-button>
      </div>
      <el-table
          :data="tableData"
          style="width: 100%">
        <el-table-column prop="log_id" label="日志ID" width="120"></el-table-column>
        <el-table-column prop="request_time" label="请求时间" width="180"></el-table-column>
        <el-table-column prop="return_time" label="结束时间" width="180"></el-table-column>
        <el-table-column prop="ip_address" label="IP地址" width="120"></el-table-column>
        <el-table-column prop="request_url" label="请求URL" width="180"></el-table-column>
        <el-table-column prop="request_params" label="请求参数" :show-overflow-tooltip="true" width="180"></el-table-column>
        <el-table-column prop="return_content" label="返回内容" :show-overflow-tooltip="true" ></el-table-column>
        <el-table-column prop="proc_success" label="请求状态" width="180">
          <template v-slot="scope">
            {{scope.proc_success==='1'?'成功':'失败'}}
          </template>
        </el-table-column>
        <el-table-column prop="user_id" label="用户ID" width="180"></el-table-column>
        <el-table-column prop="user_account" label="账号名称" width="180"></el-table-column>

      </el-table>
      <el-pagination
          @current-change="toPage"
          :current-page="search.page"
          :page-size="search.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="total"
          style="margin-left: 10px">
      </el-pagination>
    </el-main>
  </el-container>

</template>

<script>
export default {
  name: "SysLog",
  data() {

    return {
      time:[],
      total: 0,
      search: {
        page: 1,
        pagesize: 15,
        username: "",
        request_url: "",
        stime: "",
        etime: ""
      },
      tableData: [],
    }
  },

  mounted() {
   this.loadData()
  },
  methods: {
    loadData() {
      if (this.time!==null &&  this.time.length !== 0) {
        this.search.stime = this.time[0]
        this.search.etime = this.time[1]
      }else {
        this.search.stime=""
        this.search.etime=""
      }
      this.axios.post("/api/log/listlog", this.search).then((response) => {
        if (response.data.success) {
          this.total = response.data.result.totalrecords
          this.tableData = response.data.result.datalist
        }
      })
    },
    toPage (page) {
      this.search.page = page
      this.loadData()
    },
    doRefrsh() {
      this.search.page = 1
      this.loadData()
    },
    doSearch() {
      this.loadData()
    }
  }
}
</script>

<style scoped>

</style>