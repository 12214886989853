<!--
教师管理界面
最后编辑人：郑志强
最后编辑时间：2022-3-8
最后编辑细节：由AXIOS对错误信息进行拦截处理，删除每个函数的错误处理。
-->
<template>
  <el-container class="base-container">
    <el-container class="main-container">
      <el-aside id="unitTree" style="padding: 20px;" v-if="userStore.user.type != 3">
        <div class="row">
          <el-input
              siz="default"
              placeholder="请输入单位名称"
              prefix-icon="Search"
              v-model="filterName"
              style="width: 200px; min-width: 80px;"></el-input>
        </div>
        <el-tree
            :data="treeData"
            :props="treeProps"
            node-key="unit_id"
            default-expand-all
            :filter-node-method="filterNode"
            :expand-on-click-node="false"
            :highlight-current="true"
            @node-click="clickNode"
            ref="unitTree">
        </el-tree>
      </el-aside>
      <el-main style="border-left: 1px solid #EBEEF5;">
        <div style="display: flex">
          <div style="flex-grow: 1">
            <el-space>
              <el-input  placeholder="用户姓名" v-model="search.username" @keyup.enter="toSearch" style="width: 200px; min-width: 80px;">
              </el-input>
              <el-button  @click="toSearch" icon="Search" bg text>查询</el-button>
            </el-space>
          </div>
          <div style="width: 300px">
            <el-popover
                trigger="click"
                ref="popoverUpload"
                placement="bottom"
                :visible="uploadVisi"
                width="360px">
              <template #reference>
                <el-button  bg
                            text type="success" siz="default" icon="upload" @click="showUpload" :disabled="!clickUnit.parent_id">导入</el-button>
              </template>
              <div style="width: 100%; text-align: right;"><el-button link icon="Close" @click="closeUpload"></el-button></div>
              <el-upload
                  action="/api/info/user/importteacher"
                  :data="uploadData"
                  name="excelfile"
                  ref="upload"
                  drag
                  :multiple="false"
                  :before-upload="checkUpload"
                  :on-success="afterSuccess">
                <i class="upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <template #tip>
                  <div class="el-upload__tip">只能上传xls或xlsx文件，且不超过10MB</div>
                </template>
              </el-upload>
            </el-popover>
            <el-button type="primary" text bg icon="download" :disabled="!clickUnit.parent_id" @click="toDownload">下载模板</el-button>
            <el-button type="primary"
                       bg
                       text
                       @click="showAdd"
                       icon="Plus">用户</el-button>
            <a id="downlink" href="/api/info/user/downimporttemplate" v-show="false"></a>
          </div>
        </div>
        <el-table :data="tableData" stripe>
          <el-table-column label="单位" min-width="150px" prop="unit_name">
          </el-table-column>
          <el-table-column prop="user_name" label="用户名称" min-width="100"></el-table-column>
          <el-table-column prop="user_account" label="用户账号" min-width="120"></el-table-column>
          <el-table-column prop="cellphone" label="手机号" min-width="100"></el-table-column>
          <el-table-column label="角色" min-width="200">
            <template v-slot="{row}">
              <el-space size="small" direction="vertical">
                <el-tag v-for="(type,index) in row.user_types"
                        :key="index"
                >{{uTypesMap[type]?.code_name}}</el-tag>
              </el-space>
            </template>
          </el-table-column>
          <el-table-column prop="valid_until" label="有效期" min-width="120"></el-table-column>
          <el-table-column label="操作" fixed="right" width="250">
            <template v-slot="scope">
<!--              <el-tooltip content="切换状态" placement="top" effect="light" transition="" :enterable="false">-->
<!--                <el-switch-->
<!--                    v-model="scope.row.state"-->
<!--                    active-color="#13ce66"-->
<!--                    active-value="1"-->
<!--                    inactive-value="0"-->
<!--                    style="margin-right: 10px;"-->
<!--                    @change="toSwitch(scope.row)">-->
<!--                </el-switch>-->
<!--              </el-tooltip>-->
              <el-popover
                  trigger="click"
                  :ref="'popover-' + scope.row.user_id"
                  placement="left"
                  width="240px">
                <div>新密码：<el-input v-model="resetPwd" siz="default" style="width: 140px"></el-input></div>
                <div class="pwd-tip">如果不输入新密码则默认重置为az741369</div>
                <div style="text-align: right; margin: 0">
                  <el-button type="primary" size="default" @click="toResetPwd(scope.row)">确定</el-button>
                </div>
                <template #reference>
                  <el-button class="table-button"  @click="popReset(scope.row)">重置密码</el-button>
                </template>
              </el-popover>
              <el-button class="table-button" @click="showEdit(scope.row)">编辑</el-button>
              <el-button class="table-button" @click="toDel(scope.row)" type="danger">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            @current-change="toPage"
            :current-page="search.page"
            :page-size="search.pagesize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </el-main>
    </el-container>

    <el-dialog :title="formTitle" v-model="dialogVisi" width="750px" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-form :model="userData" :rules="rules" ref="userForm" label-width="150px">
        <el-form-item label="单位">
          <span>{{ userStore.user.type == "3" ? userStore.unit.name : userData.unit_name}} </span>
        </el-form-item>
        <el-form-item label="用户账号" prop="useraccount" v-show="userData.userid!==null">
          <span>{{ this.userData.useraccount }}</span>
        </el-form-item>
        <el-form-item label="登录密码" prop="userpwd" v-show="userData.userid===null">
          <el-input type="password" v-model="userData.userpwd" :disabled="userData.userid!==null || unedit"></el-input>
        </el-form-item>
        <el-form-item label="用户名称" prop="username">
          <el-input v-model="userData.username" :disabled="unedit"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="user_types">
          <el-select multiple v-model="userData.user_types" @remove-tag="userTypeRemove">
            <el-option v-for="item in userTypes"
                      :key="item.code"
                      :value="item.code"
                       :disabled="(userData.unit_parent_id === null && item.code !== '2') || (userData.unit_parent_id !== null && ['1','2','6','9','10'].includes(item.code)) "
                       :label="item.code_name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学科教研员科目" v-show="userData.user_types.includes('7')"> <!--7是学科教研员-->
          <el-select v-model="userData.researcherSubject" multiple>
            <el-option v-for="item in subjectTags"
                       :key="item.tag_id"
                       :label="item.tag_name"
                       :value="item.tag_id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="班主任所带班级" v-show="userData.user_types.includes('11')"> <!--11是班主任-->
          <el-checkbox-group v-model="userData.classLeaderClass" >
            <el-checkbox
                v-for="(item,index) in schoolGradesClass"
                :key="index"
                :label="item.grade + ' ' + item.class_name "
                :value="item.grade_id+ '-' + item.grade + '-' + item.class_id + '-' + item.class_name + '-' + item.school"
            />
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="任课教师科目班级" v-show="userData.user_types.includes('8')"> <!--8是任课教师-->
          <el-select v-model="userData.teacherSubject">
            <el-option v-for="item in subjectTags"
                       :key="item.tag_id"
                       :label="item.tag_name"
                       :value="item.tag_id"
            />
          </el-select>
          <el-checkbox-group v-model="userData.teacherGradeClass[userData.teacherSubject]" :disabled="userData.teacherSubject === null">
            <el-checkbox
                v-for="(item,index) in schoolGradesClass"
                :key="index"
                :label="item.grade + ' ' + item.class_name "
                :value="item.grade_id+ '-' + item.grade + '-' + item.class_id + '-' + item.class_name + '-' + item.school"
            />
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="年级组长所属年级" v-show="userData.user_types.includes('5')"> <!--5是年级组长-->
          <el-checkbox-group v-model="userData.gradeLeadergrades">
            <el-checkbox
                v-for="(grade,grade_id) in schoolGrades"
                :key="grade_id"
                :label="grade"
                :value="{grade_id:parseInt(grade_id),grade}"
            />
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="手机号" prop="cellphone">
          <el-input v-model="userData.cellphone" :disabled="unedit"></el-input>
        </el-form-item>
        <el-form-item label="Email" prop="email">
          <el-input v-model="userData.email" :disabled="unedit"></el-input>
        </el-form-item>
        <el-form-item label="有效期" prop="until">
          <el-date-picker
              v-model="userData.until"
              type="date"
              format="YYYY-MM-DD"
              date-format="YYYY-MM-DD ddd"
              value-format="YYYY-MM-DD"
              time-format="YYYY-MM-DD"
              style="width: 140px"
              :disabled="unedit"
              :clearable="false"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="toSave">保 存</el-button>
      </template>
    </el-dialog>

    <el-dialog title="导入数据出错" v-model="errorVisi" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-table :data="errorData">
        <el-table-column prop="row" label="行号" width="80"></el-table-column>
        <el-table-column prop="msg" label="用户账号" width="600"></el-table-column>
      </el-table>
    </el-dialog>
  </el-container>
</template>

<script>

import {useUserStoreWithOut} from "@/store/modules/user";
import {useCodeStoreWithOut} from "@/store/modules/code";
import {useTagStoreWithOut} from "@/store/modules/tag";

export default {
  name: "TeacherUser",
  setup() {
    const userStore = useUserStoreWithOut()
    const codeStore = useCodeStoreWithOut()
    const tagStore = useTagStoreWithOut()
    return {userStore,codeStore,tagStore}
  },
  data () {
    return {
      filterUserTypes:[], // 根据当前用户筛选过的角色list
      clickUnit: {}, // 左侧点击的单位
      userTypes:[],
      filterName: "",
      treeData: [],
      treeProps: {
        children: 'subunit',
        label: 'unit_name'
      },
      uploadVisi: false,
      uploadData:{
        unitid: null
      },
      errorVisi: false,
      errorData: [],
      tableTitle: "",
      total: 0,
      search: {
        page: 1,
        pagesize: 15,
        unitid: null,
        username: "",
        zhiwei: "",
        kemu: ""
      },
      tableData: [],
      resetPwd: "",
      zhiweiTags: [],
      userTags: [],
      subjectTags: [],
      unedit: true,
      dialogVisi: false,
      formTitle: "用户",
      userData: {
        unit_name: "",
        userid: null,
        useraccount: "",
        username: "",
        userpwd: "",
        cellphone: "",
        email: "",
        user_types:"",
        until: null,
        unitid: null,
        unit_parent_id: null,
        usertags: null,
        subjects: null,
        gradeLeadergrades:[], // 年级组长所属年级
        teacherGradeClass:{},// 任课教师所带科目班级
        teacherSubject:null, // 任课教师所教科目
        researcherSubject:[], // 学科教研员科目
        classLeaderClass:[],// 班主任所带班级
      },
      rules: {
        username: [{required: true, message: "请填写用户名称", trigger: "blur"}, {max: 30, message: "不能超过30个字", trigger: "blur"}],
        userpwd: [{required: true, message: "请填写登录密码", trigger: "blur"}, {max: 20, message: "不能超过20个字符", trigger: "blur"}],
        user_types: [{required: true, message: "请选择角色", trigger: "blur"}],
       // cellphone: [{required: true, message: "请填写手机号", trigger: "blur"},{pattern: /^\d*$/, message: "手机号必须是数字", trigger: "blur"}],
        email: [{type: "email", message: "请填写有效格式邮箱", trigger: "blur"}]
      },
      schoolGradesClass:[],
    }
  },
  computed:{
    schoolGrades(){
      const res = {}
      this.schoolGradesClass.forEach(item => {
        res[item.grade_id] = item.grade
      })
      return res
    },
    uTypesMap(){
      let map = {}
      for(const item of this.userTypes){
        map[item.code] = item
      }
      return map
    }
  },
  watch: {
    filterName (val) {
      this.$refs.unitTree.filter(val)
    },
    'userStore.user'(val){
      this.loadCodes()
      if (this.userStore.user.type == "3"){ // 学校管理员
        this.getUnitGradesAndClass(this.userStore.unit.unit_id)
      }
    }
  },
  mounted() {
    this.loadTreeData()
    this.loadCodes()
    this.tagStore.getTag("subject").then((subjects) => {
      this.subjectTags = subjects
    })
  },
  methods: {
    // 用户角色删除
    userTypeRemove(val){
      if (val == 4){
        this.userData.user_types.push('4')
        this.$message({message: "普通角色无法删除", type: "warning"})
      }
    },
    //载入单位树形列表
    loadTreeData () {
      this.axios.get("/api/info/user/loadtreeeduunit")
          .then((response) => {
            if (response.data.success) {
              this.treeData = response.data.result
              if (this.treeData.length > 0){
                this.clickUnit = this.treeData[0]
                this.search.unitid = this.treeData[0].unit_id
                this.loadUser()
              }
            }

          })

    },
    //查找树节点
    filterNode (value, data) {
      if (!value) return true;
      return data.unit_name.indexOf(value) !== -1;
    },
    //点击树节点
    clickNode (data) {
      this.tableTitle = data.unit_name
      this.search.unitid = data.unit_id
      this.uploadData.unitid = data.unit_id
      this.clickUnit = data
      this.loadUser()
      if (data.parent_id){
        // 学校单位，查这个学校的年级
        this.getUnitGradesAndClass(data.unit_id)
      }
    },
    // 获取某个学校单位的年级和班级
    async getUnitGradesAndClass(unit_id){
      const resp = await this.axios.post("/api/info/user/getUnitGradesAndClass",{unit_id:unit_id})
      if (resp.data.success){
        this.schoolGradesClass = resp.data.result

      }
    },
    //载入代码列表
    async loadCodes () {
      const uType = this.userStore.user.type
      const codes = await this.codeStore.getCode("user_type");
      this.userTypes = codes
      const filterCodes = []
      /**
       * [
       *     {
       *         "code_id": 12,
       *         "type_code": "user_type",
       *         "type_name": "用户类型",
       *         "code": "1",
       *         "code_name": "核心管理员"
       *     },
       *     {
       *         "code_id": 13,
       *         "type_code": "user_type",
       *         "type_name": "用户类型",
       *         "code": "2",
       *         "code_name": "经销商"
       *     },
       *     {
       *         "code_id": 14,
       *         "type_code": "user_type",
       *         "type_name": "用户类型",
       *         "code": "3",
       *         "code_name": "学校管理员"
       *     },
       *     {
       *         "code_id": 17,
       *         "type_code": "user_type",
       *         "type_name": "用户类型",
       *         "code": "4",
       *         "code_name": "普通用户"
       *     },
       *     {
       *         "code_id": 43,
       *         "type_code": "user_type",
       *         "type_name": "用户类型",
       *         "code": "5",
       *         "code_name": "年级组长"
       *     },
       *     {
       *         "code_id": 46,
       *         "type_code": "user_type",
       *         "type_name": "用户类型",
       *         "code": "6",
       *         "code_name": "考试管理员"
       *     },
       *     {
       *         "code_id": 47,
       *         "type_code": "user_type",
       *         "type_name": "用户类型",
       *         "code": "7",
       *         "code_name": "学科教研员"
       *     },
       *     {
       *         "code_id": 48,
       *         "type_code": "user_type",
       *         "type_name": "用户类型",
       *         "code": "8",
       *         "code_name": "任课教师"
       *     },
       *     {
       *         "code_id": 49,
       *         "type_code": "user_type",
       *         "type_name": "用户类型",
       *         "code": "9",
       *         "code_name": "学生"
       *     }
       * ]
       */
      codes.forEach(code => {
        if (uType == "1"){
          // 核心管理员
          if (["1","2","3","4","5","8"].includes(code.code)){
            filterCodes.push(code)
          }
        }else if (uType == "2"){
          // 经销商
          if (["2","3","4","5","8"].includes(code.code)){
            filterCodes.push(code)
          }
        }else if (uType == "3"){
          //  学校管理员
          if (["3","4","5","8"].includes(code.code)){
            filterCodes.push(code)
          }
        }
      })
      this.filterUserTypes = filterCodes
    },
    //载入用户列表
    loadUser () {
      this.axios.post("/api/info/user/loadeduuser", this.search)
          .then((response) => {
            if (response.data.success) {
              this.total = response.data.result.totalrecords
              this.tableData = response.data.result.datalist
            }

          })

    },
    //页码跳转
    toPage (page) {
      this.search.page = page
      this.loadUser()
    },
    //条件查询
    toSearch () {
      this.search.page = 1
      this.loadUser()
    },
    //关闭对话框
    closeDialog () {
      this.$refs["userForm"].resetFields()
      this.dialogVisi = false
      this.userData.userid = null
      this.userData.useraccount = ""
      this.userData.username = ""
      this.userData.userpwd = ""
      this.userData.cellphone = ""
      this.userData.email = ""
      this.userData.until = null
      this.userData.unitid = this.search.unitid
      this.userData.unit_name = ""
      this.userData.user_types = ["4"]
    },
    //新增
    showAdd () {
      if (this.search.unitid !== null || this.userStore.user.type == 3) {
        this.dialogVisi = true
        this.unedit = false
        this.userData.userid = null
        this.userData.useraccount = ""
        this.userData.username = ""
        this.userData.unit_name = this.tableTitle
        this.userData.userpwd = ""
        this.userData.cellphone = ""
        this.userData.email = ""
        this.userData.until = null
        this.userData.unitid = this.search.unitid
        if (this.clickUnit.parent_id === null){
          // 经销商单位,就默认只有一种角色
          this.userData.user_types = ["2"]
        }else {
          // 学校单位
          this.userData.user_types = ["4"]
        }

        this.formTitle = "新增用户"
        if (this.$refs["userForm"])
          this.$refs["userForm"].clearValidate()
      }
      else
        this.$message({message: "请先选择单位", type: "warning"})
    },
    //编辑
    showEdit (data) {
      this.dialogVisi = true
      this.unedit = false
      this.userData.userid = data.user_id
      this.userData.useraccount = data.user_account
      this.userData.username = data.user_name
      this.userData.userpwd = "x"
      this.userData.cellphone = data.cellphone
      this.userData.email = data.email
      this.userData.until = data.valid_until
      this.userData.unitid = data.unit_id
      this.userData.unit_name = data.unit_name
      this.userData.unit_parent_id = data.parent_id
      this.userData.user_types = data.user_types
      this.userData.gradeLeadergrades = data.gradeleadergrades ? data.gradeleadergrades : []
      if (data.teachergradeclass){
        const subjectIds = Object.keys(data.teachergradeclass)
        if (subjectIds.length > 0){
          this.userData.teacherSubject = parseInt(subjectIds[0])
          subjectIds.forEach(subject_id => {
            const arr = data.teachergradeclass[subject_id]
            this.userData.teacherGradeClass[subject_id] = []
            arr.forEach(a => {
              this.userData.teacherGradeClass[subject_id].push(a.grade_id+"-"+a.grade+'-'+a.class_id+'-'+a.class_name+'-'+a.school)
            })
          })
        }else {
          this.userData.teacherSubject = null
        }

        // this.userData.teacherGradeClass = data.teachergradeclass
      }else {
        this.userData.teacherSubject = null
        this.userData.teacherGradeClass = {}
      }
      this.userData.researcherSubject = data.researchersubject ? data.researchersubject : []

      this.userData.classLeaderClass = []
      if (data.classleaderclass){
        data.classleaderclass.forEach(a => {
          this.userData.classLeaderClass.push(a.grade_id+"-"+a.grade+'-'+a.class_id+'-'+a.class_name+'-'+a.school)
        })
      }

      this.formTitle = "编辑用户"
      if (this.$refs["userForm"])
        this.$refs["userForm"].clearValidate()
    },
    //删除
    toDel (data) {
      this.$confirm("确认删除此用户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/info/user/deluser", {user_id: data.user_id,unit_id:data.unit_id})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "用户删除成功", type: "success"})
                if (data.user_id === this.userData.userid) {
                  this.$refs["userForm"].resetFields()
                  this.unedit = true
                  this.userData.userid = null
                  this.userData.useraccount = ""
                  this.userData.username = ""
                  this.userData.userpwd = ""
                  this.userData.cellphone = ""
                  this.userData.email = ""
                  this.userData.until = null
                  this.userData.unitid = null
                  this.formTitle = "用户"
                }
                this.loadUser()
              }

            })

      })
    },
    //保存
    toSave () {
      this.$refs["userForm"].validate((valid) => {
        if (valid) {
          const newTeacherGradeClass = {}
          const newClassLeaderClass = []
          const data = {...this.userData,teacherGradeClass:newTeacherGradeClass,classLeaderClass:newClassLeaderClass}
          Object.keys(this.userData.teacherGradeClass).forEach(key => {
            newTeacherGradeClass[key] = []
            const list = this.userData.teacherGradeClass[key]

            for (let i = 0; i < list.length; i++) {
              const arr = list[i].split("-")
              const res = {}
              res["grade_id"] = parseInt(arr[0])
              res["grade"] = arr[1]
              res["class_id"] = parseInt(arr[2])
              res["class_name"] = arr[3]
              res["school"] = parseInt(arr[4])
              newTeacherGradeClass[key].push(res)
            }

          })
          this.userData.classLeaderClass.forEach(item => {
            const arr = item.split("-")
            const res = {}
            res["grade_id"] = parseInt(arr[0])
            res["grade"] = arr[1]
            res["class_id"] = parseInt(arr[2])
            res["class_name"] = arr[3]
            res["school"] = parseInt(arr[4])
            newClassLeaderClass.push(res)
          })
          this.axios.post("/api/info/user/saveuser", data)
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "用户保存成功", type: "success"})
                  this.loadUser()
                  this.closeDialog()
                }
              })

        }
        else
          return false
      })
    },
    //弹出密码重置框
    popReset (data) {
      this.resetPwd = ""
    },
    //重置密码
    toResetPwd (data) {
      this.axios.post("/api/info/user/resetpwd", {user_id: data.user_id, newpwd: this.resetPwd})
          .then((response) => {
            if (response.data.success) {
              this.$message({message: "重置密码成功", type: "success"})
            }
          })

    },
    //显示上传框
    showUpload () {
      if (this.uploadData.unitid != null) {
        this.uploadVisi = true
        this.$refs["upload"].clearFiles()
      }
      else
        this.$message({message: "请先选择单位", type: "warning"})
    },
    //关闭上传框
    closeUpload () {
      this.uploadVisi = false
    },
    //上传前检查
    checkUpload (file) {
      if (this.uploadData.unitid == null) {
        this.$message({message: "请先选择单位", type: "warning"})
        return false
      }
      let name = file.name.toLowerCase()
      if (!name.endsWith(".xls") && !name.endsWith(".xlsx")) {
        this.$message({message: "只能上传xls或xlsx文件", type: "warning"})
        return false
      }
      return true
    },
    //上传后处理
    afterSuccess (data) {
      this.$refs["upload"].clearFiles()
      if (data.success) {
        this.$message({message: "导入成功", type: "success"})
        this.loadUser()
        this.uploadVisi = false
      }
      else {
        if (typeof data.result === "string")
          this.$alert(data.result, "导入出错")
        else {
          this.errorData = data.result
          this.errorVisi = true
        }
      }
    },
    //下载模板
    toDownload () {
      document.getElementById("downlink").click()
    }
  }
}
</script>

<style scoped>
.prop-tag {
  margin-right: 15px;
}

.pwd-tip {
  padding: 15px 5px;
  font-size: 13px;
  color: #a9a9a9;
}
</style>
