<script>

import {useUserStoreWithOut} from "@/store/modules/user";
import {ArrowRight} from "@element-plus/icons";
import {useCodeStoreWithOut} from "@/store/modules/code";


export default {
  name: "PageMain",
  computed: {
    ArrowRight() {
      return ArrowRight
    },
    userTypesMap(){
      const res = {}
      if (this.userTypes){
        this.userTypes.forEach(i => {
          res[i.code] = i
        })
      }

      return res
    },
    nowUserUnitRole() {
      if (this.userStore.unitRoles) {
        return this.userStore.user.type + '-' + this.userStore.unit.unit_id
      }
      return ''
    },
    treeMenu(){
      // 去除掉“考试管理”的菜单，因为里面子菜单都在一个页面里面控制
      // 去掉“教师阅卷”的菜单
      // 去掉“扫描接口权限”的菜单
      return this.userStore.treePages.filter(item => {
        return item.permission_name !== '考试管理' && item.permission_name !== '教师阅卷' && item.permission_name !== '扫描接口权限'
      })
    },
    // eslint-disable-next-line vue/no-arrow-functions-in-watch
    nowUser(){
      let res = {}
      if (this.userStore.unitRoles){
        const user = this.userStore.user
        const unit = this.userStore.unit
        this.userStore.unitRoles.forEach((unitRole) => {
          if (user.type == unitRole.user_type && unit.unit_id == unitRole.unit_id){
            res = unitRole
          }
        })
      }

      return res
    }
  },
  setup() {
    const userStore = useUserStoreWithOut()
    const codeStore = useCodeStoreWithOut()
    return {userStore,codeStore}
  },
  data() {
    return {
      load: false,
      userTypes:[],
      examMgeMenuExist: false,
      teacherMarkingMenuExist: false,
      editPasswordVisi: false,
      editPasswordFormData: {
        oldPassword:"",
        newPass1: "",
        newPass2: ""
      },
    }
  },
  methods:{
    changeUnitRole(userType,unitId){
      this.userStore.changeUnitRole(userType,unitId)
    },
    async confirmEditPassword(){
      // oldPassword不能为空
      if (!this.editPasswordFormData.oldPassword){
        this.$message.warning('旧密码不能为空')
        return
      }

      // newPass不能为空
      if (!this.editPasswordFormData.newPass1 || !this.editPasswordFormData.newPass2) {
        this.$message.warning('新密码不能为空')
        return
      }
      // 两次密码一致
      if (this.editPasswordFormData.newPass1 !== this.editPasswordFormData.newPass2) {
        this.$message.warning('两次密码不一致')
        return
      }
      const {data} = await this.axios.post("/api/login/editPassword",this.editPasswordFormData)
      if(data.success){
        this.$message.success('修改成功,请重新登录')
        setTimeout(()=>{
          this.userStore.logout()
        },1100)
      }
    }
  },
  watch:{
    'userStore.pages': function (newVal) {
      this.load = true
      this.examMgeMenuExist = false
      for (let i = 0; i < newVal.length; i++) {
        const p = newVal[i]
        if (p.permission_url == "/exam/mgeexam"){
          this.examMgeMenuExist = true
        }
        if (p.permission_url == "/marking"){
          this.teacherMarkingMenuExist = true
        }
      }

    }
  },
  async beforeMount() {
    this.userStore.loadUserInfo()
    this.userTypes = await this.codeStore.getCode("user_type")
  }
}
</script>

<template>
  <el-container style="width: 100%;">
    <el-dialog v-model="editPasswordVisi" title="修改密码" width="500">
      <el-form :model="editPasswordFormData" label-width="150">
        <el-form-item label="旧密码" >
          <el-input v-model="editPasswordFormData.oldPassword" />
        </el-form-item>
        <el-form-item label="新密码" >
          <el-input v-model="editPasswordFormData.newPass1" />
        </el-form-item>
        <el-form-item label="再输一遍新密码" >
          <el-input v-model="editPasswordFormData.newPass2" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="editPasswordVisi = false">取消</el-button>
          <el-button type="primary" @click="confirmEditPassword">
            确认修改
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-header height="49px" class="main-header">
      <div class="header-container">
        <div class="left-content">
          <el-menu
              style="height: 49px"
              mode="horizontal"
              router
              v-if="load"
          >

            <el-menu-item v-show="examMgeMenuExist" index="/main/exam">
              <template #title>考试管理</template>
            </el-menu-item>
            <el-menu-item v-show="teacherMarkingMenuExist" index="/main/marking">
              <template #title>教师阅卷</template>
            </el-menu-item>

            <el-sub-menu v-for="(m,index) in treeMenu"
                          :key="index"
                          :index="index+''">
              <template #title>
                {{m.permission_name}}
              </template>
              <el-menu-item
                  v-for="(sub,i) in m.children"
                  :key="i"
                  :index="sub.permission_url"
              >
                {{sub.permission_name}}
              </el-menu-item>
            </el-sub-menu>


<!--            <el-sub-menu index="info">-->
<!--              <template #title>信息管理</template>-->
<!--              <el-menu-item index="/info/eduunit" v-check="'/info/eduunit'">教育单位管理</el-menu-item>-->
<!--              <el-menu-item index="/info/mgeunit" v-check="'/info/mgeunit'">管理单位管理</el-menu-item>-->
<!--              <el-menu-item index="/info/mgeuser" v-check="'/info/mgeuser'">管理员管理</el-menu-item>-->
<!--              <el-menu-item index="/info/teauser" v-check="'/info/teauser'">教师管理</el-menu-item>-->
<!--              <el-menu-item index="/info/mgestu" v-check="'/info/mgestu'">学生管理</el-menu-item>-->
<!--            </el-sub-menu>-->
<!--            <el-sub-menu index="sys" >-->
<!--              <template #title>系统管理</template>-->
<!--              <el-menu-item index="/sys/syslog" v-check="'/sys/syslog'">日志管理</el-menu-item>-->
<!--              <el-menu-item index="/sys/loginlog" v-check="'/sys/loginlog'">登录日志管理</el-menu-item>-->
<!--              <el-menu-item index="/sys/permission" v-check="'/sys/permission'">权限管理</el-menu-item>-->
<!--              <el-menu-item index="/sys/usertypeassgin" v-check="'/sys/usertypeassgin'">用户类型授权管理-->
<!--              </el-menu-item>-->
<!--              <el-menu-item index="/sys/usertagassgin" v-check="'/sys/usertagassgin'">用户标签授权管理-->
<!--              </el-menu-item>-->
<!--              <el-menu-item index="/sys/tag" v-check="'/sys/tag'">属性标签管理</el-menu-item>-->
<!--            </el-sub-menu>-->
          </el-menu>
        </div>
        <div class="right-content ">
          <div class="user-block">
            <el-dropdown trigger="click" placement="bottom-end">
              <span class="el-dropdown-link">
                <span style="color: var(--el-color-primary)" v-if="nowUser.user_type == '9'">
                  {{ nowUser.student_name + "(" + nowUser.student_number + ")"}}
                </span>
                <span style="color: var(--el-color-primary)"
                      v-else
                >
                  {{ userStore.user.name }}

                </span>
[{{(userStore.unit.name ? userStore.unit.name : '无单位') + '-' + userTypesMap[userStore.user.type]?.code_name}}]
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item v-for="(typeRole,index) in userStore.unitRoles"
                                    :key="index"
                                    :disabled="nowUserUnitRole == (typeRole.user_type+'-'+typeRole.unit_id)"
                                    @click="changeUnitRole(typeRole.user_type,typeRole.unit_id)"
                  >
                    {{(typeRole.unit_name ? typeRole.unit_name : '无单位') + '-' + userTypesMap[typeRole.user_type]?.code_name}}
                  </el-dropdown-item>
                  <el-dropdown-item divided @click="() => editPasswordVisi = true">
                    <span>
                      <i-lock theme="two-tone" size="14" :fill="['#333' ,'#b8e986']"/>
                      修改密码
                    </span>
                  </el-dropdown-item>
                  <el-dropdown-item  @click="() => userStore.logoutConfirm()">
                    <span>
                      <i-logout theme="two-tone" size="14" :fill="['#333' ,'#b8e986']"/>
                      退出
                    </span>
                  </el-dropdown-item>

                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </div>
    </el-header>
    <el-main id="maincontainer" style="overflow: auto">
      <el-breadcrumb :separator-icon="ArrowRight" style="margin-bottom: 15px">
        <el-breadcrumb-item
            v-for="(item, index) in userStore.pagePath"
            :key="index"
            :to="item.fullPath"
        >
          {{item.title}}
          <span v-show="item.otherInfo?.examName" style="color: var(--el-color-primary)">
            [{{item.otherInfo?.examName ? item.otherInfo.examName : ''}}{{item.otherInfo?.subjectName ? ' '+ item.otherInfo.subjectName : ''}}]
          </span>

        </el-breadcrumb-item>
      </el-breadcrumb>
      <router-view></router-view>
      <el-backtop target="#maincontainer"></el-backtop>
    </el-main>
  </el-container>
</template>

<style scoped>
.main-header {
  padding-left: 0;
  position: relative;
  z-index: 99;
  background-color: white;
  /*下边阴影*/
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.header-container {
  display: flex; /* 使用Flexbox布局 */
}
.left-content {
  flex-grow: 1; /* 左侧内容占剩余空间 */
}
.right-content {
  width: 400px; /* 右侧内容固定宽度 */
}


.user-block {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  line-height: 49px;
}

.el-dropdown-link {
  cursor: pointer;
  font-size: 16px;
}


</style>