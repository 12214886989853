<!--
管理员管理界面
最后编辑人：郑志强
最后编辑时间：2022-3-8
最后编辑细节：由AXIOS对错误信息进行拦截处理，删除每个函数的错误处理。
-->
<template>
  <el-container>
    <el-header style="height: 20px;">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>信息管理</el-breadcrumb-item>
        <el-breadcrumb-item>管理员管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container">
      <el-aside id="unitTree" style="padding: 20px;">
        <div class="row">
          <el-input
              siz="default"
              placeholder="请输入单位名称"
              prefix-icon="Search"
              v-model="filterName"
              style="width: 200px; min-width: 80px;"></el-input>
        </div>
        <el-tree
            :data="treeData"
            :props="treeProps"
            node-key="unit_id"
            default-expand-all
            :filter-node-method="filterNode"
            :expand-on-click-node="false"
            :highlight-current="true"
            @node-click="clickNode"
            ref="unitTree">
          <template class="tree-node"  v-slot="{ node }">
            <span>
              <i class="office-building"></i>
              {{ node.label }}
            </span>
          </template>
        </el-tree>
      </el-aside>
      <el-main style="background-color: #FBFBF9; border-left: 1px solid #EBEEF5;">
        <div class="row">
          <h3>{{ tableTitle }}</h3>
          <el-button siz="default" @click="loadUser" icon="Refresh">刷新</el-button>
          <el-button type="primary" siz="default" icon="Plus" @click="toAdd()">管理员</el-button>
          <div>查找管理员：</div>
          <el-input
              siz="default"
              placeholder="请输入管理员账号或名称"
              prefix-icon="Search"
              v-model="search.username"
              style="width: 40%; min-width: 80px;"
              @keyup.enter="loadUser">
            <el-button slot="append" icon="Search" @click="toSearch"></el-button>
          </el-input>
        </div>
        <el-table :data="tableData" stripe height="311px">
          <el-table-column prop="user_name" label="用户名称" min-width="100"></el-table-column>
          <el-table-column prop="user_account" label="用户账号" min-width="120"></el-table-column>
          <el-table-column prop="cellphone" label="手机号" min-width="120"></el-table-column>
          <el-table-column prop="email" label="Email" min-width="150"></el-table-column>
          <el-table-column prop="valid_until" label="有效期" min-width="120"></el-table-column>
          <el-table-column label="操作" fixed="right" width="250">
            <template v-slot="scope">
              <el-tooltip content="切换状态" placement="top" effect="light" transition="" :enterable="false">
                <el-switch
                    v-model="scope.row.state"
                    active-color="#13ce66"
                    active-value="1"
                    inactive-value="0"
                    style="margin-right: 10px;"
                    @change="toSwitch(scope.row)">
                </el-switch>
              </el-tooltip>
              <el-popover
                  trigger="click"
                  :ref="'popover-' + scope.row.user_id"
                  placement="top"
                  width="240px">
                <div>新密码：<el-input v-model="resetPwd" siz="default" style="width: 140px"></el-input></div>
                <div class="pwd-tip">如果不输入新密码则默认重置为az741369</div>
                <div style="text-align: right; margin: 0">
                  <el-button size="default" link @click="cancelReset(scope.row)">取消</el-button>
                  <el-button type="primary" size="default" @click="toResetPwd(scope.row)">确定</el-button>
                </div>
                <el-button class="table-button" slot="reference" @click="popReset(scope.row)">重置密码</el-button>
              </el-popover>
              <el-button class="table-button" @click="toEdit(scope.row)">编辑</el-button>
              <el-button class="table-button" @click="toDel(scope.row)" type="danger">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            @current-change="toPage"
            :current-page="search.page"
            :page-size="search.pagesize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
        <el-row>
          <el-col :span="24"><div style="padding: 7px 15px">{{ formTitle }}</div></el-col>
        </el-row>
        <el-form :model="userData" :rules="rules" ref="userForm" label-width="100px">
          <el-form-item label="用户账号" prop="useraccount">
            <el-input v-model="userData.useraccount" :disabled="userData.userid!==null || unedit"></el-input>
          </el-form-item>
          <el-form-item label="登录密码" prop="userpwd" v-show="userData.userid===null">
            <el-input v-model="userData.userpwd" :disabled="userData.userid!==null || unedit"></el-input>
          </el-form-item>
          <el-form-item label="用户名称" prop="username">
            <el-input v-model="userData.username" :disabled="unedit"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="cellphone">
            <el-input v-model="userData.cellphone" :disabled="unedit"></el-input>
          </el-form-item>
          <el-form-item label="Email" prop="email">
            <el-input v-model="userData.email" :disabled="unedit"></el-input>
          </el-form-item>
          <el-form-item label="有效期" prop="until">
            <el-date-picker
                type="Date"
                v-model="userData.until"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                style="width: 100%;"
                :disabled="unedit"></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="toSave" :disabled="unedit">保 存</el-button>
          </el-form-item>
        </el-form>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "ManageUser",
  data () {
    return {
      filterName: "",
      treeData: [],
      treeProps: {
        children: 'subunit',
        label: 'unit_name'
      },
      tableTitle: "",
      total: 0,
      search: {
        page: 1,
        pagesize: 5,
        unitid: null,
        username: ""
      },
      tableData: [],
      resetPwd: "",
      unedit: true,
      formTitle: "管理员",
      userData: {
        userid: null,
        useraccount: "",
        username: "",
        userpwd: "",
        cellphone: "",
        email: "",
        until: null,
        unitid: null
      },
      rules: {
        useraccount: [{required: true, message: "请填写用户账号", trigger: "blur"}, {max: 30, message: "不能超过30个字", trigger: "blur"}],
        username: [{required: true, message: "请填写用户名称", trigger: "blur"}, {max: 30, message: "不能超过30个字", trigger: "blur"}],
        userpwd: [{required: true, message: "请填写登录密码", trigger: "blur"}, {max: 20, message: "不能超过20个字符", trigger: "blur"}],
        cellphone: [{pattern: /^\d*$/, message: "手机号必须是数字", trigger: "blur"}],
        email: [{type: "email", message: "请填写有效格式邮箱", trigger: "blur"}]
      }
    }
  },
  watch: {
    filterName (val) {
      this.$refs.unitTree.filter(val)
    }
  },
  mounted() {
    this.loadTreeData()
  },
  methods: {
    //载入单位树形列表
    loadTreeData () {
      this.axios.get("/api/user/loadtreeallunit")
          .then((response) => {
            if (response.data.success) {
              this.treeData = response.data.result
            }
          })

    },
    //查找树节点
    filterNode (value, data) {
      if (!value) return true;
      return data.unit_name.indexOf(value) !== -1;
    },
    //点击树节点
    clickNode (data) {
      this.tableTitle = data.unit_name
      this.search.unitid = data.unit_id
      this.$refs["userForm"].resetFields()
      this.unedit = true
      this.userData.userid = null
      this.userData.useraccount = ""
      this.userData.username = ""
      this.userData.userpwd = ""
      this.userData.cellphone = ""
      this.userData.email = ""
      this.userData.until = null
      this.userData.unitid = null
      this.formTitle = "管理员"
      this.loadUser()
    },
    //载入用户列表
    loadUser () {
      this.axios.post("/api/user/loadmgeuser", this.search)
          .then((response) => {
            if (response.data.success) {
              this.total = response.data.result.totalrecords
              this.tableData = response.data.result.datalist
            }
          })

    },
    //页码跳转
    toPage (page) {
      this.search.page = page
      this.loadData()
    },
    //条件查询
    toSearch () {
      this.search.page = 1
      this.loadData()
    },
    //新增
    toAdd () {
      if (this.search.unitid !== null) {
        this.$refs["userForm"].resetFields()
        this.unedit = false
        this.userData.userid = null
        this.userData.unitid = this.search.unitid
        this.formTitle = "新增管理员"
      }
      else
        this.$message({message: "请先选择单位", type: "warning"})
    },
    //编辑
    toEdit (data) {
      this.$refs["userForm"].resetFields()
      this.unedit = false
      this.userData.userid = data.user_id
      this.userData.useraccount = data.user_account
      this.userData.username = data.user_name
      this.userData.userpwd = "x"
      this.userData.cellphone = data.cellphone
      this.userData.email = data.email
      this.userData.until = data.valid_until
      this.userData.unitid = data.unit_id
      this.formTitle = "编辑管理员"
    },
    //删除
    toDel (data) {
      this.$confirm("确认删除此管理员?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/user/deluser", {userid: data.user_id})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "管理员删除成功", type: "success"})
                if (data.user_id === this.userData.userid) {
                  this.$refs["userForm"].resetFields()
                  this.unedit = true
                  this.userData.userid = null
                  this.userData.useraccount = ""
                  this.userData.username = ""
                  this.userData.userpwd = ""
                  this.userData.cellphone = ""
                  this.userData.email = ""
                  this.userData.until = null
                  this.userData.unitid = null
                  this.formTitle = "管理员"
                }
                this.loadUser()
              }

            })

      })
    },
    //保存
    toSave () {
      this.$refs["userForm"].validate((valid) => {
        if (valid) {
          this.axios.post("/api/user/saveuser", this.userData)
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "管理员保存成功", type: "success"})
                  this.userData.userid = response.data.result
                  this.formTitle = "编辑管理员"
                  this.loadUser()
                }

              })

        }
        else
          return false
      })
    },
    //切换状态
    toSwitch (data) {
      this.axios.post("/api/user/switchuserstate", {userid: data.user_id})
          .then((response) => {
            if (response.data.success) {
              this.$message.success(response.data.result)
            }
          })

    },
    //弹出密码重置框
    popReset (data) {
      this.resetPwd = ""
      this.$refs["popover-" + data.user_id].doShow()
    },
    //取消密码重置
    cancelReset (data) {
      this.$refs["popover-" + data.user_id].doClose()
    },
    //重置密码
    toResetPwd (data) {
      this.axios.post("/api/user/resetpwd", {userid: data.user_id, newpwd: this.resetPwd})
          .then((response) => {
            if (response.data.success) {
              this.$refs["popover-" + data.user_id].doClose()
              this.$message({message: "重置密码成功", type: "success"})
            }
          })

    }
  }
}
</script>

<style scoped>
.pwd-tip {
  padding: 15px 5px;
  font-size: 13px;
  color: #a9a9a9;
}
</style>
