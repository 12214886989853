<!--
登录界面
最后编辑人：郑志强
最后编辑时间：2022-3-8
最后编辑细节：由AXIOS对错误信息进行拦截处理，删除每个函数的错误处理。
-->
<template>
  <div class="container">
    <div class="forms-container">
      <div class="signin-signup">
        <el-form :model="loginData" :rules="rules" ref="loginForm" label-width="100px" @submit.prevent class="sign-in-form">
          <h4 class="title">你好，欢迎使用鼎嘉网上阅卷平台</h4>
          <div class="input-field" v-if="loginType == '1'">
            <i class="fas fa-user"></i>
            <input v-model="loginData.ua"
                   type="text"
                   placeholder="'手机号或账号'"  @keydown.enter="doLogin"/>

          </div>

          <div v-if="loginType == '2'" class="input-field">
            <i class="fas fa-user"></i>
            <input v-model="loginData.phone"
                   type="text"
                   placeholder="手机号"
            />
          </div>
          <div class="input-field" v-if="loginType == '1'">
            <i class="fas fa-lock"></i>
            <input v-model="loginData.up" type="password" placeholder="密码" @keydown.enter="doLogin"/>
          </div>
          <div v-if="loginType == '2'" >

            <el-input v-model="loginData.code"
                      :maxlength="4"
                      placeholder="请输入验证码"
                      size="large"
            >
              <template #suffix>
                <el-button type="success"
                           size="small"
                           @click="getVerifyCode"
                           :disabled="countDown > 0"
                >{{ countDown > 0 ? '再次获取('+countDown+')' :'获取验证码' }}</el-button>
              </template>
            </el-input>
          </div>
          <el-button
              class="btn solid"
              @click="doLogin"
              v-if="loginType == '1' "
              value="登录">登录</el-button>
          <el-button
              class="btn solid"
              @click="verifyCode"
              v-if="loginType == '2' "
              value="登录/注册">登录/注册</el-button>
          <div>
            <el-link
                v-if="loginType == '1' "
                @click="()=>{
                  loginData.phone = ''
                  loginData.code = ''
                   loginType = '2'
            }">手机号一键登录/注册</el-link>
            <el-link
                v-if="loginType == '2' "
                @click="()=>{
                  loginData.ua = ''
                  loginData.up = ''
                  loginType = '1'

            }">账号密码登录</el-link>
          </div>
        </el-form>
      </div>
    </div>

    <div class="panels-container">
      <div class="panel left-panel">
        <div class="content">
          <h3>鼎嘉网上阅卷</h3>
          <p>
            答题卡智能裁剪、智能识别、整个阅卷流程系统化、数据分析智能化。
            <el-link  siz="default" icon="download" @click="toDownload()">下载扫描客户端</el-link>
          </p>
          <button class="btn transparent" id="sign-up-btn">关于我们</button>
        </div>
        <img src="../../assets/img-01.png" class="image" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/common.css"
import {ElMessageBox} from "element-plus";
export default {

  // eslint-disable-next-line vue/multi-word-component-names
  name: "Login",
  data: function() {
    return {
      isvalid: false,
      loginData: {
        ua: "",
        up: "",
        phone:"", // 手机号
        code:"" // 验证码
      },
      rules: {
        ua: [{required: this.loginType == "1", message: "请输入用户名", trigger: "blur"}],
        up: [{required: this.loginType == "1", message: "请输入密码", trigger: "blur"}],
        phone: [{required: this.loginType == "2", message: "请输入手机号", trigger: "blur"}],
        code: [{required: this.loginType == "2", message: "请输入验证码", trigger: "blur"}],
      },
      countDown: 0,
      timer: null,// 发送验证码倒计时
      loginType: "1" // 1-账号密码登录 2-手机号验证码登录
    }
  },
  mounted() {
    if (this.checkIfMobile()){
     this.$router.replace({name:"m_login"})
    }
  },
  methods: {
    // 获取验证码
    async getVerifyCode(){
      // 手机号不能为空且必须是手机号
      if (this.loginData.phone == ""){
        this.$message.warning("手机号不能为空")
        return
      }
      if (!this.checkPhone(this.loginData.phone)){
        this.$message.warning("手机号格式不正确")
        return
      }

      const {data} = await this.axios.post("/api/login/sendPhoneCode",{phone: this.loginData.phone})
      if (data.success){
        this.startTimer()
        this.$message.success("验证码发送成功")
      }else {
        this.$message.warning(data.result)
      }
    },
    // 启动60s定时器
    startTimer(){
      this.countDown = 60
      this.timer = setInterval(()=>{
        this.countDown--
        if (this.countDown <= 0){
          clearInterval(this.timer)
        }
      },1000)
    },
    // 结束60s定时器
    endTimer(){
      clearInterval(this.timer)
      this.countDown = 0
    },

    checkPhone(phone){
      let myreg = /^1[3456789]\d{9}$/;
      if (!myreg.test(phone)) {
        return false
      } else {
        return true
      }
    },
    // 跳转至主页
    toMainContent(){
      sessionStorage.setItem("is-login", "1")
      sessionStorage.removeItem("redirect")
      this.$router.replace({name:"cardMenu"})
    },
    // 校验验证码
    async verifyCode(){
      if (this.loginData.code == ""){
        this.$message.warning("验证码不能为空")
        return
      }
      const {data} = await this.axios.post("/api/login/verifyPhoneCode",{
        phone: this.loginData.phone,
        sms_code: this.loginData.code
      })
      const token = data.result.token
      const password = data.result.password
      if (password){
        ElMessageBox.alert('新用户您好！您的密码是:'+password+",进入系统后可以进行修改", '提示', {
          // if you want to disable its autofocus
          // autofocus: false,
          confirmButtonText: '我知道了',
          callback: this.toMainContent,
        })
      }else {
        this.toMainContent()
      }
    },
    toDownload(){
      this.axios({
        method: "post",
        url: "/api/common/download/1",
        data: {},
        responseType: 'blob'
      }).then(res => {
        const link = document.createElement('a')
        if (res.headers["content-type"] == "application/json;charset=UTF-8"){
          res.data.text().then(text => {
            const json = JSON.parse(text);
            this.$message.error(json.result);
          }).catch(error => {
            console.error("解析JSON出错:", error);
            this.$message.error("解析JSON出错:"+error);
          });
        }else {
          let blob = new Blob([res.data], {type: 'application/octet-stream'});
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob);
          const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
          link.setAttribute('download', decodeURIComponent(fileName))
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }, err => {
        console.log(err)
        this.$alert(err, "下载失败！")
      });
    },
    checkIfMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // 常见的移动设备关键词
      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase());
    },
    doLogin () {
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
            this.axios.post("/api/login/dologin", this.loginData)
                .then((response) => {
                  if (response.data.success) {
                    this.toMainContent()
                  }
                })
        }
        else
          return false
      })
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body{
  overflow-x: hidden;
}

body,
input {
  font-family: 'Poppins', sans-serif;
}

.container {
  position: relative;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
}

.container::before {
  content: '';
  position: absolute;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg, var(--el-color-success) 0%, var(--el-color-primary-light-7) 100%);
  transition: 1.8s ease-in-out;
  border-radius: 50%;
  z-index: 6;
}

.forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

form {
  display: flex;
  /*Distributes Flex-items along the Cross Axis */
  align-items: center;
  /*Distributes Flex-items along the Main Axis */
  justify-content: center;
  flex-direction: column;
  padding: 0 5rem;
  /*overflow: hidden;*/
  padding: 0rem 5rem;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  transition: 0.2s 0.7s ease-in-out;
}

form.sign-in-form {
  z-index: 2;
}

form.sign-up-form {
  z-index: 1;
  opacity: 0;
}

.title {
  font-size: 2rem;
  color: #444;
  margin-bottom: 10px;
}

.input-field {
  max-width: 380px;
  width: 100%;

  height: 55px;
  background-color: #f0f0f0;
  margin: 10px 0;
  border-radius: 55px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
}

.input-field i {
  text-align: center;
  line-height: 55px;
  color: #acacac;
  font-size: 1.1rem;
}

.input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: #333;
}

.input-field input::placeholder {
  color: #aaa;
  font-weight: 500;
}

.btn {
  width: 150px;
  height: 49px;
  border: none;
  outline: none;
  border-radius: 48px;
  cursor: pointer;
  background-color: var(--el-color-success-light-3);
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  font-size: 20px;
  transition: all 0.5s;
}

.btn:hover {
  background-color: var(--el-color-success-light-5);
}

.social-text {
  padding: 0.7rem 0;
}

.social-media {
  display: flex;
  justify-content: center;
}

.social-icon {
  height: 46px;
  width: 46px;
  border: 1px solid #333;
  margin: 0 0.45rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 1.3rem;
  border-radius: 50%;
  transition: all 0.5s;
}

.signin-signup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 50%;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
  transition: 1s 0.7s ease-in-out;
}

.panels-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align: center;
  z-index: 7;
}

.left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}

.right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}

.panel .content {
  color: #fff;
  transition: 0.9s 0.6s ease-in-out;
}

.panel h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 1.5rem;
}

.panel p {
  font-size: 0.95rem;
  padding: 0.7rem 0;
}

.btn.transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff;
  width: 130px;
  height: 41px;
  font-weight: 600;
  font-size: 0.8rem;
}
.btn.transparent:hover{
  background-color: #fff;
  color: #333;

}

.image {
  transition: 1.1s 0.4s ease-in-out;
  width: 50%;
}

.right-panel .content,
.right-panel .image {
  transform: translateX(800px);
}

/* ANIMATION */

.container.sign-up-mode::before {
  transform: translate(100%, -50%);
  right: 52%;
}

.container.sign-up-mode .left-panel .image,
.container.sign-up-mode .left-panel .content {
  transform: translateX(-800px);
}
.container.sign-up-mode .left-panel {
  pointer-events: none;
}
.container.sign-up-mode .right-panel {
  pointer-events: all;
}

.container.sign-up-mode .right-panel .image,
.container.sign-up-mode .right-panel .content {
  transform: translateX(0%);
}

.container.sign-up-mode .signin-signup {
  left: 25%;
}

.container.sign-up-mode form.sign-in-form {
  z-index: 1;
  opacity: 0;
}

.container.sign-up-mode form.sign-up-form {
  z-index: 2;
  opacity: 1;
}
</style>
