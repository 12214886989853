<!--
评卷教师管理管理界面
最后编辑人：宁茂
最后编辑时间：2022-5-25
最后编辑细节：首次上传
-->
<template>
  <el-container class="base-container">
    <el-header style="height: 20px; padding-right: 0;">
      <el-breadcrumb separator="/" style="display: inline-block; width: calc(100% - 100px);">
        <el-breadcrumb-item>考试管理</el-breadcrumb-item>
        <el-breadcrumb-item>评卷教师管理</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button siz="default" class="page-back-button" style="float: right;" icon="back" @click="$router.back()">返回</el-button>
    </el-header>
    <el-container class="main-container"   ref="mainSector">
      <el-header style="height: 32px; padding-right: 0;">
        <div style="display: flex; justify-content: space-between;">
          <div class="exam-title">{{ exam.exam_name }}</div>
          <div class="exam-subject">
            <el-button size="default" circle icon="refresh" @click="loadTeacherOfExam"></el-button>
            <span style="margin: 0 5px;">科目：</span>
            <el-select siz="default" v-model="search.subjectid" placeholder="请选择科目" @change="selectSubject">
              <el-option
                  v-for="item in exam.subjects"
                  :key="item.subject_id"
                  :label="item.subject_name"
                  :value="item.subject_id">
              </el-option>
            </el-select>
          </div>
        </div>
      </el-header>
      <el-container style="height: calc(100% - 32px);">
        <el-main>
          <div class="row">
            <div>查找教师：</div>
            <el-input siz="default" placeholder="请输入教师姓名" v-model="search1.username" @keyup.enter="toSearch1" style="width: 200px; min-width: 80px;">
            </el-input>
            <el-button siz="default" @click="toSearch1" icon="Search">查询</el-button>
            <el-button type="primary" siz="default" @click="showAdd" icon="Plus">添加评卷教师</el-button>
            <el-button type="primary" siz="default" @click="showMultipleOperate" icon="Grid">批量添加编辑</el-button>
          </div>
          <el-table :data="tableData1" stripe>
            <el-table-column prop="user_name" label="用户名称" min-width="100"></el-table-column>
            <el-table-column prop="user_account" label="用户账号" min-width="120"></el-table-column>
            <el-table-column prop="cellphone" label="手机号" min-width="100"></el-table-column>
            <el-table-column prop="is_online" label="状态" width="100">
              <template v-slot="scope">
                <el-tag v-if="scope.row.is_online === true" type="success">在线</el-tag>
                <el-tag v-else type="danger">离线</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="350">
              <template v-slot="scope">
                <el-button v-show="scope.row.is_online === true" class="table-button" type="warning" bg @click="forceLogout(scope.row)">
                  <template #icon><el-icon><SwitchButton /></el-icon></template>
                  强制下线</el-button>
                <el-button class="table-button" @click="toManageQuestion(scope.row)" type="info" bg>
                  <template #icon><Setting/></template>
                  管理题组轮次</el-button>
                <el-button class="table-button" @click="toDel(scope.row)" type="danger" bg >
                  <template #icon><Delete/></template>
                  删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              @current-change="toPage"
              :current-page="search1.page"
              :page-size="search1.pagesize"
              layout="total, prev, pager, next, jumper"
              :total="total1">
          </el-pagination>
        </el-main>
      </el-container>
    </el-container>

    <!--批量添加编辑-->
    <el-dialog title="批量添加编辑老师" v-model="multipleDialogVisi" width="90%" >
      <el-space style="margin-bottom: 10px;width: 100%">
        <el-select v-model="search3.question_id" placeholder="暂无题组" @change="(val) =>{
            nowSelectQuestion = questionGroupInfoList.find(item => item.question_id === val)
            if (search3.question_id != null) {
                listTeacherQuestionTurn()
            }
          }">
          <template #prefix>
            题组：
          </template>
          <el-option
            v-for="item in questionGroupInfoList"
            :key="item.question_id"
            :label="item.question_name"
            :value="item.question_id"
          ></el-option>
        </el-select>
        <el-select v-model="search3.turn" placeholder="选择轮次" style="width: 120px;" clearable>
          <el-option label="第一轮评卷" value="1"></el-option>
          <el-option label="第二轮评卷" value="2"></el-option>
          <el-option label="第三轮评卷" value="3"></el-option>
          <el-option label="异常卷评卷" value="9"></el-option>
          <el-option label="最终轮评卷" value="8"></el-option>
        </el-select>
        <el-button type="primary" @click="listTeacherQuestionTurn" icon="Search">查询</el-button>
        <el-button type="warning" @click="downloadTeacherTable" icon="Download">导出</el-button>


        <el-popover
          trigger="click"
          ref="popoverUpload"
          placement="bottom"
          :visible="uploadVisi"
          width="360px">
          <template #reference>
            <el-button @click="uploadTeacherTable" icon="Upload" >导入</el-button>
          </template>
          <div style="width: 100%; text-align: right;"><el-button link icon="Close" @click="closeUpload"></el-button></div>
          <el-upload
            action="/api/exam/importTeacher"
            :data="search3"
            name="excelfile"
            ref="upload"
            drag
            :multiple="false"
            :before-upload="checkUpload"
            :on-success="afterSuccess">
            <i class="upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只能上传xls或xlsx文件，且不超过10MB</div>
          </el-upload>
        </el-popover>

      </el-space>
      <el-space style="width: 100%">
        <span style="font-weight: bold;border-bottom: solid 1px black">批量生成：</span>
        <span>第一轮评卷:
        <el-input-number v-model="generateTeacherNum.turn1" :min="0" :max="1000"
                         controls-position="right"
                         style="width: 80px;"/>
        </span>
        <span>第二轮评卷:
        <el-input-number v-model="generateTeacherNum.turn2" :min="0" :max="1000"
                         controls-position="right"
                         style="width: 80px;"/>
        </span>
        <span>

        </span>
        <span>第三轮评卷:
        <el-input-number v-model="generateTeacherNum.turn3" :min="0" :max="1000"
                         controls-position="right"
                         style="width: 80px;"/>
        </span>
        <span>异常卷评卷:
        <el-input-number v-model="generateTeacherNum.turn9" :min="0" :max="1000"
                         controls-position="right"
                         style="width: 80px;"/>
        </span>
        <span>最终轮评卷:
        <el-input-number v-model="generateTeacherNum.turn8" :min="0" :max="1000"
                         controls-position="right"
                         style="width: 80px;"/>
        </span>

        <el-button type="success" @click="generateTeacher" style="width: 100px;">生成</el-button>


      </el-space>

      <el-table :data="teacherQuestionTurnTableList" stripe>
        <el-table-column prop="user_name" label="用户名称" min-width="100"></el-table-column>
        <el-table-column prop="user_account" label="用户账号" min-width="120"></el-table-column>
        <el-table-column prop="cellphone" label="手机号" min-width="100"></el-table-column>
        <el-table-column prop="is_online" label="状态" width="100">
          <template v-slot="scope">
            <el-tag v-if="scope.row.is_online === true" type="success">在线</el-tag>
            <el-tag v-else type="danger">离线</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="turn" label="轮次" width="100">
          <template v-slot="scope">
            <el-tag v-if="scope.row.turn === '1'" type="info">第一轮</el-tag>
            <el-tag v-else-if="scope.row.turn === '2'" type="success">第二轮</el-tag>
            <el-tag v-else-if="scope.row.turn === '3'" type="primary" >第三轮</el-tag>
            <el-tag v-else-if="scope.row.turn === '9'" type="danger">异常轮</el-tag>
            <el-tag v-else-if="scope.row.turn === '8'" type="warning" >最终轮</el-tag>
            <el-tag v-else type="danger">轮次错误</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="350">
          <template v-slot="scope">
            <el-button v-show="scope.row.is_online === true" class="table-button" type="warning" bg @click="forceLogout2(scope.row)">
              <template #icon><el-icon><SwitchButton /></el-icon></template>
              强制下线</el-button>
            <el-button class="table-button" @click="toDelTeacherTurn(scope.row)" type="danger" bg >
              <template #icon><Delete/></template>
              删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="search3toPage"
        :current-page="search3.page"
        :page-size="search3.pagesize"
        layout="total, prev, pager, next, jumper"
        :total="total3">
      </el-pagination>
    </el-dialog>

    <!--分配权限dialog-->
    <el-dialog :title="questionDialogTitle" v-model="questionDialogVisi" width="750px" :close-on-click-modal="false" :close-on-press-escape="false">
      <div v-for="(item,index) in questionGroupInfoList"
           :key="index">

        <el-descriptions :title="item.question_name" :column="1">
          <el-descriptions-item label="">
            <el-checkbox v-model="questionTurnCheck[item.question_id].turn1" label="第一轮评卷" size="small" border />
            <el-checkbox v-model="questionTurnCheck[item.question_id].turn2" v-show="item.mark_mode !== '1'" label="第二轮评卷" size="small" border />
            <el-checkbox v-model="questionTurnCheck[item.question_id].turn3" v-show="item.mark_mode === '4'" label="第三轮评卷" size="small" border />
            <el-checkbox v-model="questionTurnCheck[item.question_id].turn9" label="异常卷评卷" size="small" border />
            <el-checkbox v-model="questionTurnCheck[item.question_id].turn8" label="最终轮评卷" size="small" border />
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <el-button type="success" style="width: 100%;" @click="toSaveTeacherQuestion">保存</el-button>
    </el-dialog>

    <el-dialog :title="tableTitle" v-model="dialogVisi" width="950px" >
      <div class="row">
        <div>查找教师：</div>
        <el-input siz="default" placeholder="教师姓名" v-model="search2.username" @keyup.enter="toSearch2" style="width: 150px; min-width: 80px;">
        </el-input>
        <el-button siz="default" @click="toSearch2" icon="Search">查询</el-button>
        <el-button siz="default" type="primary" @click="toSave">保 存</el-button>
      </div>
      <el-table :data="tableData2" stripe @selection-change="handleSelectionChange">
        <el-table-column
            type="selection"
            width="55">
        </el-table-column>
        <el-table-column prop="user_name" label="用户名称" min-width="100"></el-table-column>
        <el-table-column prop="user_account" label="用户账号" min-width="120"></el-table-column>
        <el-table-column prop="cellphone" label="手机号" min-width="100"></el-table-column>
      </el-table>
      <div slot="footer">
        <el-button type="primary" @click="toSave">保 存</el-button>
      </div>
    </el-dialog>


  </el-container>
</template>

<script>
import {Delete, Setting} from "@element-plus/icons-vue";
import * as FileUtils from "@/utils/FileUtils";

export default {
  name: "ManageExamTeacher",
  components: {Delete, Setting},
  data () {
    return {
      errorData:null,
      errorVisi:false,
      uploadVisi:false,
      exam: {
        exam_id: null,
        exam_name: "",
        unit_id: null,
        unit_name: "",
        exam_state: "",
        subjects: []
      },
      search: {
        examid: null,
        subjectid: null
      },
      search1: {
        page: 1,
        pagesize: 15,
        examid: null,
        subjectid: null,
        username: ""
      },
      search2: {
        examid: null,
        subjectid: null,
        unitid: null,
        username: ""
      },
      search3: {
        exam_id: null,
        subject_id: null,
        question_id: null,
        turn: "", // 轮次
        page: 1,
        pagesize: 15,
      },
      total1: 0,
      total2: 0,
      total3: 0,
      tableData1: [],
      tableData2: [],
      multipleSelection:[],
      dialogVisi: false,
      tableTitle: "未分配教师列表",

      questionDialogVisi: false,
      questionDialogTitle: "",
      selectTeacherId: null,
      initQuestionGroupInfoList: [],
      questionGroupInfoList: [],
      teacherQuestionGroupList: [],
      questionTurnCheck:{},

      nowSelectQuestion: {},
      multipleDialogVisi:false,
      generateTeacherNum:{
        turn1: 0,
        turn2: 0,
        turn3: 0,
        turn9: 0,
        turn8: 0
      },
      teacherQuestionTurnTableList:[],

      downloadHref:''
    }
  },

  mounted() {
    this.exam.exam_id= this.search.examid = this.search1.examid  = this.search2.examid  = sessionStorage.getItem("exam-mge-exam-id")
    this.exam.unit_id = sessionStorage.getItem("exam-mge-exam-unit-id")
    if (this.exam.exam_id != null) {
      this.exam.exam_id = Number(this.exam.exam_id)
      this.search.examid = this.exam.exam_id
    }
    if (this.exam.unit_id != null) {
      this.exam.unit_id = Number(this.exam.unit_id)
      this.search2.unitid = this.exam.unit_id
    }
    this.loadExamInfo()
  },

  methods: {

    //上传前检查
    checkUpload (file) {
      let name = file.name.toLowerCase()
      if (!name.endsWith(".xls") && !name.endsWith(".xlsx")) {
        this.$message({message: "只能上传xls或xlsx文件", type: "warning"})
        return false
      }
      return true
    },
    //上传后处理
    afterSuccess (data) {
      this.$refs["upload"].clearFiles()
      if (data.success) {
        this.$message({message: "导入成功", type: "success"})
        this.listTeacherQuestionTurn()
        this.uploadVisi = false
      }
      else {
        if (typeof data.result === "string")
          this.$alert(data.result, "导入出错")
        else {
          this.errorData = data.result
          this.errorVisi = true
        }
      }
    },

    //显示上传框
    showUpload () {
      this.uploadVisi = true
      this.$refs["upload"].clearFiles()
    },
    //关闭上传框
    closeUpload () {
      this.uploadVisi = false
    },

    // 打开批量添加编辑dialog
    async showMultipleOperate(){
      this.search3.exam_id = this.search1.examid
      this.search3.subject_id = this.search1.subjectid
      this.multipleDialogVisi = true

      if (this.search3.question_id){
        this.listTeacherQuestionTurn()
      }

    },
    search3toPage(page){
      this.search3.page = page
      this.listTeacherQuestionTurn()
    },
    // 获取老师题组轮次
    async listTeacherQuestionTurn(){
      const resp = await this.axios.post("/api/exam/listExamQuestionTeacher", this.search3)
      if (resp.data.success){
        this.teacherQuestionTurnTableList = resp.data.result.datalist
        this.total3 = resp.data.result.totalrecords
      }
    },
    // 导出某个题组轮次的老师
    async downloadTeacherTable(){
      const {exam_id,question_id,turn} = this.search3
      FileUtils.download("/api/exam/downloadTeacherTurn",this.search3)
    },
    // 导入老师题组轮次
    async uploadTeacherTable(){
      this.showUpload()
    },
    // 生成
    async generateTeacher(){
      if (!this.nowSelectQuestion.mark_mode){
        this.$message.warning("此题未设置评卷轮次，暂无法生成！")
        return
      }
      const resp = await this.axios.post("/api/exam/batchCreateTeacher",{
        exam_id: this.search1.examid,
        subject_id: this.search1.subjectid,
        question_id: this.search3.question_id,
        teacherTurnNum: this.generateTeacherNum
      })
      if (resp.data.success){
        this.$message.success("生成成功")
        this.listTeacherQuestionTurn()
      }
    },

    // 强制下线
    forceLogout2(row){
      this.$confirm("确认强制下线该教师?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/exam/forceLogout", {teacherid: row.user_id})
          .then((response) => {
            if (response.data.success) {
              this.$message({message: "强制下线成功", type: "success"})
              this.listTeacherQuestionTurn()
            }
          })
      }).then(() => {
      }).catch(() => {
      })
    },
    //删除
    toDelTeacherTurn (data) {
      this.$confirm("确认删除该教师在当前轮次的权限?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/exam/delTeacherTurn", {
          teacher_id: data.user_id,
          exam_id : parseInt(this.search3.exam_id),
          subject_id: this.search3.subject_id,
          question_id: this.search3.question_id,
          turn: data.turn
        })
          .then((response) => {
            if (response.data.success) {
              this.$message({message: "删除成功", type: "success"})
              this.listTeacherQuestionTurn()
            }
          })
      }).then(() => {
      }).catch(() => {
      })
    },

    //载入考试科目信息
    loadExamInfo () {
      this.axios.post("/api/common/loadexaminfo", {examid: this.exam.exam_id})
          .then((response) => {
            if (response.data.success) {
              this.exam = response.data.result
              if (this.exam.subjects.length > 0) {
                this.search.examid = this.examID
                this.search.subjectid = this.search1.subjectid = this.search2.subjectid = this.exam.subjects[0].subject_id
                this.loadTeacherOfExam()
                this.loadTeacherOfUnit()
                this.loadExamSubjectQuestionGroup()
              }
            }
          })
    },

    //选择科目重新加载
    selectSubject () {
      if (this.search.subjectid != null) {
        this.search1.subjectid = this.search2.subjectid = this.search.subjectid
        this.loadTeacherOfExam()
        this.loadExamSubjectQuestionGroup()
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    //载入已分配教师列表
    loadTeacherOfExam () {
      this.axios.post("/api/exam/loadexamteacher", this.search1)
          .then((response) => {
            if (response.data.success) {
              this.total1 = response.data.result.totalrecords
              this.tableData1 = response.data.result.datalist
            }
          })
    },

    //载入未分配教师列表
    loadTeacherOfUnit () {
      this.axios.post("/api/exam/loadunitteacher", this.search2)
          .then((response) => {
            if (response.data.success) {
              this.tableData2 = response.data.result
            }
          })
    },

    //页码跳转
    toPage (page) {
      this.search1.page = page
      this.loadTeacherOfExam()
    },

    toSearch1 () {
      this.search1.page = 1
      this.loadTeacherOfExam()
    },

    toSearch2 () {
      this.loadTeacherOfUnit()
    },
    //新增
    showAdd () {
      if (this.search2.examid !== null) {
        this.dialogVisi = true
        this.loadTeacherOfUnit()
        this.multipleSelection = []
      }
      else
        this.$message({message: "考试ID缺失", type: "warning"})
    },

    //删除
    toDel (data) {
      this.$confirm("确认删除该教师?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/exam/delteacher", { teacherid: data.user_id, examid : this.search1.examid, subjectid: this.search1.subjectid })
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "删除成功", type: "success"})
                this.loadTeacherOfExam()
              }
            })
      }).then(() => {
      }).catch(() => {
      })
    },

    // 查询考试科目下的题组信息
    loadExamSubjectQuestionGroup(){
      this.axios.post("/api/exam/loadExamSubjectQuestionGroup", {examid: this.search1.examid, subjectid: this.search1.subjectid})
          .then((response) => {
            if (response.data.success) {
              // 过滤掉mark_mode = "" || mark_mode = null的题组
              this.questionGroupInfoList = response.data.result.filter(item => item.mark_mode !== "" && item.mark_mode !== null)
              this.questionGroupInfoList.forEach((item) => {
                this.questionTurnCheck[item.question_id] = {}
                this.questionTurnCheck[item.question_id].turn1 = false
                this.questionTurnCheck[item.question_id].turn2 = false
                this.questionTurnCheck[item.question_id].turn3 = false
                this.questionTurnCheck[item.question_id].turn9 = false
                this.questionTurnCheck[item.question_id].turn8 = false
              })
              if (this.questionGroupInfoList.length > 0) {
                this.search3.question_id = this.questionGroupInfoList[0].question_id
                this.nowSelectQuestion = this.questionGroupInfoList[0]
              }
            }
          })
    },

    // 管理题组轮次
    toManageQuestion(data){
      this.selectTeacherId = data.user_id
      this.questionDialogTitle = "【"+data.user_id+"】" + data.user_name + " 的题组轮次"
      this.axios.post("/api/exam/loadTeacherQuestionGroup", {examid: this.search1.examid, subjectid: this.search1.subjectid, teacherid: data.user_id})
          .then((response) => {
            if (response.data.success) {
              this.teacherQuestionGroupList = response.data.result

              // 全部先改成false
              Object.keys(this.questionTurnCheck).forEach((key) => {
                this.questionTurnCheck[key].turn1 = false
                this.questionTurnCheck[key].turn2 = false
                this.questionTurnCheck[key].turn3 = false
                this.questionTurnCheck[key].turn9 = false
                this.questionTurnCheck[key].turn8 = false
              })

              this.teacherQuestionGroupList.forEach((item) => {
                const questionId = item.question_id
                const turn = item.turn
                if (turn.toString() === "1") {
                  this.questionTurnCheck[questionId].turn1 = true
                }else if (turn.toString() === "2") {
                  this.questionTurnCheck[questionId].turn2 = true
                }else if (turn.toString() === "3") {
                  this.questionTurnCheck[questionId].turn3 = true
                }else if (turn.toString() === "9") {
                  this.questionTurnCheck[questionId].turn9 = true
                }else if (turn.toString() === "8") {
                  this.questionTurnCheck[questionId].turn8 = true
                }
              })
              this.questionDialogVisi = true
            }
          })
    },

    // 强制下线
    forceLogout(row){
      this.$confirm("确认强制下线该教师?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/exam/forceLogout", {teacherid: row.user_id})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "强制下线成功", type: "success"})
                this.loadTeacherOfExam()
              }
            })
      }).then(() => {
      }).catch(() => {
      })
    },

    // 保存老师题组轮次
    toSaveTeacherQuestion(){
      const data = {}

      Object.keys(this.questionTurnCheck).forEach((key) => {
        const item = this.questionTurnCheck[key]
        if(data[key] === undefined){
          data[key] = []
        }
        if(item.turn1){
          data[key].push('1')
        }
        if(item.turn2){
          data[key].push('2')
        }
        if(item.turn3){
          data[key].push('3')
        }
        if(item.turn9){
          data[key].push('9')
        }
        if(item.turn8){
          data[key].push('8')
        }

        if (data[key].length === 0) {
          delete data[key]
        }
      })
      this.axios.post("/api/exam/saveTeacherQuestion", {examid: parseInt(this.search1.examid), subjectid: this.search1.subjectid, teacherid: this.selectTeacherId, data: data})
          .then((response) => {
            if (response.data.success) {
              this.$message({message: "保存成功",type: "success"})
              this.questionDialogVisi = false
              this.selectTeacherId = null
            }
          })
    },

    //保存
    toSave () {
      if (this.multipleSelection.length !== 0) {
        this.axios.post("/api/exam/saveteacher", { examid: this.search2.examid, subjectid: this.search2.subjectid, teacherList: this.multipleSelection})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "保存成功",type: "success"})
                this.loadTeacherOfExam()
                this.loadTeacherOfUnit()
              }
            })

      }
      else
        this.$message({message: "请勾选教师！", type: "warning"})
    },

  }
}
</script>

<style scoped>

</style>
