<script>


import {useUserStoreWithOut} from "@/store/modules/user";

export default {
  name: "ProgressComponent",
  props: {
    height: {
      type: String,
      default: "300px"
    },
    width: {
      type: String,
      default: "300px"
    },
    name:{
      type: String,
      default: ""
    }
  },
  data(){
    return{
      progressData:{
        current: 0,
        total: 0,
        title: "",
        name:""
      }
    }
  },
  computed:{
    progressPercentage(){
      if (this.progressData.current === 0 && this.progressData.total === 0){
        return 0
      }else {
        return this.progressData.current * 100 / this.progressData.total
      }
    }
  },
  methods: {
    onWebSocketProgressMessage(msg){
      const progressData = JSON.parse(msg.data)
      if (this.$props.name === progressData.name){
        this.progressData = progressData
      }
      // 发送事件
      this.$emit("onMessage", progressData)
    }
  },
  setup(){
    const userStore = useUserStoreWithOut()
    return {userStore}
  },
  mounted() {
    this.userStore.addWebSocketOnMessageFunc(this.onWebSocketProgressMessage)
  },
  unmounted() {
    this.userStore.delWebSocketOnMessageFunc(this.onWebSocketProgressMessage)
  }
}
</script>

<template>
  <div :style=" { height: height, width: width }">
    <el-progress type="line" :percentage="progressPercentage">
      <template #default="{ percentage }">
        <span class="percentage-value">{{ percentage.toFixed(2) }}%</span>
        <span class="percentage-label">{{progressData.title}}</span>
      </template>
    </el-progress>
  </div>
</template>

<style scoped>
</style>