let scrollBarWidth = null

export default {

    // 检查是不是手机用户
    checkIfMobile() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // 常见的移动设备关键词
        return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase());
    },

  // 将时间戳转换成日期格式
  // date是1970年1月1日至今的毫秒数
  formatDate(timeStamp, fmt='yyyy-MM-dd HH:mm:ss') {
     if (!timeStamp) {
       return ""
     }
    let date = new Date(timeStamp)
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'H+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    }
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + ''
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : this.padLeftZero(str))
        }
    }
    return fmt
  },

  /**
   * 组合成二级树的结构
   * @param dataArr 要组合的数据
   * @param parentKey 父级的key
   * @param parentLabel 父级的label
   * @param childKey 子级的key
   * @param childLabel 子级的label
   * @param parentAll 是否在父级前面加上全部
   * @param childAll 是否在子级前面加上全部
   * @return arr 返回最终组合好的数据
   */
    combineTreeData(dataArr,
                    parentKey,
                    parentLabel,
                    childKey,
                    childLabel,
                    parentAll = true,
                    parentAllValue = -1,
                    parentAllLabel = '全部',
                    childAll = true,
                    childAllValue = -1,
                    childAllLabel = '全部'

  ) {
      let temp = {};
      if (parentAll){
        temp['all'] = {
          value: parentAllValue,
          label: parentAllLabel,
          children: []
        }
      }
      dataArr.forEach((item) => {
        const pValue = item[parentKey]
        const pLabel = item[parentLabel]
        const cValue = item[childKey]
        const cLabel = item[childLabel]

        let parent = temp[pValue]
        if (!parent) {
          // 如果没有父级，就创建一个父级
            temp[pValue] = {
                value: pValue,
                label: pLabel,
                children: []
            }
            if (childAll){
              // 如果需要子级全部，就在父级的子级里面加上全部
              temp[pValue].children.push({
                value: childAllValue,
                label: childAllLabel
              })
            }
        }

        // 如果有父级，就把子级放进去
        temp[pValue].children.push({
          value: cValue,
          label: cLabel
        })
      })
      // 最后把temp转成数组
        let arr = []
        for (let key in temp) {
          const tempElement = temp[key]
          // 当children的长度为0时并且childAll为true时 或者
          // 当children的长度为1时并且childAll为true时
          // 就给这个parent添加disabled: false
            if ((tempElement.children.length === 0 && childAll) || (tempElement.children.length === 1 && childAll)){
                tempElement.disabled = true
            }
          // arr.push(tempElement)
          // 在arr按反方向插入
            arr.unshift(tempElement)
        }
        return arr
  },
  // 获取浏览器的滚动条宽度
  getScrollbarWidth() {
    if (scrollBarWidth !== null) {
      return scrollBarWidth
    }
    // 创建一个临时的div元素
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // 让这个元素强制显示滚动条
    document.body.appendChild(outer);

    // 创建一个内部div并设置其宽度为100%
    const inner = document.createElement('div');
    inner.style.width = '100%';
    outer.appendChild(inner);

    // 计算滚动条的宽度
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    // 移除临时创建的元素
    outer.parentNode.removeChild(outer);

    scrollBarWidth = scrollbarWidth
    return scrollbarWidth;
  },

  // 获取当前时间，给定一个时间格式
   getCurrentTime(format = 'yyyy-MM-dd HH:mm:ss') {
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()
    return format.replace('yyyy', year)
      .replace('MM', month < 10 ? '0' + month : month)
      .replace('dd', day < 10 ? '0' + day : day)
      .replace('HH', hour < 10 ? '0' + hour : hour)
      .replace('mm', minute < 10 ? '0' + minute : minute)
      .replace('ss', second < 10 ? '0' + second : second)
    },
  padLeftZero(str) {
    return ('00' + str).substr(str.length)
  }
}
