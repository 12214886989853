
<template>
  <div class="dashboard-container">
    <!-- 基本信息 -->
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>基本信息</span>
      </div>
      <div class="basic-info">
        <el-row :gutter="20">
          <el-col :span="6"><b>Redis版本:</b> {{ redisInfo.version }}</el-col>
          <el-col :span="6"><b>运行方式:</b> {{ redisInfo.mode }}</el-col>
          <el-col :span="6"><b>单机或集群:</b> {{ redisInfo.instanceType }}</el-col>
          <el-col :span="6"><b>端口:</b> {{ redisInfo.port }}</el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 10px;">
          <el-col :span="6"><b>运行时间(天):</b> {{ redisInfo.uptimeDays }}</el-col>
          <el-col :span="6"><b>用户内存:</b> {{ redisInfo.usedMemory }}</el-col>
          <el-col :span="6"><b>客户端数:</b> {{ redisInfo.clientCount }}</el-col>
          <el-col :span="6"><b>缓存配置:</b> {{ redisInfo.maxMemory }}</el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 10px;">
          <el-col :span="6"><b>AOF是否开启:</b> {{ redisInfo.aofEnabled }}</el-col>
          <el-col :span="6"><b>RDB是否成功:</b> {{ redisInfo.rdbStatus }}</el-col>
          <el-col :span="6"><b>Key数量:</b> {{ redisInfo.keyCount }}</el-col>
          <el-col :span="6"><b>网络输入/输出:</b> {{ redisInfo.networkInputOutput }}</el-col>
        </el-row>
      </div>
    </el-card>

    <!-- 命令统计 -->
    <el-card class="box-card" style="margin-top: 20px;">
      <div slot="header" class="clearfix">
        <span>命令统计</span>
      </div>
      <div id="command-statistics" class="chart"></div>
    </el-card>

    <!-- 内存信息 -->
    <el-card class="box-card" style="margin-top: 20px;">
      <div slot="header" class="clearfix">
        <span>内存信息</span>
      </div>
      <div id="memory-info" class="chart">
        <el-progress :percentage="memoryInfo.usedPercentage" status="success"/>
        <div class="memory-usage">内存消耗: {{ memoryInfo.usedMemory }}</div>
      </div>
    </el-card>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "RedisManager",
  data() {
    return {
      redisInfo: {},
      memoryInfo: {
        usedPercentage: 0,
        usedMemory: "0B",
      },
    }
  },
  created() {
    this.getRedisInfo()
  },
  mounted() {
    this.loadCharts()
  },
  methods: {
    async getRedisInfo() {
      try {
        const response = await this.axios.get('/api/redis/info');
        if (response.data.success){
          const data = response.data.result
          this.redisInfo = {
            version: data.redis_version,
            mode: data.redis_mode,
            instanceType: data.role === 'master' ? '单机' : '集群',
            port: data.tcp_port,
            uptimeDays: Math.floor(data.uptime_in_seconds / 86400),
            usedMemory: data.used_memory_human,
            clientCount: data.connected_clients,
            maxMemory: data.maxmemory_human,
            aofEnabled: data.aof_enabled ? '是' : '否',
            rdbStatus: data.rdb_last_bgsave_status,
            keyCount: data.db0 ? data.db0.split(',')[0].split('=')[1] : 0,
            networkInputOutput: `${data.instantaneous_input_kbps}kps/${data.instantaneous_output_kbps}kps`,
          };
          this.memoryInfo.usedPercentage = (parseInt(data.used_memory) / parseInt(data.maxmemory || 1)) * 100;
          this.memoryInfo.usedMemory = data.used_memory_human;
        }

      } catch (error) {
        console.error('Failed to fetch Redis info:', error);
      }
    },
    loadCharts() {
      // 命令统计图表渲染
      this.drawCommandStatistics();
      // 内存信息
      this.drawMemoryUsage();
    },
    drawCommandStatistics() {
      // 使用 ECharts 或其他图表库来渲染命令统计图表
      const chartDom = document.getElementById("command-statistics");
      const myChart = echarts.init(chartDom);
      const option = {
        title: {
          text: "Redis 命令统计",
        },
        tooltip: {},
        series: [
          {
            type: "pie",
            data: [
              {value: 1048, name: "rpush"},
              {value: 735, name: "set"},
              {value: 580, name: "expire"},
              {value: 350, name: "get"},
              {value: 300, name: "lpush"},
              {value: 250, name: "del"},
              {value: 200, name: "hset"},
              {value: 180, name: "zadd"},
              // 添加更多命令统计数据...
            ],
          },
        ],
      };
      myChart.setOption(option);
    },
    drawMemoryUsage() {
      // 内存消耗信息的图表化表示
      // 本示例使用简单的进度条展示，实际中可以使用更复杂的图表
    },
  },
};
</script>

<style scoped>
.dashboard-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.box-card {
  margin-bottom: 20px;
}

.memory-usage {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
}

.chart {
  width: 100%;
  height: 400px;
}
</style>
