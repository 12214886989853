<template>
  <el-container class="base-container">

    <el-main class="main-container">
      <el-space>
        <el-input v-model="paperInfo.studentid"  style="width: 200px; min-width: 80px;" placeholder="学生id" ></el-input>
        <el-button siz="default" type="primary"  @click="loadPaperInfo">查询</el-button>
      </el-space>
      <el-progress
          v-show="showProgress"
          :text-inside="true"
          :stroke-width="24"
          :percentage="this.percentage"
          status="success"
      ></el-progress>
      <el-upload
          ref="paperUpload"
          :action="uploadUrl"
          :on-success="uploadsuccess"
          :on-error="uploaderror"
          :on-progress="getProgress"
          name="uploadfiles"
          :data='data'
          v-model:file-list="uploadFiles"
          :auto-upload="false"
          :file-list="fileList"
          multiple
      >
        <el-button type="primary" @click="checkPaper">选择试卷</el-button>
      </el-upload>
      <el-button type="success" @click="doUpload">上传</el-button>
      <el-table
          :data="paperInfoList"
          style="width: 100%">
        <el-table-column prop="studentid" label="密号" width="180"></el-table-column>
        <el-table-column prop="examid" label="考试ID" width="180"></el-table-column>
        <el-table-column prop="subjectid" label="科目ID" width="180"></el-table-column>
        <el-table-column prop="page" label="页码" width="100"></el-table-column>
        <el-table-column prop="answers" label="客观题答案"></el-table-column>
      </el-table>
      <el-pagination
          @current-change="toPage"
          :current-page="paperInfo.page"
          :page-size="paperInfo.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="total"
          style="margin-left: 10px">
      </el-pagination>
    </el-main>
  </el-container>
</template>

<script>
import {useAppStoreWithOut} from "@/store/modules/app";

export default {
  name: "ExamUpload",
  data() {
    return {
      total: 0,
      fileList:[],
      paperInfoList: [],
      showButton: true,
      interval: null,
      erroInfo: "",
      uploadInfo: "",
      showProgress:false,
      paperId: "",
      fileData: "",
      loading: null,
      percentage: 0,
      paperInfo: {
        page: 1,
        pagesize: 15,
        paperid: "",
        examid: "",
        subjectid: "",
        studentid:""
      },
      data: {
        "examid": "",
        "subjectid": "",
        "paperid": "",
        "filesize": ""
      },
      paperList: [],
      uploadFiles: [],
      filesize: 0,
      uploadUrl: "/api/examupload/getuploadpapers"
    }
  },
  setup(){
    const appStore = useAppStoreWithOut()
    return {appStore}
  },
  watch: {
    paperId: {
      immediate: true,
      handler(value) {
        if (value !== "") {
          this.showButton = false
          let paper
          let i = 0
          for (i = 0; i < this.paperList.length; i++) {
            if (this.paperList[i].paper_id === this.paperId) {
              paper = this.paperList[i]
              break
            }
          }
          this.paperInfo.subjectid = paper.subject_id
          this.paperInfo.paperid = paper.paper_id
          this.paperInfo.examid = paper.exam_id
        } else {
          this.paperInfo.subjectid = ""
          this.paperInfo.paperid = ""
          this.paperInfo.examid = ""
          this.showButton = true
        }
        // this.loadPaperInfo()
      }
    }
  },
  mounted() {
    this.paperInfo.examid = this.appStore.exam_id
    this.paperInfo.subjectid = this.appStore.subject_id
    this.loadPaperInfo()
  },
  methods: {
    toPage(page) {
      this.paperInfo.page = page
      this.loadPaperInfo()
    },
    loadPaperInfo() {
      this.axios.post("/api/examupload/getuploadpaperinfolist", this.paperInfo).then((response) => {
        if (response.data.success) {
          this.total = response.data.result.totalrecords
          this.paperInfoList = response.data.result.datalist
        }
      })
    }
    ,

    uploadsuccess(response, file, fileList) {
      this.uploadFiles=[]
      clearInterval(this.interval)

      if (response.success){
        this.uploadInfo = response.result
        this.loadPaperInfo()
        this.showProgress=false
        this.percentage=0
      }
      else {
        this.showProgress=false
        this.percentage=0
        this.erroInfo = this.erroInfo + response.result
        this.$messageBox.alert(this.erroInfo)
        this.$refs.paperUpload.abort()
      }
    },

    getProgress(event, file, fileList) {
      this.showProgress = true;
      // 假设你想显示所有文件的平均上传进度
      let totalFiles = fileList.length;
      let totalPercentage = fileList.reduce((acc, file) => acc + file.percentage, 0);
      // 计算整体进度
      this.percentage = totalPercentage / totalFiles;
      console.log(this.percentage);
    },


    uploaderror(err) {
      this.uploadFiles=[]
      this.showProgress=false
      this.percentage=0
      clearInterval(this.interval)

      this.erroInfo = this.erroInfo + err.toString()
      this.$message({message: "文件上传失败。" + err.toString(), type: "error",})
    },
    checkPaper() {
      // this.$refs.paperUpload.clearFiles()
      this.erroInfo = ""
      this.uploadInfo = ""
    },
    doUpload() {
      this.data.examid = this.paperInfo.examid
      this.data.subjectid = this.paperInfo.subjectid
      this.data.paperid = this.paperInfo.paperid
      this.data.filesize = this.filesize
      this.$refs.paperUpload.submit()
    }

  }
}
</script>

<style scoped>

</style>
