<script>
import ProgressComponent from "@/components/utils/ProgressComponent.vue";
import {CheckOne} from "@icon-park/vue-next";
import {useAppStoreWithOut} from "@/store/modules/app";


export default {
  name: "ViewDataAnalyseAndCheck",
  components: {ProgressComponent},
  data(){
    return{
      activities: [
        {
          timestamp: '所有非缺考试卷都应该进入阅卷流程',
          type: 'info',
        },
        {
          timestamp: '所有的非缺考试卷每一个题组应该一致',
          type: 'info',
        },
        {
          timestamp: '判断子题的分值是否在原定的区间内',
          type: 'info',
        },
        {
          timestamp: '判断大题的分值是否在原定的区间内',
          type: 'info',
        },
        {
          timestamp: '判断客观题的计算方式',
          type: 'info',
        },
        {
          timestamp: '日志表中tag加起来应该等于得分(校验日志表数据正确性)',
          type: 'info'
        },
        {
          timestamp: '判断大题分是否等于小题分',
          type: 'info'
        },
        {
          timestamp: '判断评卷模式在日志表中的正确性(校验日志表数据正确性)',
          type: 'info'
        },
        {
          timestamp: '判断题组的得分是否等于科目总分',
          type: 'info'
        },
        {
          timestamp: '判断是否存在科目总分大于本科目的总分的',
          type: 'info'
        },
        {
          timestamp: '判断是否存在满分试卷',
          type: 'info'
        },
        {
          timestamp: '扫描分析',
          type: 'info'
        },
      ]
    }
  },
  setup(){
    const appStore = useAppStoreWithOut()
    return {appStore}
  },
  methods:{
    // 将activities的状态置为loading
    setActivitiesLoading(index){
      if (index !== undefined && index !== null){
        this.activities[index].type = 'primary'
      }else {
        this.activities.forEach(item => item['type'] = 'primary')
      }
    },
    // 将activities的状态还原
    setActivitiesNormal(index){
      if (index !== undefined && index !== null){
        this.activities[index].type = 'info'
      }else {
        this.activities.forEach(item => item['type'] = 'info')
      }
    },
    // 将activities的状态置为成功
    setActivitiesSuccess(index) {
      if (index !== undefined && index !== null){
        this.activities[index].type = 'success'
        delete this.activities[index].data
      }else {
        this.activities.forEach(item => {
          item['type'] = 'success'
          delete this.item.data
        })
      }
    },
    // 将activities的状态置为失败
    setActivitiesError(index,data) {
      if (index !== undefined && index !== null){
        this.activities[index].type = 'danger'
        this.activities[index].data = data
        this.activities[index].data.cols = Object.keys(data.datalist[0])
        this.activities[index].data.search = {
          page: data.page,
          pagesize: data.pagesize,
          total: data.totalrecords
        }
      }else {
        this.activities.forEach(item => item['type'] = 'danger')
      }
    },
    // 开始分析
    startAnalyse() {
      this.setActivitiesLoading()
      try{
        this.axios.post('/api/exam/viewDataAnalyseAndCheck/startAnalyse',{exam_id: this.appStore.exam_id},{establishWS: true})
            .then(response => {
              if (response.data.success){
                this.$message.success(response.data.result)
              }
            })
            .catch(error => {
              console.error("Error starting task:", error);
            });
      }catch (e) {
        this.setActivitiesNormal()
      }

    },
    // websocket消息
    onMessage(msg){
      const title = msg.data.otherInfo
      const data = msg.data
      const index = parseInt(title.toString().split("、")[0]) - 1
      if (data.totalrecords === 0){
        this.setActivitiesSuccess(index)
      }else {
        this.setActivitiesError(index,data)
      }
    },
    tableSizeChange(activity,p){
      this.axios.post('/api/exam/viewDataAnalyseAndCheck/list',{
        exam_id: this.appStore.exam_id,
        ...activity.data.search,
        sqlIndex: activity.data.otherInfo.toString().split("、")[0]
      })
        .then(response => {
          if (response.data.success){
            const res = response.data.result
            activity.data.datalist = res.datalist
            activity.data.search.page = res.page
            activity.data.search.pagesize = res.pagesize
            activity.data.search.total = res.totalrecords
          }
        })
    }
  }
}
</script>

<template>
  <div>
    <el-row>
      <el-col :span="20">
        <ProgressComponent name="ViewDataAnalyseAndCheck" width="90%" @onMessage="onMessage" height="50px"/>
      </el-col>
      <el-col :span="4">
        <el-button style="width: 100%" text bg type="primary" @click="startAnalyse">开始分析</el-button>
      </el-col>
    </el-row>
    <el-timeline>
      <el-timeline-item
          v-for="(activity, index) in activities"
          :key="index"
          :type="activity.type"
          size="large"
          :hollow="true"
          :timestamp="activity.timestamp"
          placement="top"
      >
        <div v-loading="activity.type == 'primary'"
             v-if="['danger','primary'].includes(activity.type) "
             >
          <el-pagination background
                         @current-change="(p) => tableSizeChange(activity,p)"
                         v-if="activity?.data?.search"
                         layout="total,prev, pager, next"
                         :total="activity.data.search.total"
                         v-model:current-page="activity.data.search.page"
                         :page-size="activity.data.search.pagesize"
                         small
          />
          <el-table :data="activity?.data?.datalist" style="width: 100%">
            <el-table-column :prop="r"
                             :label="r"
                             v-for="(r,i) in activity?.data?.cols"
                             :key="i"
                              />
          </el-table>
        </div>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<style scoped>

</style>