<!--
考试科目管理界面
上传科目试卷模板，划分配置题组子题
最后编辑人：李正华
最后编辑时间：2022-4-20
最后编辑细节：修复bug
-->
<template>
  <el-container class="base-container">
    <el-header style="height: 20px; padding-right: 0;">
      <el-breadcrumb separator="/" style="display: inline-block; width: calc(100% - 100px);">
        <el-breadcrumb-item>考试管理</el-breadcrumb-item>
        <el-breadcrumb-item>科目试卷模板配置</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button siz="default" class="page-back-button" style="float: right;" @click="$router.back()">
        <el-icon>
          <back/>
        </el-icon>
        返回
      </el-button>

    </el-header>
    <el-container class="main-container" ref="mainSectorSubject" id="mainSectorSubject">
      <el-header style="height: 32px; padding-right: 0;">
        <div style="display: flex; justify-content: space-between;">
          <div class="exam-title">{{ exam.exam_name }}</div>
          <div class="exam-subject">
            <span style="margin-right: 5px;">科目：</span>
            <el-select siz="default" v-model="search.subjectid" placeholder="请选择科目" @change="selectSubject">
              <el-option
                  v-for="item in exam.subjects"
                  :key="item.subject_id"
                  :label="item.subject_name"
                  :value="item.subject_id">
              </el-option>
            </el-select>
          </div>
        </div>
      </el-header>
      <el-container style="height: calc(100% - 32px);">
        <el-main style="padding: 10px 0 0 0;z-index: 999">
          <LayerCanvas
              ref="paperCanvas"
              :layer-data="paperLayers"
              :valid-area="validAreas"
              :zoomable="true"
              :after-zoom="renderSubject"
              :dragable="canvasDragable"
              :after-render="afterCanvasRender"
              @mousedown="canvasMouseDown"
              @mousemove="canvasMouseMove"
              @mouseup="canvasMouseUp"
              @mouseleave="canvasMouseLeave"></LayerCanvas>
        </el-main>
        <el-aside style="height: 100%; padding-top: 10px; position: relative;z-index: 997">
          <el-tabs type="border-card" v-model="activeTab" style="height: calc(100% - 5px);">
            <el-tab-pane label="试卷图片" name="paperTab">
              <el-upload
                  ref="paperUpload"
                  action="/api/exam/uploadexamsubjectpaper"
                  name="imgfile"
                  list-type="text"
                  accept="image/png,image/jpeg,image/gif"
                  :show-file-list="false"
                  :data="search"
                  :file-list="uploadFileList"
                  :on-success="uploadResult"
                  :on-error="uploadError">
                <el-button siz="default" type="primary" icon="Plus">试卷图片</el-button>
                <div class="el-upload__tip">只能上传jpg/png文件</div>
              </el-upload>
              <el-divider></el-divider>
              <el-tree
                  :data="subjectPapers"
                  :props="paperTreeProps"
                  node-key="paper_id"
                  default-expand-all
                  draggable
                  @node-drop="paperDrop"
                  :expand-on-click-node="false"
                  ref="paperTree">
                <template v-slot="{ node, data }">
                  <div class="paper-row">
                      <span>
                    <i class="picture-outline"></i>
                    {{ node.label }}
                  </span>
                    <span style="padding-left:10px">
                    <el-tooltip content="删除" placement="top" effect="light" transition="" :enterable="false">
                      <el-button type="danger" class="tree-button" icon="delete"
                                 @click="() => toPaperDel(data)"></el-button>
                    </el-tooltip>
                  </span>
                  </div>

                </template>
              </el-tree>
            </el-tab-pane>
            <el-tab-pane label="试卷配置" name="propTab">
              <el-form :model="propData" :rules="propRule" ref="subForm" size="default" label-width="80px">
                <el-form-item label="试卷名称" prop="name">
                  <el-input v-model="propData.name" style="width: 210px;"></el-input>
                </el-form-item>
                <el-form-item label="信息遮盖区域">
                  <el-button size="default" :type="maskAreaAddButton" round icon="Plus" @click="toMaskAreaAdd"
                             :disabled="paperLayers.length === 0">区域
                  </el-button>
                  <el-tree
                      :data="propData.maskarea"
                      default-expand-all
                      ref="maskAreaTree">
                    <template v-slot="{ node, data }">
                      <div class="paper-row">
                         <span :class="{'area-select': activeArea===data}" @click="() => selectMaskArea(data)">
                        {{ data.p + "-(" + data.x + "," + data.y + "):(" + data.w + "," + data.h + ")" }}
                      </span>
                        <span style="padding-left:5px">
                        <el-tooltip content="删除遮盖区域" placement="top" effect="light" transition=""
                                    :enterable="false">
                          <el-button type="danger" class="tree-button" icon="delete"
                                     @click="() => removeMaskArea(data)"></el-button>
                        </el-tooltip>
                      </span>
                      </div>

                    </template>
                  </el-tree>
                </el-form-item>
                <el-form-item label="条码识别区域">
                  <el-button size="default" :type="codeAreaAddButton" round icon="Plus" @click="toCodeAreaAdd"
                             :disabled="paperLayers.length === 0">区域
                  </el-button>
                  <el-tree
                      :data="propData.codearea"
                      default-expand-all
                      ref="maskAreaTree">
                    <template v-slot="{ node, data }">
                      <div class="paper-row">  <span :class="{'area-select': activeArea===data}"
                                                     @click="() => selectCodeArea(data)">
                        {{ data.p + "-(" + data.x + "," + data.y + "):(" + data.w + "," + data.h + ")" }}
                      </span>
                        <span style="padding-left:5px">
                        <el-tooltip content="删除条码区域" placement="top" effect="light" transition=""
                                    :enterable="false">
                          <el-button type="danger" class="tree-button" icon="delete"
                                     @click="() => removeCodeArea(data)"></el-button>
                        </el-tooltip>
                      </span></div>

                    </template>
                  </el-tree>
                </el-form-item>
              </el-form>
              <div style="text-align: center;">
                <el-button type="primary" @click="toPropSave">保 存</el-button>
              </div>
            </el-tab-pane>
            <el-tab-pane label="题组配置" name="questionTab">
              <el-button siz="default" type="primary" icon="Plus" @click="toQuestionAdd"
                         :disabled="paperLayers.length === 0" style="margin-bottom: 10px;">题组
              </el-button>
              <el-tree
                  :data="questionList"
                  :props="questionTreeProps"
                  node-key="uid"
                  default-expand-all
                  draggable
                  @node-drop="questionDrop"
                  :allowDrop="questionAllowDrop"
                  :expand-on-click-node="false"
                  ref="quesTree">
                <template v-slot="{ node, data }">
                  <div class="paper-row">
   <span @click="() => toQuestionEdit(data)">
                      <i class="document"></i>
                      <span v-if="typeof data.subquestion_name === 'undefined'">{{ data.question_name }}</span>
                      <span v-else>{{
                          data.content != null && data.content.is_multi ? data.subquestion_name + "(多选)" : data.subquestion_name
                        }}</span>
                      <el-tooltip content="区域缺失" placement="top" effect="light" transition="" :enterable="false"
                                  v-if="(typeof data.subquestion_name === 'undefined' && data.question_area.length === 0) || (typeof data.subquestion_name !== 'undefined' && data.subquestion_area.length === 0)">
                      </el-tooltip>

                    </span>
                    <span style="padding-left:10px">
                      <el-tooltip content="新增子题" placement="top" effect="light" transition="" :enterable="false"
                                  v-if="typeof data.subquestion_name === 'undefined' && data.question_type === '2'">
                        <el-button type="primary" size="small" class="tree-button" icon="Plus"
                                   @click="() => toSubquestionAdd(data)"></el-button>
                      </el-tooltip>
                      <el-tooltip content="删除" placement="top" effect="light" transition="" :enterable="false">
                        <el-button type="danger" size="small" class="tree-button" icon="delete"
                                   @click="() => toQuestionDel(data)"></el-button>
                      </el-tooltip>
                    </span>
                  </div>

                </template>
              </el-tree>
            </el-tab-pane>
          </el-tabs>
          <el-drawer
              ref="quesDrawer"
              :title="quesTitle"
              class="side-drawer"
              v-model="quesDrawerVisi"
              :modal="false"
              :close-on-click-modal="false"
              :append-to-body="false"
              :size="299"
              @close="drawerClose"
              @opened="() => $refs.quesForm.clearValidate()">
            <el-form :model="quesData" :rules="quesRule" ref="quesForm" size="small" label-width="80px">
              <el-form-item label="名称" prop="name">
                <el-input v-model="quesData.name" style="width: 210px;" @blur="drawEditQuestion"></el-input>
              </el-form-item>
              <el-form-item label="类型" prop="type">
                <el-radio-group v-model="quesData.type">
                  <el-radio label="1">客观题</el-radio>
                  <el-radio label="2">主观题</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="区域">
                <el-button size="default" :type="quesAreaAddButton" round icon="Plus" @click="toQuestionAreaAdd">区域
                </el-button>
                <span class="el-upload__tip" style="margin-left: 10px;"
                      v-if="optMode === 'ques-area-add'">请框选新区域</span>
                <el-tree
                    :data="quesData.area"
                    default-expand-all
                    draggable
                    :allowDrop="areaAllowDrop"
                    @node-drop="areaDrop"
                    ref="quesAreaTree">
                  <template v-slot="{ node, data }">
                  <span :class="{'area-select': activeArea===data}" @click="() => selectArea(data)">
                    {{ data.p + "-(" + data.x + "," + data.y + "):(" + data.w + "," + data.h + ")" }}
                  </span>
                    <span style="padding-left:5px">
                    <el-tooltip content="删除题组区域" placement="top" effect="light" transition="" :enterable="false">
                      <el-button type="danger" class="tree-button" icon="delete"
                                 @click="() => removeQuestionArea(data)"></el-button>
                    </el-tooltip>
                  </span>
                  </template>
                </el-tree>
              </el-form-item>
              <el-form-item label="" v-if="quesData.type === '1' && quesData.quesid != null">
                <el-button size="default" type="primary" round icon="Plus" @click="toSubquestionBatchAdd">批量添加子题
                </el-button>
              </el-form-item>
              <el-form-item label="评卷模式" v-if="quesData.type === '2'">
                <el-select v-model="quesData.content.mark_mode" placeholder="请选择">
                  <el-option
                      v-for="item in markModeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="限制时间" v-if="quesData.type === '2'">
                <el-input v-model="quesData.content.limit_time" style="width: 100px;"></el-input>
              </el-form-item>
              <el-form-item label="满分">
                <el-input-number :min="0" v-model="quesData.content.full_score" style="width: 100px;"></el-input-number>
              </el-form-item>
              <el-form-item label="分数间隔" v-if="quesData.type === '2'">
                <el-input-number :min="0" v-model="quesData.content.score_interval"
                                 style="width: 100px;"></el-input-number>
              </el-form-item>
              <el-form-item label="有效分数" prop="validScoreStr" v-if="quesData.type === '2'">
                <el-input v-model="quesData.validScoreStr" style="width: 210px;"></el-input>
              </el-form-item>
              <el-form-item label="快捷键" v-if="quesData.type === '2'">
                <el-popover
                    placement="left"
                    width="290"
                    trigger="click"
                    v-model="shortCutsPopVisi">
                  <div style="max-height: 400px; overflow-y: auto;text-align: center;">
                    <el-row type="flex" v-for="(item, idx) in quesData.content.shortcuts" :key="idx"
                            style="align-items: baseline; margin-bottom: 5px;">
                      <el-col :span="5" style="text-align: right; padding-right: 8px;">快捷键</el-col>
                      <el-col :span="4">
                        <el-input v-model="item.key"></el-input>
                      </el-col>
                      <el-col :span="4" style="text-align: right; padding-right: 8px;">分值</el-col>
                      <el-col :span="8">
                        <el-input-number controls-position="right" :min="0" :max="quesData.content.full_score"
                                         v-model="item.score" style="width: 80px;"></el-input-number>
                      </el-col>
                      <el-col :span="3" style="padding-left: 8px;">
                        <el-button type="danger" class="tree-button" icon="delete"
                                   @click="removeShortcut(idx)"></el-button>
                      </el-col>
                    </el-row>
                    <el-button size="default" type="primary" round icon="Plus" @click="addShortcut">添加快捷键
                    </el-button>
                  </div>
                  <el-button size="default" type="primary" round icon="edit" slot="reference">编辑快捷键</el-button>
                </el-popover>
              </el-form-item>
              <el-form-item label="误差分数"
                            v-if="quesData.type === '2' && (quesData.content.mark_mode === '3' || quesData.content.mark_mode === '4' || quesData.content.mark_mode === '5')">
                <el-input-number :min="0" v-model="quesData.content.score_diff" style="width: 100px;"></el-input-number>
              </el-form-item>
              <el-form-item label="自评?" v-if="quesData.type === '2'">
                <el-radio-group v-model="quesData.content.remark_flag">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="自评间隔" v-if="quesData.type === '2' && quesData.content.remark_flag">
                <el-input-number :min="1" v-model="quesData.content.remark_interval"
                                 style="width: 100px;"></el-input-number>
                份
              </el-form-item>
              <el-form-item label="选做题?" v-if="quesData.type === '2'">
                <el-radio-group v-model="quesData.content.choose_flag">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="覆盖率" v-if="quesData.type === '1'">
                <el-input-number :min="0" :max="100" v-model="quesData.content.coverage"
                                 style="width: 100px;"></el-input-number>
              </el-form-item>
              <el-form-item label="识别色深" v-if="quesData.type === '1'">
                <el-input-number :min="0" :max="100" v-model="quesData.content.depth"
                                 style="width: 100px;"></el-input-number>
              </el-form-item>
            </el-form>
            <div style="text-align: center;">
              <el-button @click="$refs['quesDrawer'].handleClose()">取 消</el-button>
              <el-button type="primary" @click="toQuestionSave">保 存</el-button>
            </div>
          </el-drawer>
          <el-drawer
              ref="subDrawer"
              :title="subTitle"
              class="side-drawer"
              :v-model="subDrawerVisi"
              :modal="false"
              :close-on-click-modal="false"
              :append-to-body="false"
              :size="299"
              @close="drawerClose"
              @opened="() => $refs.subForm.clearValidate()">
            <el-form :model="subData" :rules="subRule" ref="subForm" size="default" label-width="80px">
              <el-form-item label="名称" prop="name">
                <el-input v-model="subData.name" style="width: 210px;" @blur="drawEditQuestion"></el-input>
              </el-form-item>
              <el-form-item label="区域">
                <el-button size="default" :type="subAreaAddButton" round icon="Plus" @click="toSubquestionAreaAdd"
                           v-if="subData.type === '2'">区域
                </el-button>
                <span class="el-upload__tip" style="margin-left: 10px;"
                      v-if="optMode === 'sub-area-add'">请在题组区域内框选新区域</span>
                <el-tree
                    :data="subData.area"
                    default-expand-all
                    draggable
                    :allowDrop="areaAllowDrop"
                    @node-drop="areaDrop"
                    ref="subquesAreaTree">
                  <template v-slot="{ node, data }">
                    <div class="paper-row"> <span :class="{'area-select': activeArea===data}"
                                                  @click="() => selectArea(data)">
                    {{ data.p + "-(" + data.x + "," + data.y + "):(" + data.w + "," + data.h + ")" }}
                  </span>
                      <span style="padding-left:5px">
                    <el-tooltip content="删除子题区域" placement="top" effect="light" transition="" :enterable="false"
                                v-if="subData.type === '2'">
                      <el-button type="danger" class="tree-button" icon="delete"
                                 @click="() => removeSubquestionArea(data)"></el-button>
                    </el-tooltip>
                  </span></div>

                  </template>
                </el-tree>
              </el-form-item>
              <el-form-item label="满分" prop="content.full_score">
                <el-input-number :min="0" v-model="subData.content.full_score" style="width: 100px;"></el-input-number>
              </el-form-item>
              <el-form-item label="打分点" v-if="subData.type === '2'">
                <el-button size="default" :type="pointAddButton" round icon="Plus" @click="toSubquestionPointAdd">
                  打分点
                </el-button>
                <span class="el-upload__tip" style="margin-left: 10px;" v-if="optMode === 'sub-point-add'">请在子题区域内点击添加打分点</span>
                <el-tree
                    :data="subData.content.points"
                    default-expand-all
                    draggable
                    :allowDrop="areaAllowDrop"
                    @node-drop="areaDrop"
                    ref="pointTree">
                  <template v-slot="{ node, data }">
                    <div class="paper-row"><span
                        :class="{'area-select': activePointIdx===subData.content.points.indexOf(data)}"
                        @click="() => selectArea(data)">
                    {{ data.p + "-(" + data.x + "," + data.y + ")" }}
                  </span>
                      <span style="padding-left:10px">
                    <el-tooltip content="删除打分点" placement="top" effect="light" transition="" :enterable="false">
                      <el-button type="danger" class="tree-button" icon="delete"
                                 @click="() => removeSubquestionPoint(data)"></el-button>
                    </el-tooltip>
                  </span></div>

                  </template>
                </el-tree>
              </el-form-item>
              <el-form-item label="多选题?" v-if="subData.type === '1'">
                <el-radio-group v-model="subData.content.is_multi">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
            <div style="text-align: center;">
              <el-button @click="$refs['subDrawer'].handleClose()">取 消</el-button>
              <el-button type="primary" @click="toSubquestionSave">保 存</el-button>
            </div>
          </el-drawer>
        </el-aside>

      </el-container>

      <el-dialog title="批量添加客观子题" v-model="subDialogVisi" width="90%" class="v-center_dialog"
                 :close-on-click-modal="false" :close-on-press-escape="false">
        <div style="display: flex; height: calc(100% - 74px);">
          <div style="flex-grow: 1; margin: 0; width: calc(100% - 300px); height: 100%">
            <LayerCanvas
                ref="objectiveCanvas"
                :layer-data="objectiveLayers"
                @mouseup="objectiveMouseUp"></LayerCanvas>
          </div>
          <div style="margin: 0; width: 300px; height: 100%;">
            <div style="height: calc(100% - 50px); overflow: auto;">
              <div style="padding-left: 20px;">
                <el-button-group>
                  <el-button size="default" icon="el-icon-zoom-in" @click="objectiveZoomIn">放大</el-button>
                  <el-button size="default" icon="el-icon-zoom-out" @click="objectiveZoomOut">缩小</el-button>
                </el-button-group>
              </div>
              <div style="margin-top: 10px;padding-left: 20px;">
                <el-button-group>
                  <el-button size="default" type="primary" icon="refresh" @click="refindOptions">重新识别选项
                  </el-button>
                </el-button-group>
              </div>
              <div style="margin-top: 10px;padding-left: 20px;">
                <el-button-group>
                  <el-button size="default" :type="optColDelButton" icon="Close" @click="toOptionColRemove">移除列
                  </el-button>
                  <el-button size="default" :type="optRowDelButton" icon="Close" @click="toOptionRowRemove">移除行
                  </el-button>
                </el-button-group>
              </div>
              <div style="margin-top: 10px;padding-left: 20px;">
                <el-button-group>
                  <el-button size="default" :type="firstOptSwitchButton" icon="collection-tag"
                             @click="toFirstOptSwitch">设置首选项
                  </el-button>
                  <el-button size="default" :type="setMultiButton" icon="notebook-2" @click="toSetMultiOpt">设置多选题
                  </el-button>
                </el-button-group>
              </div>

              <el-form :model="subDialogData" :rules="objRule" ref="objectiveForm" size="default" label-width="90px">
                <el-form-item label="排序类型" prop="direction">
                  <el-radio-group v-model="subDialogData.direction">
                    <el-radio label="1">横向</el-radio>
                    <el-radio label="2">纵向</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="开始序号" prop="startnum">
                  <el-input-number v-model="subDialogData.startnum" :min="1" :step="2"></el-input-number>
                </el-form-item>
              </el-form>

              <div style="margin-top: 10px;padding-left: 20px;">
                <el-button-group>
                  <el-button size="default" type="primary" icon="notebook-2" @click="generateSubNum">生成子题号
                  </el-button>
                </el-button-group>
              </div>
            </div>
            <div style="text-align: center;">
              <el-button type="primary" @click="batchAddSubquestions">保 存</el-button>
              <el-button @click="closeBatchOptionDialog">关 闭</el-button>
            </div>
          </div>
        </div>
      </el-dialog>
    </el-container>
  </el-container>
</template>

<script>
import LayerCanvas from "@/components/utils/LayerCanvas";
import "@/assets/css/common.css"

export default {
  name: "ManageExamSubjectPaper",
  components: {LayerCanvas},
  computed: {
    quesAreaAddButton() {
      return this.optMode === "ques-area-add" ? "success" : "primary"
    },
    subAreaAddButton() {
      return this.optMode === "sub-area-add" ? "success" : "primary"
    },
    pointAddButton() {
      return this.optMode === "sub-point-add" ? "success" : "primary"
    },
    maskAreaAddButton() {
      return this.optMode === "mask-area-add" ? "success" : "primary"
    },
    codeAreaAddButton() {
      return this.optMode === "code-area-add" ? "success" : "primary"
    },
    optColDelButton() {
      return this.optObjectiveMode === "col-remove" ? "success" : "primary"
    },
    optRowDelButton() {
      return this.optObjectiveMode === "row-remove" ? "success" : "primary"
    },
    firstOptSwitchButton() {
      return this.optObjectiveMode === "first-opt-switch" ? "success" : "primary"
    },
    setMultiButton() {
      return this.optObjectiveMode === "multi-opt-switch" ? "success" : "primary"
    }
  },
  watch: {
    "quesData.validScoreStr"(val) {
      this.validScoreStr = val
      this.quesData.content.valid_scores = this.validScoreRegex.test(val) ?
          this.quesData.content.valid_scores = JSON.parse("[" + val + "]") : []
    }
  },
  data() {
    return {
      examID: null,
      cvs: null,
      ctx: null,
      downPos: null,
      downMousePos: null,
      lastMousePos: {x: 0, y: 0},
      maskColor: "#cc1096", //遮盖区域框线颜色
      codeColor: "#cc8a10", //条码区域框线颜色
      quesColor: "#07817a", //题组框线颜色
      subquesColor: "#02b340",  //子题框线颜色
      optColor: "#93da04",  //选项框线颜色
      optTxtColor: "#da042b",  //单选题题号颜色
      optMultiTxtColor: "#8113e8",  //多选题题号颜色
      firstOptColor: "#8113e8",  //首选项框线颜色
      multiOptColor: "#138fe8",  //多选项框线颜色
      exam: {
        exam_id: null,
        exam_name: "",
        unit_id: null,
        unit_name: "",
        exam_state: "",
        subjects: []
      },
      search: {
        examid: null,
        subjectid: null
      },
      subjectPapers: [],
      paperTreeProps: {
        children: 'subpaper',
        label: 'file_name'
      },
      fitPaper: true, //为true是渲染试卷后自动缩放适应宽度
      paperLoaded: false,
      propLoaded: false,
      questionLoaded: false,
      questionList: [],
      questionTreeProps: {
        children: 'sub_question',
        label: 'question_name'
      },
      questionTreeHeight: 400,
      paperLayers: [],
      validAreas: [],
      canvasDragable: false,
      optMode: "",  //操作模式
      optObjectiveMode: "", //客观题操作模式
      activeMaskIdx: null,  //当前选中遮盖区域索引
      activeCodeIdx: null,  //当前选中条码区域索引
      activeQuesIdx: null,  //当前选中题组索引
      activeSubquesIdx: null, //当前选中子题索引
      activePointIdx: null, //当前选中打分点索引
      activeArea: null,
      paperVisi: false,
      uploadFileList: [],
      activeTab: "paperTab",
      propData: {
        examid: null,
        subjectid: null,
        name: "",
        maskarea: [],
        codearea: [],
        fillarea: []
      },
      quesTitle: "题组配置",
      quesDrawerVisi: false,
      subTitle: "子题配置",
      subDrawerVisi: false,
      drawerCloseRedraw: true,  //关闭抽屉时是否重绘
      drawerCloseClear: true,  //关闭抽屉时是否清除选中数据
      subDialogVisi: false,   //批量添加子题弹窗是否显示
      validScoreRegex: /^\d+(.\d+)?(\s*,\s*\d+(.\d+)?)*$/,
      quesData: {
        quesid: null,
        examid: null,
        subjectid: null,
        name: "",
        type: "",
        area: [],
        validScoreStr: "",
        content: {
          full_score: null,
          score_interval: 1,
          valid_scores: [],
          shortcuts: [],
          mark_mode: "",
          limit_time: 0,
          score_diff: null,
          remark_flag: false,
          remark_interval: null,
          choose_flag: false,
          coverage: 70,
          depth: 70
        }
      },
      subData: {
        subid: null,
        quesid: null,
        examid: null,
        subjectid: null,
        type: "",
        num: null,
        name: "",
        area: [],
        content: {
          full_score: null,
          points: [],
          is_multi: false
        }
      },
      subDialogData: {
        direction: "2",  //子题方向，1-横向，2-纵向
        startnum: 1 //子题开始序号
      },
      objectiveLayers: [],
      objectiveOptionList: [],
      objectiveAreaList: [],  //弹窗中各区域的范围列表
      markModeList: [
        {
          value: '1',
          label: '1评'
        }, {
          value: '2',
          label: '2评'
        }, {
          value: '3',
          label: '2+1'
        }, {
          value: '4',
          label: '1+1+1'
        }, {
          value: '5',
          label: '3+1'
        }
      ],
      shortCutsPopVisi: false,
      propRule: {
        name: [{required: true, message: "请填写试卷名称", trigger: "blur"}, {
          max: 50,
          message: "不能超过50个字",
          trigger: "blur"
        }]
      },
      quesRule: {
        name: [{required: true, message: "请填写题组名称", trigger: "blur"}, {
          max: 50,
          message: "不能超过50个字",
          trigger: "blur"
        }],
        type: [{required: true, message: "请选择题组类型", trigger: "change"}],
        validScoreStr: [{
          pattern: /^\d+(.\d+)?(\s*,\s*\d+(\.\d+)?)*$/,
          message: "有效分数格式必须是：1,2.5,3",
          trigger: "blur"
        }]
      },
      subRule: {
        name: [{required: true, message: "请填写子题名称", trigger: "blur"}, {
          max: 50,
          message: "不能超过50个字",
          trigger: "blur"
        }],
        "content.full_score": [{required: true, message: "请填写满分", trigger: "blur"}]
      },
      objRule: {
        direction: [{required: true, message: "请选择方向类型", trigger: "change"}],
        startnum: [{required: true, message: "请选输入开始序号", trigger: "blur"}]
      }
    }
  },
  mounted() {
    this.examID = sessionStorage.getItem("exam-mge-exam-id")
    if (this.examID != null) {
      this.examID = Number(this.examID)
      this.search.examid = this.examID
    }
    this.cvs = document.createElement("canvas")
    this.ctx = this.cvs.getContext("2d")
    this.calcHeight()
    this.$nextTick(() => {
      window.removeEventListener("resize", this.calcHeight)
      window.addEventListener("resize", this.calcHeight)
    })

    this.loadExamInfo()
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcHeight)
  },
  methods: {
    //计算相关高度
    calcHeight() {
    },
    //载入考试科目信息
    loadExamInfo() {
      this.axios.post("/api/common/loadexaminfo", {examid: this.examID})
          .then((response) => {
            if (response.data.success) {
              this.exam = response.data.result
              if (this.exam.subjects.length > 0) {
                this.search.examid = this.examID
                this.search.subjectid = this.exam.subjects[0].subject_id
                this.loadSubjectPaper()
              }
            }
          })
    },
    //载入模板图片
    loadSubjectPaper() {
      this.paperLoaded = false
      this.axios.post("/api/exam/loadexamsubjectpaper", this.search)
          .then((response) => {
            if (response.data.success) {
              let papers = response.data.result
              papers.forEach(paper => {
                let img = new Image()
                img.src = paper.paper_image
                paper.paper_image = img
              })
              this.subjectPapers = papers
              this.paperLoaded = true
              this.loadSubjectProp()
              this.loadSubjectQuestion()
              this.waitImageLoaded()
            }
          })
    },
    //载入模板图片
    loadSubjectProp() {
      this.propLoaded = false
      this.axios.post("/api/exam/loadexamsubjectprop", this.search)
          .then((response) => {
            if (response.data.success) {
              let prop = response.data.result
              this.propData.examid = this.search.examid
              this.propData.subjectid = this.search.subjectid
              if (prop != null) {
                this.propData.name = prop.paper_name
                this.propData.maskarea = prop.mask_area == null ? [] : prop.mask_area
                this.propData.codearea = prop.code_area == null ? [] : prop.code_area
                this.propData.fillarea = prop.fill_area == null ? [] : prop.fill_area
              } else {
                this.propData.name = ""
                this.propData.maskarea = []
                this.propData.codearea = []
                this.propData.fillarea = []
              }
              this.propLoaded = true
            }
          })
    },
    //载入题组配置
    loadSubjectQuestion(redraw) {
      this.questionLoaded = false
      this.optMode = ""
      this.activeArea = null
      this.activeQuesIdx = null
      this.activeSubquesIdx = null
      this.activePointIdx = null
      this.axios.post("/api/exam/loadexamsubjectquestion", this.search)
          .then((response) => {
            if (response.data.success) {
              let qlist = response.data.result
              this.addQuestionUniID(qlist)
              this.questionList = qlist
              this.questionLoaded = true
              this.drawerCloseRedraw = true
              if (redraw === true)
                this.renderSubject()
            }
            if (this.subjectPapers.length > 0 && this.questionList.length > 0)
              this.activeTab = "questionTab"
            else if (this.activeTab === "questionTab")
              this.activeTab = "paperTab"
          })
    },
    //对题组和子题生成唯一ID
    addQuestionUniID(list) {
      let i = 1
      list.forEach(ques => {
        ques.uid = i
        i++
        if (typeof ques.sub_question !== "undefined") {
          ques.sub_question.forEach(sub => {
            sub.uid = i
            i++
          })
        }
      })
    },
    //选择科目重新加载
    selectSubject() {
      if (this.search.subjectid != null) {
        this.subjectPapers = []
        this.questionList = []
        this.propData = {
          examid: null,
          subjectid: null,
          name: "",
          maskarea: [],
          codearea: [],
          fillarea: []
        }
        this.optMode = ""
        this.activeArea = null
        this.validAreas = []
        this.$refs.paperCanvas.removeResizeBox()
        this.$refs.paperCanvas.cancelOnlyMove()
        this.quesDrawerVisi = false
        this.subDrawerVisi = false
        this.fitPaper = true
        this.loadSubjectPaper()
      }
    },
    //模板图片上传成功以后执行方法
    uploadResult(response) {
      if (response.success) {
        this.loadSubjectPaper()
      } else
        this.$message.error(response.result)
      this.$refs.paperUpload.clearFiles()
    },
    //模板图片上传出错以后执行方法
    uploadError(error) {
      this.$message.error(error.message)
    },
    //拖拽模板图片
    paperDrop(dragging, drop, type) {
      this.$confirm("调换模板图片顺序将清空题组设置，确认调换?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/exam/changesubjectpaper", {
          srcid: dragging.data.paper_id,
          tgtid: drop.data.paper_id,
          type: type
        }).then((response) => {
          if (response.data.success) {
            this.loadSubjectPaper()
          }

        }).catch(() => {
          this.loadSubjectPaper()
        })
      })
    },
    //模板图片删除
    toPaperDel(data) {
      this.$confirm("删除模板图片将清空题组设置，确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/exam/removesubjectpaper", {examid: data.exam_id, paperid: data.paper_id})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "模板图片删除成功", type: "success"})
                this.loadSubjectPaper()
              }
            })

      })
    },
    //拖拽题组
    questionDrop(dragging, drop, type) {
      if (typeof dragging.data.subquestion_name === "undefined") {
        this.axios.post("/api/exam/changesubjectquestion", {
          examid: this.search.examid,
          srcid: dragging.data.question_id,
          tgtid: drop.data.question_id,
          type: type
        }).then((response) => {
          if (!response.data.success) {
            this.loadSubjectQuestion(true)
          }
        }).catch(() => {
          this.loadSubjectQuestion(true)
        })
      } else {
        this.axios.post("/api/exam/changesubjectsubquestion", {
          examid: this.search.examid,
          srcid: dragging.data.subquestion_id,
          tgtid: drop.data.subquestion_id,
          type: type
        }).then((response) => {
          if (!response.data.success) {
            this.loadSubjectQuestion(true)
          }
        }).catch(() => {
          this.loadSubjectQuestion(true)
        })
      }
    },
    //是否允许题组拖拽
    questionAllowDrop(dragging, drop, type) {
      if (type === "inner")
        return false
      else
        return (typeof dragging.data.subquestion_id === "undefined" && typeof drop.data.subquestion_id === "undefined")
            || (typeof dragging.data.subquestion_id !== "undefined" && typeof drop.data.subquestion_id !== "undefined" && dragging.data.question_id === drop.data.question_id);
    },
    //是否允许区域拖拽
    areaAllowDrop(dragging, drop, type) {
      return type !== "inner"
    },
    areaDrop(dragging, drop, type) {
      if (type === "inner")
        return false
      else
        this.drawEditQuestion()
    },
    //题组删除
    toQuestionDel(data) {
      if (typeof data.subquestion_name === "undefined") { //删除题组
        this.$confirm("确认删除题组?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.axios.post("/api/exam/delsubjectquestion", {examid: data.exam_id, quesid: data.question_id})
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "题组删除成功", type: "success"})
                  this.loadSubjectQuestion(true)
                }
              })

        })
      } else {  //删除子题
        this.$confirm("确认删除子题?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.axios.post("/api/exam/delsubjectsubquestion", {examid: data.exam_id, subid: data.subquestion_id})
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "子题删除成功", type: "success"})
                  this.loadSubjectQuestion(true)
                }
              })

        })
      }
    },
    canvasToImage() {
      let img = new Image();
      img.src = this.cvs.toDataURL("image/png");

      return img;
    },
    //等待全部试卷图片加载完成
    waitImageLoaded() {
      let allLoaded = true
      this.subjectPapers.forEach(paper => {
        if (!paper.paper_image.complete)
          allLoaded = false
      })
      if (!allLoaded || !this.paperLoaded || !this.propLoaded || !this.questionLoaded)
        setTimeout(this.waitImageLoaded, 33)
      else {
        this.renderSubject()
        if (this.subjectPapers.length > 0 && this.questionList.length > 0)
          this.activeTab = "questionTab"
        else if (this.activeTab === "questionTab")
          this.activeTab = "paperTab"
      }
    },
    //绘制试卷配置
    drawProp(returnLayer) {
      const maskareas = this.propData.maskarea
      const codeareas = this.propData.codearea
      const layerName = "paper-prop"
      if ((maskareas == null || maskareas.length === 0) && (codeareas == null || codeareas.length === 0)) {
        if (returnLayer)
          return null
        else
          this.$refs.paperCanvas.removeLayerByName(layerName)
      }
      const layers = this.paperLayers
      const exist = layers.find(layer => {
        return layer.name === layerName
      })
      const ratio = this.$refs["paperCanvas"].showScale
      this.ctx.lineWidth = Math.ceil(2 / ratio)
      const fontSize = Math.ceil(16 / ratio)
      let sx = 0, sy = 0, ex = 0, ey = 0, w = 0, h = 0
      if (maskareas !== null && maskareas !== undefined) {
        maskareas.forEach(area => {
          const range = this.getPaperRange(area.p)
          if (sx === 0)
            sx = area.x
          else if (sx > area.x)
            sx = area.x
          if (sy === 0)
            sy = area.y + range.sh
          else if (sy > area.y + range.sh)
            sy = area.y + range.sh
          if (ex === 0)
            ex = area.x + area.w
          else if (ex < area.x + area.w)
            ex = area.x + area.w
          if (ey === 0)
            ey = area.y + range.sh + area.h
          else if (ey < area.y + range.sh + area.h)
            ey = area.y + range.sh + area.h
        })
      }
      codeareas.forEach(area => {
        const range = this.getPaperRange(area.p)
        if (sx === 0)
          sx = area.x
        else if (sx > area.x)
          sx = area.x
        if (sy === 0)
          sy = area.y + range.sh
        else if (sy > area.y + range.sh)
          sy = area.y + range.sh
        if (ex === 0)
          ex = area.x + area.w
        else if (ex < area.x + area.w)
          ex = area.x + area.w
        if (ey === 0)
          ey = area.y + range.sh + area.h
        else if (ey < area.y + range.sh + area.h)
          ey = area.y + range.sh + area.h
      })
      w = ex - sx
      h = ey - sy
      this.cvs.width = w
      this.cvs.height = h
      this.ctx.clearRect(0, 0, w, h)
      this.ctx.strokeStyle = this.maskColor
      this.ctx.font = fontSize + "px sans-serif"
      if (maskareas !== null && maskareas !== undefined) {
        maskareas.forEach((area, idx) => {
          const range = this.getPaperRange(area.p)
          const title = "遮盖区域" + (idx + 1)
          const tw = Math.ceil(this.ctx.measureText(title).width)
          this.ctx.fillStyle = this.maskColor
          this.ctx.fillRect(area.x - sx, area.y + range.sh - sy, tw + fontSize / 2, fontSize * 1.3)
          this.ctx.fillStyle = "#ffffff"
          this.ctx.fillText(title, area.x - sx + fontSize / 4, area.y + range.sh - sy + fontSize)
          this.ctx.strokeRect(area.x - sx + 1, area.y + range.sh - sy + 1, area.w - 2, area.h - 2)
        })
      }
      this.ctx.strokeStyle = this.codeColor
      codeareas.forEach((area, idx) => {
        const range = this.getPaperRange(area.p)
        const title = "条码区域" + (idx + 1)
        const tw = Math.ceil(this.ctx.measureText(title).width)
        this.ctx.fillStyle = this.codeColor
        this.ctx.fillRect(area.x - sx, area.y + range.sh - sy, tw + fontSize / 2, fontSize * 1.3)
        this.ctx.fillStyle = "#ffffff"
        this.ctx.fillText(title, area.x - sx + fontSize / 4, area.y + range.sh - sy + fontSize)
        this.ctx.strokeRect(area.x - sx + 1, area.y + range.sh - sy + 1, area.w - 2, area.h - 2)
      })
      if (returnLayer) {
        return {
          name: layerName,
          image: this.canvasToImage(),
          x: sx,
          y: sy,
          w: w,
          h: h
        }
      } else {
        if (typeof exist === "undefined") {
          if (layers[0].name === "bottom-paper") {
            const proplayer = {
              name: layerName,
              image: this.canvasToImage(),
              x: sx,
              y: sy,
              w: w,
              h: h
            }
            if (layers.length > 1)
              layers.splice(1, 0, proplayer)
            else
              layers.push(proplayer)
          }
        } else {
          exist.x = sx
          exist.y = sy
          exist.w = w
          exist.h = h
          exist.image = this.canvasToImage()
        }
        this.paperLayers = layers
        this.$refs.paperCanvas.isChanged = true
      }
    },
    //绘制题组框
    drawQuestion(ques) {
      const areas = ques.question_area
      if (areas == null || areas.length === 0) {
        return {
          name: "question-" + ques.question_id,
          image: null,
          x: 0,
          y: 0,
          w: 0,
          h: 0
        }
      }
      const ratio = this.$refs["paperCanvas"].showScale
      this.ctx.lineWidth = Math.ceil(2 / ratio)
      const fontSize = Math.ceil(16 / ratio)
      let sx = 0, sy = 0, ex = 0, ey = 0, w = 0, h = 0
      areas.forEach(area => {
        const range = this.getPaperRange(area.p)
        if (sx === 0)
          sx = area.x
        else if (sx > area.x)
          sx = area.x
        if (sy === 0)
          sy = area.y + range.sh
        else if (sy > area.y + range.sh)
          sy = area.y + range.sh
        if (ex === 0)
          ex = area.x + area.w
        else if (ex < area.x + area.w)
          ex = area.x + area.w
        if (ey === 0)
          ey = area.y + range.sh + area.h
        else if (ey < area.y + range.sh + area.h)
          ey = area.y + range.sh + area.h
      })
      w = ex - sx
      h = ey - sy
      this.cvs.width = w
      this.cvs.height = h
      this.ctx.clearRect(0, 0, w, h)
      this.ctx.strokeStyle = this.quesColor
      this.ctx.font = fontSize + "px sans-serif"
      areas.forEach((area, idx) => {
        const range = this.getPaperRange(area.p)
        const title = ques.question_name + "-区域" + (idx + 1)
        const tw = Math.ceil(this.ctx.measureText(title).width)
        this.ctx.fillStyle = this.quesColor
        this.ctx.fillRect(area.x - sx, area.y + range.sh - sy, tw + fontSize / 2, fontSize * 1.3)
        this.ctx.fillStyle = "#ffffff"
        this.ctx.fillText(title, area.x - sx + fontSize / 4, area.y + range.sh - sy + fontSize)
        this.ctx.strokeRect(area.x - sx + 1, area.y + range.sh - sy + 1, area.w - 2, area.h - 2)
      })
      if (typeof ques.sub_question !== "undefined") {
        ques.sub_question.forEach(sub => {
          const areas = sub.subquestion_area
          this.ctx.font = fontSize + "px sans-serif"
          if (ques.question_type === "1") {
            areas.sort((a, b) => {
              return a.x - b.x
            })
            const first = areas[0]
            const firstRange = this.getPaperRange(first.p)
            this.ctx.strokeStyle = this.optColor
            this.ctx.fillStyle = sub.content != null && sub.content.is_multi ? this.optMultiTxtColor : this.optTxtColor
            const txt = sub.subquestion_name
            const tw = Math.ceil(this.ctx.measureText(txt).width)
            this.ctx.fillText(txt, first.x - sx - tw - 10, first.y + firstRange.sh - sy + fontSize * 0.7)
            areas.forEach(area => {
              const range = this.getPaperRange(area.p)
              this.ctx.strokeRect(area.x - sx + 1, area.y + range.sh - sy + 1, area.w - 2, area.h - 2)
            })
          } else if (ques.question_type === "2") {
            areas.forEach((area, idx) => {
              const range = this.getPaperRange(area.p)
              const title = sub.subquestion_name + "-区域" + (idx + 1)
              const tw = Math.ceil(this.ctx.measureText(title).width)
              this.ctx.fillStyle = this.subquesColor
              this.ctx.fillRect(area.x - sx, area.y + range.sh - sy, tw + fontSize / 2, fontSize * 1.3)
              this.ctx.fillStyle = "#ffffff"
              this.ctx.fillText(title, area.x - sx + fontSize / 4, area.y + range.sh - sy + fontSize)
              this.ctx.strokeStyle = this.subquesColor
              this.ctx.strokeRect(area.x - sx + 1, area.y + range.sh - sy + 1, area.w - 2, area.h - 2)
            })
            if (sub.content.points.length > 0) {  //绘制打分点
              sub.content.points.forEach(p => {
                const range = this.getPaperRange(p.p)
                this.drawPoint(p.x - sx, p.y + range.sh - sy)
              })
            }
          }
        })
      }
      return {
        name: "question-" + ques.question_id,
        image: this.canvasToImage(),
        x: sx,
        y: sy,
        w: w,
        h: h
      }
    },
    //绘制编辑中题组框
    drawEditQuestion() {
      let qData, qareas, qname, qid
      if (this.activeSubquesIdx == null && !this.subDrawerVisi) {  //判断当前是编辑题组中
        qData = this.quesData
        qareas = qData.area
        qname = qData.name
        qid = qData.quesid
      } else {
        qData = this.questionList[this.activeQuesIdx]
        qareas = qData.question_area
        qname = qData.question_name
        qid = qData.question_id
      }
      const layerName = qid == null ? "question-new" : "question-" + qid
      const layers = this.paperLayers
      const exist = layers.find(layer => {
        return layer.name === layerName
      })
      if (qareas.length > 0) {
        const ratio = this.$refs["paperCanvas"].showScale
        this.ctx.lineWidth = Math.ceil(2 / ratio)
        const fontSize = Math.ceil(16 / ratio)
        let sx = 0, sy = 0, ex = 0, ey = 0, w = 0, h = 0
        qareas.forEach(area => {
          const range = this.getPaperRange(area.p)
          if (sx === 0)
            sx = area.x
          else if (sx > area.x)
            sx = area.x
          if (sy === 0)
            sy = area.y + range.sh
          else if (sy > area.y + range.sh)
            sy = area.y + range.sh
          if (ex === 0)
            ex = area.x + area.w
          else if (ex < area.x + area.w)
            ex = area.x + area.w
          if (ey === 0)
            ey = area.y + range.sh + area.h
          else if (ey < area.y + range.sh + area.h)
            ey = area.y + range.sh + area.h
        })
        w = ex - sx
        h = ey - sy
        this.cvs.width = w
        this.cvs.height = h
        this.ctx.clearRect(0, 0, w, h)
        this.ctx.strokeStyle = this.quesColor
        this.ctx.font = fontSize + "px sans-serif"
        qareas.forEach((area, idx) => {
          const range = this.getPaperRange(area.p)
          const title = qname + "-区域" + (idx + 1)
          const tw = Math.ceil(this.ctx.measureText(title).width)
          this.ctx.fillStyle = this.quesColor
          this.ctx.fillRect(area.x - sx, area.y + range.sh - sy, tw + fontSize / 2, fontSize * 1.3)
          this.ctx.fillStyle = "#ffffff"
          this.ctx.fillText(title, area.x - sx + fontSize / 4, area.y + range.sh - sy + fontSize)
          this.ctx.strokeRect(area.x - sx + 1, area.y + range.sh - sy + 1, area.w - 2, area.h - 2)
        })
        //绘制子题
        if (qid != null) {
          const ques = this.questionList.find(q => {
            return q.question_id === qid
          })
          if (typeof ques.sub_question !== "undefined") {
            ques.sub_question.forEach((sub, sidx) => {
              const sareas = this.activeSubquesIdx === sidx ? this.subData.area : sub.subquestion_area
              this.ctx.font = fontSize + "px sans-serif"
              if (ques.question_type === "1") {
                sareas.sort((a, b) => {
                  return a.x - b.x
                })
                const first = sareas[0]
                const firstRange = this.getPaperRange(first.p)
                this.ctx.strokeStyle = this.optColor
                this.ctx.fillStyle = sub.content != null && sub.content.is_multi ? this.optMultiTxtColor : this.optTxtColor
                const txt = sub.subquestion_name
                const tw = Math.ceil(this.ctx.measureText(txt).width)
                this.ctx.fillText(txt, first.x - sx - tw - 10, first.y + firstRange.sh - sy + fontSize * 0.7)
                sareas.forEach(area => {
                  const range = this.getPaperRange(area.p)
                  this.ctx.strokeRect(area.x - sx + 1, area.y + range.sh - sy + 1, area.w - 2, area.h - 2)
                })
              } else if (ques.question_type === "2") {
                sareas.forEach((area, idx) => {
                  const range = this.getPaperRange(area.p)
                  const title = sub.subquestion_name + "-区域" + (idx + 1)
                  const tw = Math.ceil(this.ctx.measureText(title).width)
                  this.ctx.fillStyle = this.subquesColor
                  this.ctx.fillRect(area.x - sx, area.y + range.sh - sy, tw + fontSize / 2, fontSize * 1.3)
                  this.ctx.fillStyle = "#ffffff"
                  this.ctx.fillText(title, area.x - sx + fontSize / 4, area.y + range.sh - sy + fontSize)
                  this.ctx.strokeStyle = this.subquesColor
                  this.ctx.strokeRect(area.x - sx + 1, area.y + range.sh - sy + 1, area.w - 2, area.h - 2)
                })
                if (sub.content.points.length > 0) {  //绘制打分点
                  sub.content.points.forEach(p => {
                    const range = this.getPaperRange(p.p)
                    this.drawPoint(p.x - sx, p.y + range.sh - sy)
                  })
                }
              }
            })
          }
          if (this.subTitle === "新增子题") {
            const sareas = this.subData.area
            sareas.forEach((area, idx) => {
              const range = this.getPaperRange(area.p)
              const title = this.subData.name + "-区域" + (idx + 1)
              const tw = Math.ceil(this.ctx.measureText(title).width)
              this.ctx.fillStyle = this.subquesColor
              this.ctx.fillRect(area.x - sx, area.y + range.sh - sy, tw + fontSize / 2, fontSize * 1.3)
              this.ctx.fillStyle = "#ffffff"
              this.ctx.fillText(title, area.x - sx + fontSize / 4, area.y + range.sh - sy + fontSize)
              this.ctx.strokeRect(area.x - sx + 1, area.y + range.sh - sy + 1, area.w - 2, area.h - 2)
            })
            if (ques.question_type === "2" && this.subData.content.points.length > 0) {  //绘制打分点
              this.subData.content.points.forEach(p => {
                const range = this.getPaperRange(p.p)
                this.drawPoint(p.x - sx, p.y + range.sh - sy)
              })
            }
          }
        }
        if (typeof exist === "undefined") {
          const rlidx = this.$refs.paperCanvas.getResizeLayerIndex()
          layers.splice(rlidx, 0, {
            name: layerName,
            image: this.canvasToImage(),
            x: sx,
            y: sy,
            w: w,
            h: h
          })
        } else {
          exist.x = sx
          exist.y = sy
          exist.w = w
          exist.h = h
          exist.image = this.canvasToImage()
        }
      } else if (typeof exist !== "undefined") {
        const idx = layers.indexOf(exist)
        layers.splice(idx, 1)
      }
      this.paperLayers = layers
      this.$refs.paperCanvas.isChanged = true
    },
    //绘制打分点
    drawPoint(x, y) {
      const half = 10 / this.$refs["paperCanvas"].showScale //打分点半径，以10像素为基准
      this.ctx.beginPath()
      this.ctx.arc(x, y, half, 0, 2 * Math.PI)
      this.ctx.fillStyle = "#ffb061"
      this.ctx.fill()
      this.ctx.strokeStyle = "#ec9433"
      this.ctx.stroke()
      this.ctx.closePath()
      this.ctx.font = (half + 2) + "px sans-serif"
      this.ctx.fillStyle = "#ffffff"
      this.ctx.fillText("M", x - half / 2, y + half / 2)
    },
    //画板绘制完成后事件
    afterCanvasRender() {
      if (this.fitPaper && this.$refs.paperCanvas.layerData.length > 0) {
        this.$refs.paperCanvas.zoomFitWidth()
        this.fitPaper = false
      }
    },
    //绘制试卷图片和题组框
    renderSubject() {
      let layers = []
      if (this.subjectPapers.length > 0) {
        let tw = 0, th = 0;
        this.subjectPapers.forEach(paper => {
          if (paper.paper_image.width > tw)
            tw = paper.paper_image.width
          th += paper.paper_image.height
        })
        this.cvs.width = tw
        this.cvs.height = th
        let ch = 0
        this.subjectPapers.forEach(paper => {
          this.ctx.drawImage(paper.paper_image, 0, 0, paper.paper_image.width, paper.paper_image.height,
              0, ch, paper.paper_image.width, paper.paper_image.height)
          ch += paper.paper_image.height
        })
        layers.push({
          name: "bottom-paper",
          image: this.canvasToImage(),
          x: 0,
          y: 0,
          w: tw,
          h: th,
          selectable: false
        })
        const proplayer = this.drawProp(true)
        if (proplayer != null)
          layers.push(proplayer)
        this.questionList.forEach(ques => {
          layers.push(this.drawQuestion(ques))
        })
      }
      if (this.activeArea != null) {
        const sl = this.$refs.paperCanvas.getResizeLayer()
        layers.push(sl)
      }
      this.paperLayers = layers
    },
    //获取鼠标位置所在试卷图片序号
    getPosPaperIndex(mpos) {
      let index = -1
      let curHeight = 0
      this.subjectPapers.forEach((paper, idx) => {
        if (mpos.x >= 0 && mpos.x < paper.paper_image.width && mpos.y >= curHeight && mpos.y < curHeight + paper.paper_image.height)
          index = idx
        curHeight += paper.paper_image.height
      })
      console.log("当前鼠标所在试卷图片序号" + index)
      return index
    },
    //获取指定序号图片宽高度范围
    getPaperRange(pidx) {
      let startHeight = 0
      let endHeight = 0
      let width = 0
      this.subjectPapers.forEach((paper, idx) => {
        if (pidx === idx) {
          endHeight = startHeight + paper.paper_image.height
          width = paper.paper_image.width
        }
        if (pidx > idx)
          startHeight += paper.paper_image.height
      })
      return {sh: startHeight, eh: endHeight, w: width}
    },

    //生成激活区域
    getActiveArea(area) {
      const range = this.getPaperRange(area.p)
      if (typeof area.w === "undefined") {
        const half = 10 / this.$refs["paperCanvas"].showScale //打分点半径，以10像素为基准
        return {x: area.x - half, y: area.y + range.sh - half, w: 2 * half, h: 2 * half}
      } else {
        return {x: area.x, y: area.y + range.sh, w: area.w, h: area.h}
      }
    },
    //获取有效区域列表
    getValidAreas(areas) {
      const varea = []
      areas.forEach(a => {
        const range = this.getPaperRange(a.p)
        varea.push({x: a.x, y: a.y + range.sh, w: a.w, h: a.h})
      })
      return varea
    },
    //处理鼠标离开
    canvasMouseLeave(ev) {
      if (this.optMode !== "" && this.$refs.paperCanvas.isMouseDown)
        this.canvasMouseUp(ev)
    },
    //处理鼠标点下
    canvasMouseDown(ev) {
      if (ev.button === 0) {
        //获取鼠标在画布中的位置
        const mpos = this.$refs.paperCanvas.getClientPos(ev)
        console.log("当前 mpos ：" + JSON.stringify(mpos))
        console.log("当前 optMode：" + this.optMode)
        if (this.optMode === "") {
          this.downMousePos = mpos
          this.lastMousePos = this.downMousePos
        } else
          this.downPos = {x: mpos.x, y: mpos.y, pidx: this.getPosPaperIndex(mpos)}
      }
    },
    //处理鼠标在画布上移动
    canvasMouseMove(ev) {
      if (ev.button === 0 && this.downMousePos != null) {
        const mpos = this.$refs.paperCanvas.getClientPos(ev)
        if ((this.optMode === "" || this.optMode === "drag-scroll") && Math.abs(mpos.x - this.downMousePos.x) > 2 || Math.abs(mpos.y - this.downMousePos.y)) {
          this.optMode = "drag-scroll"
          let left = mpos.x - this.lastMousePos.x
          let top = mpos.y - this.lastMousePos.y
          if (Math.abs(left) > 2 || Math.abs(top) > 2) {
            if (left > 2)
              left += 10
            else if (left < -2)
              left -= 10
            if (top > 2)
              top += 10
            else if (top < -2)
              top -= 10
            this.lastMousePos = mpos
            this.$refs.paperCanvas.scrollBy(left, top)
          }
        }
      }
    },
    //处理鼠标按键抬起事件
    canvasMouseUp(ev) {
      if (ev.button === 0) {
        //获取鼠标位置
        const mpos = this.$refs.paperCanvas.getClientPos(ev)
        //判断位置是否在有效区域内
        const isValid = this.$refs.paperCanvas.isInValidArea(mpos);
        if (isValid) {
          if (this.optMode !== "") {
            //获取缩放图层
            const lc = this.$refs.paperCanvas.getResizeLayer()
            //获取指定序号图片宽高度范围
            const range = this.getPaperRange(this.downPos.pidx)
            if (typeof lc !== "undefined") {
              if (this.optMode === "mask-area-add") {
                const ay = lc.y < range.sh ? range.sh : lc.y
                const aw = lc.x + lc.w > range.w ? range.w - lc.x : lc.w
                const ah = ay + lc.h > range.eh ? range.eh - ay : lc.h
                this.propData.maskarea.push({x: lc.x, y: ay - range.sh, w: aw, h: ah, p: this.downPos.pidx})
                this.activeMaskIdx = this.propData.maskarea.length - 1
                this.activeArea = this.getActiveArea(this.propData.maskarea[this.activeMaskIdx])
                this.$refs.paperCanvas.setResizeBox(this.activeArea.x, this.activeArea.y, this.activeArea.w, this.activeArea.h)
                this.drawProp()
                this.optMode = "mask-area-edit"
                this.canvasDragable = false
              } else if (this.optMode === "code-area-add") {
                const ay = lc.y < range.sh ? range.sh : lc.y
                const aw = lc.x + lc.w > range.w ? range.w - lc.x : lc.w
                const ah = ay + lc.h > range.eh ? range.eh - ay : lc.h
                this.propData.codearea.push({x: lc.x, y: ay - range.sh, w: aw, h: ah, p: this.downPos.pidx})
                this.activeCodeIdx = this.propData.codearea.length - 1
                this.activeArea = this.getActiveArea(this.propData.codearea[this.activeCodeIdx])
                this.$refs.paperCanvas.setResizeBox(this.activeArea.x, this.activeArea.y, this.activeArea.w, this.activeArea.h)
                this.drawProp()
                this.optMode = "code-area-edit"
                this.canvasDragable = false
              } else if (this.optMode === "ques-area-add") {
                const ay = lc.y < range.sh ? range.sh : lc.y
                const aw = lc.x + lc.w > range.w ? range.w - lc.x : lc.w
                const ah = ay + lc.h > range.eh ? range.eh - ay : lc.h
                this.quesData.area.push({x: lc.x, y: ay - range.sh, w: aw, h: ah, p: this.downPos.pidx})
                this.activeQuesIdx = this.quesData.area.length - 1
                this.activeArea = this.getActiveArea(this.quesData.area[this.quesData.area.length - 1])
                this.$refs.paperCanvas.setResizeBox(this.activeArea.x, this.activeArea.y, this.activeArea.w, this.activeArea.h)
                this.drawEditQuestion()
                this.optMode = "ques-area-edit"
                this.canvasDragable = false
              } else if (this.optMode === "sub-area-add") {
                const ay = lc.y < range.sh ? range.sh : lc.y
                const aw = lc.x + lc.w > range.w ? range.w - lc.x : lc.w
                const ah = ay + lc.h > range.eh ? range.eh - ay : lc.h
                this.subData.area.push({x: lc.x, y: ay - range.sh, w: aw, h: ah, p: this.downPos.pidx})
                this.activeSubquesIdx = this.subData.area.length - 1
                this.activeArea = this.getActiveArea(this.subData.area[this.subData.area.length - 1])
                this.$refs.paperCanvas.setResizeBox(this.activeArea.x, this.activeArea.y, this.activeArea.w, this.activeArea.h)
                this.drawEditQuestion()
                this.optMode = "sub-area-edit"
                this.canvasDragable = false
              } else if (this.optMode === "ques-area-edit" || this.optMode === "sub-area-edit" || this.optMode === "mask-area-edit" || this.optMode === "code-area-edit") {
                const dpos = this.$refs.paperCanvas.mDownPos
                if (this.activeArea != null && dpos.x >= this.activeArea.x && dpos.x < this.activeArea.x + this.activeArea.w && dpos.y >= this.activeArea.y && dpos.y < this.activeArea.y + this.activeArea.h) {
                  this.activeArea.x = lc.x
                  if (lc.y < range.sh)
                    this.activeArea.y = range.sh
                  else if (lc.y > range.eh)
                    this.activeArea.y = range.eh - this.$refs.paperCanvas.dragNodeSize * 4
                  else
                    this.activeArea.y = lc.y
                  this.activeArea.w = lc.x + lc.w > range.w ? range.w - lc.x : lc.w
                  this.activeArea.h = this.activeArea.y + lc.h > range.eh ? range.eh - this.activeArea.y : lc.h
                  this.$refs.paperCanvas.setResizeBox(this.activeArea.x, this.activeArea.y, this.activeArea.w, this.activeArea.h)
                  console.log("this.optMode" + this.optMode)
                  if (this.optMode === "ques-area-edit") {
                    console.log(JSON.stringify(this.quesData.area[this.activeQuesIdx]))

                    this.quesData.area[this.activeQuesIdx].x = this.activeArea.x
                    this.quesData.area[this.activeQuesIdx].y = this.activeArea.y - range.sh
                    this.quesData.area[this.activeQuesIdx].w = this.activeArea.w
                    this.quesData.area[this.activeQuesIdx].h = this.activeArea.h
                    this.drawEditQuestion()
                  } else if (this.optMode === "sub-area-edit") {
                    this.subData.area[this.activeQuesIdx].x = this.activeArea.x
                    this.subData.area[this.activeQuesIdx].y = this.activeArea.y - range.sh
                    this.subData.area[this.activeQuesIdx].w = this.activeArea.w
                    this.subData.area[this.activeQuesIdx].h = this.activeArea.h
                    this.drawEditQuestion()
                  } else if (this.optMode === "mask-area-edit") {
                    this.propData.maskarea[this.activeMaskIdx].x = this.activeArea.x
                    this.propData.maskarea[this.activeMaskIdx].y = this.activeArea.y - range.sh
                    this.propData.maskarea[this.activeMaskIdx].w = this.activeArea.w
                    this.propData.maskarea[this.activeMaskIdx].h = this.activeArea.h
                    this.drawProp()
                  } else if (this.optMode === "code-area-edit") {
                    this.propData.codearea[this.activeCodeIdx].x = this.activeArea.x
                    this.propData.codearea[this.activeCodeIdx].y = this.activeArea.y - range.sh
                    this.propData.codearea[this.activeCodeIdx].w = this.activeArea.w
                    this.propData.codearea[this.activeCodeIdx].h = this.activeArea.h
                    this.drawProp()
                  }
                } else {
                  this.switchAreaSelect(mpos)
                }
              } else if (this.optMode === "sub-point-edit") {
                const dpos = this.$refs.paperCanvas.mDownPos
                if (this.activeArea != null && dpos.x >= this.activeArea.x && dpos.x < this.activeArea.x + this.activeArea.w && dpos.y >= this.activeArea.y && dpos.y < this.activeArea.y + this.activeArea.h) {
                  this.activeArea.x = lc.x
                  this.activeArea.y = lc.y
                  this.activeArea.w = lc.w
                  this.activeArea.h = lc.h
                  const point = this.subData.content.points[this.activePointIdx]
                  point.x = parseInt(lc.x + lc.w / 2)
                  point.y = parseInt(lc.y + lc.h / 2)
                  point.p = this.getPosPaperIndex(point)
                  point.y = point.y - this.getPaperRange(point.p).sh
                  this.drawEditQuestion()
                } else {
                  this.switchAreaSelect(mpos)
                }
              }
            } else if (this.optMode === "sub-point-add") {
              const pidx = this.getPosPaperIndex(mpos)
              const prange = this.getPaperRange(pidx)
              this.subData.content.points.push({x: parseInt(mpos.x), y: parseInt(mpos.y) - prange.sh, p: pidx})
              const half = 10 / this.$refs["paperCanvas"].showScale //打分点半径，以10像素为基准
              this.activeArea = {x: mpos.x - half, y: mpos.y + prange.sh - half, w: 2 * half, h: 2 * half}
              this.validAreas = this.getValidAreas(this.subData.area)
              this.drawEditQuestion()
              this.optMode = "sub-point-edit"
              this.canvasDragable = false
              this.$refs.paperCanvas.setOnlyMove()
            } else if (this.optMode === "drag-scroll") {
              this.optMode = ""
            } else {
              this.switchAreaSelect(mpos)
            }
          } else {
            this.switchAreaSelect(mpos)
          }
        }
        this.downMousePos = null
      } else if (ev.button === 2) {
        if (this.optMode !== "") {
          if (this.optMode === "ques-area-add" || this.optMode === "sub-area-add" || this.optMode === "sub-point-add" || this.optMode === "mask-area-add" || this.optMode === "code-area-add") {
            this.canvasDragable = false
            this.optMode = ""
          }
          if (this.optMode === "sub-point-edit") {
            this.activePointIdx = null
          } else if (this.optMode === "sub-area-edit") {
            this.activePointIdx = null
          } else if (this.optMode === "ques-area-edit") {
            this.activeSubquesIdx = null
            this.activePointIdx = null
          } else if (this.optMode === "mask-area-edit") {
            this.activeMaskIdx = null
          } else if (this.optMode === "code-area-edit") {
            this.activeCodeIdx = null
          }
          this.optMode = ""
          this.activeArea = null
          this.validAreas = []
          this.$refs.paperCanvas.removeResizeBox()
          this.$refs.paperCanvas.cancelOnlyMove()
        } else if (this.quesDrawerVisi || this.subDrawerVisi) {
          this.quesDrawerVisi = false
          this.subDrawerVisi = false
        }
      }
    },
    //切换区域选中
    switchAreaSelect(mpos) {
      let maskIdx = -1
      let codeIdx = -1
      let quesIdx = -1
      let quesAreaIdx = -1
      const oldQuesIdx = this.activeQuesIdx
      const oldSubIdx = this.activeSubquesIdx
      this.$refs.paperCanvas.cancelOnlyMove()
      this.propData.maskarea.forEach((area, idx) => {
        const range = this.getPaperRange(area.p)
        if (maskIdx === -1 && mpos.x >= area.x && mpos.x <= area.x + area.w && mpos.y >= area.y + range.sh && mpos.y <= area.y + range.sh + area.h)
          maskIdx = idx
      })
      if (maskIdx !== -1) {
        if (this.quesDrawerVisi)
          this.quesDrawerVisi = false
        if (this.subDrawerVisi)
          this.subDrawerVisi = false
        if (this.activeTab !== "propTab")
          this.activeTab = "propTab"
        this.optMode = "mask-area-edit"
        this.validAreas = []
        this.activeMaskIdx = maskIdx
        this.activeCodeIdx = null
        this.activeArea = this.getActiveArea(this.propData.maskarea[maskIdx])
        this.$refs.paperCanvas.setResizeBox(this.activeArea.x, this.activeArea.y, this.activeArea.w, this.activeArea.h)
      }
      this.propData.codearea.forEach((area, idx) => {
        const range = this.getPaperRange(area.p)
        if (codeIdx === -1 && mpos.x >= area.x && mpos.x <= area.x + area.w && mpos.y >= area.y + range.sh && mpos.y <= area.y + range.sh + area.h)
          codeIdx = idx
      })
      if (codeIdx !== -1) {
        if (this.quesDrawerVisi)
          this.quesDrawerVisi = false
        if (this.subDrawerVisi)
          this.subDrawerVisi = false
        if (this.activeTab !== "propTab")
          this.activeTab = "propTab"
        this.optMode = "code-area-edit"
        this.validAreas = []
        this.activeMaskIdx = null
        this.activeCodeIdx = codeIdx
        this.activeArea = this.getActiveArea(this.propData.maskarea[maskIdx])
        this.$refs.paperCanvas.setResizeBox(this.activeArea.x, this.activeArea.y, this.activeArea.w, this.activeArea.h)
      }
      if (maskIdx === -1 && codeIdx === -1) {
        this.questionList.forEach((ques, qidx) => { //查找点击所在题组区域
          const qarea = oldQuesIdx === qidx && this.activeSubquesIdx == null ? this.quesData.area : ques.question_area
          if (quesIdx === -1 && qarea.length > 0) {
            qarea.forEach((area, idx) => {
              const range = this.getPaperRange(area.p)
              if (quesIdx === -1 && mpos.x >= area.x && mpos.x <= area.x + area.w && mpos.y >= area.y + range.sh && mpos.y <= area.y + range.sh + area.h)
                quesAreaIdx = idx
            })
            if (quesIdx === -1 && quesAreaIdx !== -1)
              quesIdx = qidx
          }
        })
        if (quesIdx !== -1) { //点中某个题组区域
          if (this.activeTab !== "questionTab")
            this.activeTab = "questionTab"
          const oldLayerName = oldQuesIdx != null ? "question-" + this.questionList[oldQuesIdx].question_id : "question-new"
          const oldLayer = this.paperLayers.find(layer => {
            return layer.name === oldLayerName
          })
          const oldLayerIdx = typeof oldLayer === "undefined" ? -1 : this.paperLayers.indexOf(oldLayer)
          if (oldQuesIdx !== quesIdx && oldLayerIdx !== -1) //还原之前缩放过未保存的题组
            this.paperLayers[oldLayerIdx] = this.drawQuestion(this.questionList[oldQuesIdx])
          this.activeQuesIdx = quesIdx
          const pointQues = this.questionList[quesIdx]
          if (typeof pointQues.sub_question !== "undefined" && pointQues.sub_question.length > 0) {
            let subIdx = -1
            let subAreaIdx = -1
            pointQues.sub_question.forEach((sub, sidx) => { //查找点击所在子题区域
              const sarea = this.activeSubquesIdx === sidx ? this.subData.area : sub.subquestion_area
              if (subIdx === -1 && sarea.length > 0) {
                sarea.forEach((area, idx) => {
                  const range = this.getPaperRange(area.p)
                  if (subIdx === -1 && mpos.x >= area.x && mpos.x <= area.x + area.w && mpos.y >= area.y + range.sh && mpos.y <= area.y + range.sh + area.h)
                    subAreaIdx = idx
                })
                if (subIdx === -1 && subAreaIdx !== -1)
                  subIdx = sidx
              }
            })
            if (subIdx !== -1) { //点中某个子题区域
              if (oldSubIdx !== subIdx && oldLayerIdx !== -1) {
                this.paperLayers[oldLayerIdx] = this.drawQuestion(this.questionList[oldQuesIdx])
              }
              this.activeSubquesIdx = subIdx
              if (oldQuesIdx != null && oldSubIdx == null) {
                this.drawerCloseClear = false
              }
              this.toQuestionEdit(this.questionList[quesIdx].sub_question[subIdx])
              this.activeArea = this.getActiveArea(this.subData.area[subAreaIdx])

              let thirdIdx = -1
              const pointSub = pointQues.sub_question[subIdx]
              if (pointQues.question_type === "2" && pointSub.content.points.length > 0) {  //查找点击所在打分点区域
                let ppoint = null
                const half = 10 / this.$refs["paperCanvas"].showScale //打分点半径，以10像素为基准
                const ps = this.subData.content.points
                ps.forEach((point, pidx) => {
                  if (thirdIdx === -1) {
                    if (mpos.x > point.x - half && mpos.x < point.x + half && mpos.y > point.y - half && mpos.y < point.y + half) {
                      thirdIdx = pidx
                      ppoint = point
                    }
                  }
                })
                if (thirdIdx !== -1) {  //点中打分点
                  this.activePointIdx = thirdIdx
                  this.activeArea = this.getActiveArea(ppoint)
                  this.validAreas = this.getValidAreas(pointSub.subquestion_area)
                  this.optMode = "sub-point-edit"
                  this.drawerCloseClear = this.activeQuesIdx === oldQuesIdx
                  this.$refs.paperCanvas.setOnlyMove()
                } else
                  this.activePointIdx = null
              }
              if (thirdIdx === -1) {  //未点中打分点，判断为选中子题
                this.validAreas = this.getValidAreas(this.questionList[this.activeQuesIdx].question_area)
                this.optMode = "sub-area-edit"
              }
            } else if (this.subTitle === "新增子题") { //判断点击位置是否在新增子题区域
              const sarea = this.subData.area
              sarea.forEach((area, idx) => {
                const range = this.getPaperRange(area.p)
                if (mpos.x >= area.x && mpos.x <= area.x + area.w && mpos.y >= area.y + range.sh && mpos.y <= area.y + range.sh + area.h)
                  subAreaIdx = idx
              })
              if (subAreaIdx !== -1) { //点中新增子题区域设置缩放框
                this.optMode = "sub-area-edit"
                this.activePointIdx = null
                this.activeArea = this.getActiveArea(sarea[quesAreaIdx])
                this.validAreas = this.getValidAreas(this.questionList[this.activeQuesIdx].question_area)
              } else {  //未点中新增子题区域，则判断为选中题组
                if (oldQuesIdx !== quesIdx || oldSubIdx !== subIdx) {
                  this.activeSubquesIdx = null
                  this.activePointIdx = null
                  this.drawerCloseClear = false
                  this.toQuestionEdit(this.questionList[quesIdx])
                  this.optMode = "ques-area-edit"
                }
                this.validAreas = []
                this.activeArea = this.getActiveArea(this.quesData.area[quesAreaIdx])
              }
            } else {  //未点中子题，则判断为选中题组
              if (oldQuesIdx !== quesIdx || oldSubIdx !== subIdx) {
                this.activeSubquesIdx = null
                this.activePointIdx = null
                if (oldSubIdx != null) {
                  this.drawerCloseClear = false
                }
                this.toQuestionEdit(this.questionList[quesIdx])
                this.optMode = "ques-area-edit"
              }
              this.validAreas = []
              this.activeArea = this.getActiveArea(this.quesData.area[quesAreaIdx])
            }
            this.$refs.paperCanvas.setResizeBox(this.activeArea.x, this.activeArea.y, this.activeArea.w, this.activeArea.h)
          } else {  //未点中子题，则判断为选中题组
            if (oldQuesIdx !== quesIdx) {
              this.activeSubquesIdx = null
              this.activePointIdx = null
              this.drawerCloseClear = false
              this.toQuestionEdit(this.questionList[quesIdx])
              this.optMode = "ques-area-edit"
            }
            this.validAreas = []
            this.activeArea = this.getActiveArea(this.quesData.area[quesAreaIdx])
            this.$refs.paperCanvas.setResizeBox(this.activeArea.x, this.activeArea.y, this.activeArea.w, this.activeArea.h)
          }
        } else if (this.quesTitle === "新增题组") { //判断点击位置是否在新增题组区域
          const qarea = this.quesData.area
          qarea.forEach((area, idx) => {
            const range = this.getPaperRange(area.p)
            if (mpos.x >= area.x && mpos.x <= area.x + area.w && mpos.y >= area.y + range.sh && mpos.y <= area.y + range.sh + area.h)
              quesAreaIdx = idx
          })
          if (quesAreaIdx !== -1) { //点中新增题组区域设置缩放框
            this.optMode = "ques-area-edit"
            this.activeSubquesIdx = null
            this.activePointIdx = null
            this.activeArea = this.getActiveArea(qarea[quesAreaIdx])
            this.$refs.paperCanvas.setResizeBox(this.activeArea.x, this.activeArea.y, this.activeArea.w, this.activeArea.h)
          } else {  //未点中新增题组区域
            this.optMode = ""
            this.activeMaskIdx = null
            this.activeCodeIdx = null
            this.activeQuesIdx = null
            this.activeSubquesIdx = null
            this.activePointIdx = null
            this.activeArea = null
            this.validAreas = []
            this.$refs.paperCanvas.removeResizeBox()
          }
        } else {  //未点中任何题组区域
          this.optMode = ""
          this.activeMaskIdx = null
          this.activeCodeIdx = null
          this.activeQuesIdx = null
          this.activeSubquesIdx = null
          this.activePointIdx = null
          this.activeArea = null
          this.validAreas = []
          this.$refs.paperCanvas.removeResizeBox()
        }
      } else {  //未点中任何区域
        this.optMode = ""
        this.activeMaskIdx = null
        this.activeCodeIdx = null
        this.activeQuesIdx = null
        this.activeSubquesIdx = null
        this.activePointIdx = null
        this.activeArea = null
        this.validAreas = []
        this.$refs.paperCanvas.removeResizeBox()
      }
    },
    //新增信息遮盖区域
    toMaskAreaAdd() {
      if (this.optMode === "mask-area-add") {
        this.canvasDragable = false
        this.optMode = ""
      } else {
        if (this.quesDrawerVisi || this.subDrawerVisi) {
          this.quesDrawerVisi = false
          this.subDrawerVisi = false
        }
        this.$refs.paperCanvas.removeResizeBox()
        this.$refs.paperCanvas.cancelOnlyMove()
        this.canvasDragable = true
        this.optMode = "mask-area-add"
      }
    },
    //信息遮盖区域列表中选中区域触发缩放编辑
    selectMaskArea(area) {
      if (this.quesDrawerVisi || this.subDrawerVisi) {
        this.quesDrawerVisi = false
        this.subDrawerVisi = false
      }
      this.activeArea = this.getActiveArea(area)
      this.validAreas = []
      this.activeMaskIdx = this.propData.maskarea.indexOf(area)
      this.optMode = "mask-area-edit"
      this.$refs.paperCanvas.cancelOnlyMove()
      this.$refs.paperCanvas.setResizeBox(this.activeArea.x, this.activeArea.y, this.activeArea.w, this.activeArea.h)
      this.$refs.paperCanvas.scrollToResize()
    },
    //移除信息遮盖区域
    removeMaskArea(data) {
      const range = this.getPaperRange(data.p)
      if (this.activeArea != null && this.activeArea.x === data.x && this.activeArea.y === data.y + range.sh && this.activeArea.w === data.w && this.activeArea.h === data.h) {
        this.$refs.paperCanvas.removeResizeBox()
        this.activeArea = null
      }
      const idx = this.propData.maskarea.indexOf(data)
      this.propData.maskarea.splice(idx, 1)
      this.drawProp()
    },
    //新增条码识别区域
    toCodeAreaAdd() {
      if (this.optMode === "code-area-add") {
        this.canvasDragable = false
        this.optMode = ""
      } else {
        if (this.quesDrawerVisi || this.subDrawerVisi) {
          this.quesDrawerVisi = false
          this.subDrawerVisi = false
        }
        this.$refs.paperCanvas.removeResizeBox()
        this.$refs.paperCanvas.cancelOnlyMove()
        this.canvasDragable = true
        this.optMode = "code-area-add"
      }
    },
    //条码区域列表中选中区域触发缩放编辑
    selectCodeArea(area) {
      if (this.quesDrawerVisi || this.subDrawerVisi) {
        this.quesDrawerVisi = false
        this.subDrawerVisi = false
      }
      this.activeArea = this.getActiveArea(area)
      this.validAreas = []
      this.activeCodeIdx = this.propData.codearea.indexOf(area)
      this.optMode = "code-area-edit"
      this.$refs.paperCanvas.cancelOnlyMove()
      this.$refs.paperCanvas.setResizeBox(this.activeArea.x, this.activeArea.y, this.activeArea.w, this.activeArea.h)
      this.$refs.paperCanvas.scrollToResize()
    },
    //移除条码识别区域
    removeCodeArea(data) {
      const range = this.getPaperRange(data.p)
      if (this.activeArea != null && this.activeArea.x === data.x && this.activeArea.y === data.y + range.sh && this.activeArea.w === data.w && this.activeArea.h === data.h) {
        this.$refs.paperCanvas.removeResizeBox()
        this.activeArea = null
      }
      const idx = this.propData.codearea.indexOf(data)
      this.propData.codearea.splice(idx, 1)
      this.drawProp()
    },
    //保存试卷配置
    toPropSave() {
      this.$refs.subForm.validate(valid => {
        if (valid) {
          this.axios.post("/api/exam/savesubjectprop", this.propData)
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "试卷配置保存成功", type: "success"})
                }
              })

        } else
          return valid
      })
    },
    //新增题组
    toQuestionAdd() {
      this.quesData = {
        quesid: null,
        examid: this.search.examid,
        subjectid: this.search.subjectid,
        name: "",
        type: "",
        area: [],
        validScoreStr: "",
        content: {
          full_score: null,
          score_interval: 1,
          valid_scores: [],
          shortcuts: [],
          mark_mode: "",
          limit_time: 0,
          score_diff: null,
          remark_flag: false,
          remark_interval: null,
          choose_flag: false,
          coverage: 70,
          depth: 70
        }
      }
      this.quesTitle = "新增题组"
      this.quesDrawerVisi = true
    },
    //编辑题组
    toQuestionEdit(data) {
      if (typeof data.subquestion_name === "undefined") { //编辑题组
        const quesData = this.quesData
        quesData.quesid = data.question_id
        quesData.examid = data.exam_id
        quesData.subjectid = data.subject_id
        quesData.name = data.question_name
        quesData.type = data.question_type
        const areastr = JSON.stringify(data.question_area)
        quesData.area = JSON.parse(areastr)
        quesData.content.mark_mode = data.content.mark_mode
        quesData.content.full_score = data.content.full_score
        quesData.content.score_interval = data.content.score_interval
        quesData.content.limit_time = data.content.limit_time
        if (typeof data.content.valid_scores !== "undefined") {
          let str = JSON.stringify(data.content.valid_scores)
          this.quesData.validScoreStr = str.substring(1, str.length - 1)
        }
        if (typeof data.content.shortcuts !== "undefined")
          quesData.content.shortcuts = data.content.shortcuts
        if (typeof data.content.score_diff !== "undefined")
          quesData.content.score_diff = data.content.score_diff
        if (typeof data.content.remark_flag !== "undefined")
          quesData.content.remark_flag = data.content.remark_flag
        if (typeof data.content.remark_interval !== "undefined")
          quesData.content.remark_interval = data.content.remark_interval
        if (typeof data.content.choose_flag !== "undefined")
          quesData.content.choose_flag = data.content.choose_flag
        if (typeof data.content.coverage !== "undefined")
          quesData.content.coverage = data.content.coverage
        if (typeof data.content.depth !== "undefined")
          quesData.content.depth = data.content.depth
        if (this.$refs["quesForm"])
          this.$refs["quesForm"].clearValidate()
        this.activeQuesIdx = this.questionList.indexOf(data)
        this.subDrawerVisi = false
        this.quesDrawerVisi = true
      } else {  //编辑子题
        const subData = this.subData
        subData.subid = data.subquestion_id
        subData.quesid = data.question_id
        subData.examid = data.exam_id
        subData.subjectid = data.subject_id
        subData.num = data.subquestion_num
        subData.name = data.subquestion_name
        const areastr = JSON.stringify(data.subquestion_area)
        subData.area = JSON.parse(areastr)
        if (data.content != null) {
          subData.content.full_score = data.content.full_score
          if (typeof data.content.points !== "undefined")
            subData.content.points = data.content.points
          if (typeof data.content.is_multi !== "undefined")
            subData.content.is_multi = data.content.is_multi
        } else {
          subData.content.full_score = null
          subData.content.points = []
          subData.content.is_multi = false
        }
        if (this.$refs["subForm"])
          this.$refs["subForm"].clearValidate()
        const parent = this.$refs["quesTree"].getNode(data).parent.data
        subData.type = parent.question_type
        this.activeQuesIdx = this.questionList.indexOf(parent)
        this.activeSubquesIdx = parent.sub_question.indexOf(data)
        this.quesDrawerVisi = false
        this.subDrawerVisi = true
      }
    },
    //新增题组区域
    toQuestionAreaAdd() {
      if (this.optMode === "ques-area-add") {
        this.canvasDragable = false
        this.optMode = ""
      } else {
        this.$refs.paperCanvas.removeResizeBox()
        this.$refs.paperCanvas.cancelOnlyMove()
        this.canvasDragable = true
        this.optMode = "ques-area-add"
      }
    },
    //列表中选中区域触发缩放编辑
    selectArea(area) {
      if (this.subDrawerVisi) {
        if (typeof area.w === "undefined") {
          this.activePointIdx = this.subData.content.points.indexOf(area)
          this.activeArea = this.getActiveArea(area)
          this.validAreas = this.getValidAreas(this.subData.area)
          this.optMode = "sub-point-edit"
          this.drawerCloseClear = true
          this.$refs.paperCanvas.setOnlyMove()
        } else {
          this.activeArea = this.getActiveArea(area)
          this.activeSubquesIdx = this.subData.area.indexOf(area)
          const ques = this.questionList[this.activeQuesIdx]
          this.validAreas = this.getValidAreas(ques.question_area)
          this.optMode = "sub-area-edit"
          this.drawerCloseClear = true
          this.$refs.paperCanvas.cancelOnlyMove()
        }
      } else {
        this.activeArea = this.getActiveArea(area)
        this.validAreas = []
        this.optMode = "ques-area-edit"
        this.drawerCloseClear = true
        this.$refs.paperCanvas.cancelOnlyMove()
      }
      this.$refs.paperCanvas.setResizeBox(this.activeArea.x, this.activeArea.y, this.activeArea.w, this.activeArea.h)
      this.$refs.paperCanvas.scrollToResize()
    },
    //添加新快捷键
    addShortcut() {
      const ct = this.quesData.content
      if (typeof ct.shortcuts === "undefined")
        ct.shortcuts = []
      ct.shortcuts.push({key: "", score: ""})
    },
    //移除快捷键
    removeShortcut(idx) {
      const sc = this.quesData.content.shortcuts
      sc.splice(idx, 1)
    },
    //移除题组指定区域
    removeQuestionArea(data) {
      const range = this.getPaperRange(data.p)
      if (this.activeArea != null && this.activeArea.x === data.x && this.activeArea.y === data.y + range.sh && this.activeArea.w === data.w && this.activeArea.h === data.h) {
        this.$refs.paperCanvas.removeResizeBox()
        this.activeArea = null
      }
      const idx = this.quesData.area.indexOf(data)
      this.quesData.area.splice(idx, 1)
      this.drawEditQuestion()
    },
    //保存题组
    toQuestionSave() {
      this.$refs.quesForm.validate(valid => {
        if (valid) {
          this.axios.post("/api/exam/savesubjectquestion", this.quesData)
              .then((response) => {
                if (response.data.success) {
                  this.drawerCloseRedraw = false
                  this.$refs['quesDrawer'].handleClose()
                  this.loadSubjectQuestion(true)
                }
              })

        } else
          return valid
      })
    },
    //新增子题
    toSubquestionAdd(parent) {
      this.subData = {
        subid: null,
        quesid: parent.question_id,
        examid: parent.exam_id,
        subjectid: parent.subject_id,
        type: parent.question_type,
        num: null,
        name: "",
        area: [],
        content: {
          full_score: null,
          points: [],
          is_multi: false
        }
      }
      this.activeQuesIdx = this.questionList.indexOf(parent)
      this.subTitle = "新增子题"
      this.subDrawerVisi = true
    },
    //保存子题
    toSubquestionSave() {
      this.$refs.subForm.validate(valid => {
        if (valid) {
          this.axios.post("/api/exam/savesubjectsubquestion", this.subData)
              .then((response) => {
                if (response.data.success) {
                  this.drawerCloseRedraw = false
                  this.$refs['subDrawer'].handleClose()
                  this.loadSubjectQuestion(true)
                }
              })

        } else
          return valid
      })
    },
    //新增子题区域
    toSubquestionAreaAdd() {
      if (this.optMode === "sub-area-add") {
        this.validAreas = []
        this.canvasDragable = false
        this.optMode = ""
      } else {
        this.$refs.paperCanvas.removeResizeBox()
        this.validAreas = this.getValidAreas(this.questionList[this.activeQuesIdx].question_area)
        this.$refs.paperCanvas.cancelOnlyMove()
        this.canvasDragable = true
        this.optMode = "sub-area-add"
      }
    },
    //移除子题指定区域
    removeSubquestionArea(data) {
      const range = this.getPaperRange(data.p)
      if (this.activeArea != null && this.activeArea.x === data.x && this.activeArea.y === data.y + range.sh && this.activeArea.w === data.w && this.activeArea.h === data.h) {
        this.$refs.paperCanvas.removeResizeBox()
        this.activeArea = null
      }
      const idx = this.subData.area.indexOf(data)
      this.subData.area.splice(idx, 1)
      this.drawEditQuestion()
    },
    //新增子题打分点
    toSubquestionPointAdd() {
      if (this.optMode === "sub-point-add") {
        this.validAreas = []
        this.optMode = ""
      } else {
        this.$refs.paperCanvas.removeResizeBox()
        this.validAreas = this.getValidAreas(this.subData.area)
        this.optMode = "sub-point-add"
      }
    },
    //移除子图指定打分点
    removeSubquestionPoint(data) {
      const idx = this.subData.content.points.indexOf(data)
      this.subData.content.points.splice(idx, 1)
      this.drawEditQuestion()
    },
    //关闭右边抽屉
    drawerClose() {
      this.quesTitle = "题组配置"
      this.subTitle = "子题配置"
      this.canvasDragable = false
      if (this.drawerCloseClear) {
        this.optMode = ""
        this.activeArea = null
        this.activeQuesIdx = null
        this.activeSubquesIdx = null
        this.activePointIdx = null
        this.validAreas = []
        this.$refs.paperCanvas.cancelOnlyMove()
      } else {
        this.drawerCloseClear = true
      }
      if (this.drawerCloseRedraw) {
        this.renderSubject()
      } else {
        this.drawerCloseRedraw = true
      }
    },
    //客观题画布鼠标抬起事件
    objectiveMouseUp(ev) {
      if (ev.button === 0) {
        const mpos = cvs.getPointer(ev.e)
        const aidx = this.findPosAreaIndex(mpos)
        if (this.optObjectiveMode === "col-remove") {
          this.objectiveOptionList = this.objectiveOptionList.filter(opt => {
            const idx = this.findPosAreaIndex(opt)
            return opt.x > mpos.x || opt.x + opt.w < mpos.x || aidx !== idx
          })
          this.renderObjectiveOption()
        } else if (this.optObjectiveMode === "row-remove") {
          this.objectiveOptionList = this.objectiveOptionList.filter(opt => {
            const idx = this.findPosAreaIndex(opt)
            return opt.y > mpos.y || opt.y + opt.h < mpos.y || aidx !== idx
          })
          this.renderObjectiveOption()
        } else if (this.optObjectiveMode === "first-opt-switch") {
          const opt = this.findPosOption(mpos)
          if (opt != null) {
            const first = opt.f
            if (typeof first === "undefined")
              opt["f"] = true
            else
              delete opt.f
            this.objectiveOptionList.forEach(option => {
              const idx = this.findPosAreaIndex(option)
              if (mpos.x >= option.x && mpos.x <= option.x + option.w && opt.y + opt.h < option.y && aidx === idx) {
                if (typeof option.f === "undefined") {
                  if (typeof first === "undefined")
                    option["f"] = true
                } else if (typeof first !== "undefined") {
                  delete option.f
                }
              }
            })
            this.renderObjectiveOption()
          }
        } else if (this.optObjectiveMode === "multi-opt-switch") {
          const opt = this.findPosOption(mpos)
          if (opt != null) {
            if (typeof opt.f !== "undefined") {
              const ismulti = opt.m
              if (typeof ismulti === "undefined")
                opt["m"] = true
              else
                delete opt.m
              this.renderObjectiveOption()
            } else {
              this.$message.warning("请点击首选项")
            }
          }
        }
      } else if (ev.button === 2) {
        if (this.optMode !== "") {
          this.optMode = ""
        }
      }
    },
    //查找指定位置在客观题区域列表中的索引
    findPosAreaIndex(mpos) {
      let areaIdx = -1
      this.objectiveAreaList.forEach((area, idx) => {
        if (mpos.y >= area.y && mpos.y < area.y + area.h)
          areaIdx = idx
      })
      return areaIdx
    },
    //查找选中客观题选项
    findPosOption(mpos) {
      let find = null
      this.objectiveOptionList.forEach(option => {
        if (mpos.x >= option.x && mpos.x <= option.x + option.w && mpos.y >= option.y && mpos.y <= option.y + option.h)
          find = option
      })
      return find
    },
    //激活批量添加答题卡客观子题
    toSubquestionBatchAdd() {
      if (this.quesData.area.length === 0) {
        this.$message.warning("请先添加题组区域")
      } else {
        this.subDialogVisi = true
        this.subDialogData = {
          direction: "2",
          startnum: 1
        }
        //拼接题组区域图片
        let tw = 0, th = 0;
        this.quesData.area.forEach(area => {
          if (area.w > tw)
            tw = area.w
          th += area.h
        })
        this.cvs.width = tw
        this.cvs.height = th
        let ch = 0
        const arealist = []
        this.quesData.area.forEach(area => {
          const paper = this.subjectPapers[area.p]
          this.ctx.drawImage(paper.paper_image, area.x, area.y, area.w, area.h,
              0, ch, area.w, area.h)
          this.ctx.lineWidth = 2
          this.ctx.strokeStyle = "#8222c6"
          this.ctx.moveTo(0, ch + 1)
          this.ctx.lineTo(tw, ch + 1)
          this.ctx.stroke()
          arealist.push({x: 0, y: ch, w: area.w, h: area.h})
          ch += area.h
        })
        this.objectiveAreaList = arealist
        let layers = []
        layers.push({
          name: "objective-paper",
          image: this.canvasToImage(),
          x: 0,
          y: 0,
          w: tw,
          h: th,
          selectable: false
        })
        this.objectiveLayers = layers
        this.refindOptions()
      }
    },
    //客观题图片放大
    objectiveZoomIn() {
      this.$refs.objectiveCanvas.zoomIn()
    },
    //客观题图片缩小
    objectiveZoomOut() {
      this.$refs.objectiveCanvas.zoomOut()
    },
    //获取题组区域内选项列表
    refindOptions() {
      this.axios.post("/api/exam/getoptionpos", {
        examid: this.quesData.examid,
        subjectid: this.quesData.subjectid,
        areas: this.quesData.area
      })
          .then((response) => {
            if (response.data.success) {
              const poslist = response.data.result
              const optlist = []
              let curheight = 0
              this.quesData.area.forEach((area, aidx) => {
                const areapos = poslist[aidx]
                if (areapos != null) {
                  areapos.forEach(pos => {
                    const opt = {
                      x: pos.x,
                      y: pos.y + curheight,
                      w: pos.w,
                      h: pos.h,
                      ox: pos.x + area.x,
                      oy: pos.y + area.y,
                      p: area.p
                    }
                    optlist.push(opt)
                  })
                }
                curheight += area.h
              })
              this.objectiveOptionList = optlist
              this.renderObjectiveOption()
            }
          })
    },
    //绘制客观题选项
    renderObjectiveOption() {
      const backlayer = this.objectiveLayers[0]
      this.cvs.width = backlayer.w
      this.cvs.height = backlayer.h
      this.ctx.clearRect(0, 0, backlayer.w, backlayer.h)
      const layers = [backlayer]
      const ratio = this.$refs.objectiveCanvas.showScale
      this.ctx.lineWidth = Math.ceil(2 / ratio)
      const fontSize = 16
      this.ctx.font = fontSize + "px sans-serif"
      this.ctx.fillStyle = this.optTxtColor
      this.objectiveOptionList.forEach(opt => {
        this.ctx.strokeStyle = typeof opt.f !== "undefined" ? this.firstOptColor : this.optColor
        this.ctx.strokeRect(opt.x + 1, opt.y + 1, opt.w - 2, opt.h - 2)
        if (typeof opt.m !== "undefined") {
          this.ctx.strokeStyle = this.multiOptColor
          this.ctx.strokeRect(opt.x - 3, opt.y - 3, opt.w + 5, opt.h + 5)
        }
        if (typeof opt.sq !== "undefined") {
          const txt = opt.sq.toString()
          const tw = Math.ceil(this.ctx.measureText(txt).width)
          this.ctx.fillText(txt, opt.x - tw - 10, opt.y + fontSize * 0.8)
        }
      })
      const exist = this.objectiveLayers.find(layer => {
        return layer.name === "options"
      })
      if (typeof exist !== "undefined") {
        exist.image = this.canvasToImage()
        this.$refs.objectiveCanvas.isChanged = true
      } else {
        layers.push({
          name: "options",
          image: this.canvasToImage(),
          x: 0,
          y: 0,
          w: backlayer.w,
          h: backlayer.h
        })
        this.objectiveLayers = layers
      }
    },
    //切换到移除选项列模式
    toOptionColRemove() {
      if (this.optObjectiveMode !== "col-remove")
        this.optObjectiveMode = "col-remove"
      else
        this.optObjectiveMode = ""
    },
    //切换到移除选项行模式
    toOptionRowRemove() {
      if (this.optObjectiveMode !== "row-remove")
        this.optObjectiveMode = "row-remove"
      else
        this.optObjectiveMode = ""
    },
    //切换到设置首选项模式
    toFirstOptSwitch() {
      if (this.optObjectiveMode !== "first-opt-switch")
        this.optObjectiveMode = "first-opt-switch"
      else
        this.optObjectiveMode = ""
    },
    //切换到设置多选题模式
    toSetMultiOpt() {
      if (this.optObjectiveMode !== "multi-opt-switch")
        this.optObjectiveMode = "multi-opt-switch"
      else
        this.optObjectiveMode = ""
    },
    //生成子题号
    generateSubNum() {
      let isFirstSet = false
      const firstList = []
      this.objectiveOptionList.forEach(option => {
        if (typeof option.f !== "undefined") {
          isFirstSet = true
          firstList.push(option)
        }
        if (typeof option.sq !== "undefined") {
          delete option.sq
        }
      })
      if (isFirstSet) {
        //首选项排序
        if (this.subDialogData.direction === "1") { //横向
          firstList.sort((a, b) => {
            const aidx = this.findPosAreaIndex(a)
            const bidx = this.findPosAreaIndex(b)
            if (aidx === bidx) {
              if (a.y === b.y)
                return a.x - b.x
              else
                return a.y - b.y
            } else
              return aidx - bidx
          })
        } else {
          firstList.sort((a, b) => {  //纵向
            const aidx = this.findPosAreaIndex(a)
            const bidx = this.findPosAreaIndex(b)
            if (aidx === bidx) {
              if (a.x === b.x)
                return a.y - b.y
              else
                return a.x - b.x
            } else
              return aidx - bidx
          })
        }
        let n = this.subDialogData.startnum
        firstList.forEach(opt => {
          opt.sq = n
          n++
        })
        this.renderObjectiveOption()
      } else
        this.$message.warning("请先设置首选项")
    },
    //关闭添加子题对话框
    closeBatchOptionDialog() {
      this.subDialogVisi = false
    },
    //批量保存客观子题
    batchAddSubquestions() {
      this.$refs.subForm.validate(valid => {
        if (valid) {
          const params = {
            examid: this.quesData.examid,
            subjectid: this.quesData.subjectid,
            quesid: this.quesData.quesid
          }
          this.objectiveOptionList.sort((a, b) => {
            if (a.y === b.y)
              return a.x - b.x
            else
              return a.y - b.y
          })
          let options
          const subquesList = []
          this.objectiveOptionList.forEach(opt => {
            if (typeof opt.f !== "undefined") {
              const subques = {sq: opt.sq, areas: []}
              options = subques.areas
              subques.content = {full_score: 0, is_multi: typeof opt.m !== "undefined"}
              subquesList.push(subques)
            }
            if (options instanceof  Array)
            options.push({x: opt.ox, y: opt.oy, w: opt.w, h: opt.h, p: opt.p})
          })
          params.subques = subquesList
          this.axios.post("/api/exam/batchaddsubquestion", params)
              .then((response) => {
                if (response.data.success) {
                  this.subDialogVisi = false
                  this.loadSubjectQuestion(true)
                }
              })
        } else
          return valid
      })
    }
  }
}
</script>

<style>
.side-drawer {
  position: absolute;
  height: calc(100% - 5px);
}

.exam-title {
  font-size: 20px;
  color: #284f95;
}

.exam-subject {
  width: 300px;
  text-align: right;
}

.paper-row {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.area-select {
  color: #2e62cd;
}
</style>
