
<!--
经销商及学校单位管理
最后编辑人：郑志强
最后编辑时间：2024-8-15
-->
<template>
  <el-container class="base-container">
    <el-main class="main-container">
      <div class="row">
        <el-button type="primary" size="default" @click="toAdd" icon="Plus">新单位</el-button>
        <el-select v-model="search.unit_type" placeholder="请选择单位类型" style="width: 200px; min-width: 180px;"
                   clearable>
          <el-option
              v-for="item in unitTypes"
              :key="item.code"
              :label="item.code_name"
              :value="item.code"
          ></el-option>
        </el-select>
        <el-input
            size="default"
            placeholder="请输入查询单位名称"
            v-model="search.unit_name"
            @keyup.enter="toSearch"
            style="width: 200px; min-width: 80px;"
        >
        </el-input>
        <el-button slot="append" type="primary" icon="Search" @click="toSearch">查询</el-button>

      </div>
      <el-table :data="tableData" stripe>
        <el-table-column prop="unit_id" label="单位ID" min-width="100"></el-table-column>
        <el-table-column prop="unit_code" label="单位编码" min-width="100"></el-table-column>
        <el-table-column prop="unit_name" label="单位名称" min-width="160"></el-table-column>
        <el-table-column prop="short_code" label="简写代码" min-width="60"></el-table-column>
        <el-table-column prop="unit_type_name" label="单位类型" min-width="100"></el-table-column>
        <el-table-column prop="state" label="状态" min-width="100">
          <template v-slot="scope">
            <el-tag v-if="scope.row.state === '1'" type="success">启用</el-tag>
            <el-tag v-else type="danger">禁用</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" min-width="160">
        </el-table-column>
        <el-table-column prop="last_edit_time" label="最后编辑时间" min-width="160">
        </el-table-column>
        <el-table-column prop="punit_name" label="所属经销商" min-width="160"></el-table-column>
        <el-table-column label="操作" fixed="right" width="170">
          <template v-slot="scope">
            <el-tooltip content="切换状态" placement="top" effect="light" transition="" :enterable="false">
              <el-switch
                  v-model="scope.row.state"
                  active-color="#13ce66"
                  active-value="1"
                  inactive-value="0"
                  style="margin-right: 10px;"
                  @change="toSwitchState(scope.row)"
              ></el-switch>
            </el-tooltip>
            <el-button class="table-button" @click="toEdit(scope.row)">编辑</el-button>
            <el-button class="table-button" @click="toDel(scope.row)" type="danger">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @current-change="toPage"
          :current-page="search.page"
          :page-size="search.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="total"
      ></el-pagination>
      <el-dialog :title="formTitle" v-model="dialogVisible" width="50%">
        <el-form :model="UNITDATA" label-width="120px" :rules="rules" ref="unitForm">
          <el-form-item label="单位编码" prop="unit_code">
            <el-input v-model="UNITDATA.unit_code"></el-input>
          </el-form-item>
          <el-form-item label="单位名称" prop="unit_name">
            <el-input v-model="UNITDATA.unit_name"></el-input>
          </el-form-item>
          <el-form-item label="简写代码" prop="short_code">
            <el-input v-model="UNITDATA.short_code"></el-input>
          </el-form-item>
          <el-form-item label="单位类型" prop="unit_type">
            <el-select v-model="UNITDATA.unit_type" placeholder="请选择单位类型">
              <el-option
                  v-for="item in unitTypes"
                  :key="item.code"
                  :label="item.code_name"
                  :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态" prop="state">
            <el-select v-model="UNITDATA.state" placeholder="请选择状态">
              <el-option
                  v-for="item in stateOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属经销商" prop="punit_id" v-show="UNITDATA.unit_type==='4'">
            <el-select v-model="UNITDATA.punit_id" placeholder="请选择所属经销商">
              <el-option
                  v-for="item in dlearsList"
                  :key="item.unit_id"
                  :label="item.unit_name"
                  :value="item.unit_id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="toSave">保存</el-button>
        </div>
      </el-dialog>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: "ManageUnit",
  data() {
    return {
      tableData: [],
      total: 0,
      search: {
        page: 1,
        pagesize: 15,
        unit_type: '',
        unit_name: ''
      },
      formTitle: "",
      isEdit: false,
      UNITDATA: {
        unit_id: null,
        unit_code: '',
        unit_name: '',
        short_code: '',
        unit_type: '',
        state: '',
        create_time: '',
        last_edit_time: '',
        punit_id: null,
        punit_name: ''
      },
      dialogVisible: false,
      unitTypes: [],
      dlearsList: [],
      stateOptions: [
        {label: '启用', value: '1'},
        {label: '禁用', value: '0'}
      ],
      rules: {
        unit_name: [{required: true, message: '请填写单位名称', trigger: 'blur'}],
        unit_code: [{required: true, message: '请填写单位编码', trigger: 'blur'}],
        short_code: [{required: true, message: '请填写简写代码', trigger: 'blur'}],
        unit_type: [{required: true, message: '请选择单位类型', trigger: 'change'}],
        state: [{required: true, message: '请选择状态', trigger: 'change'}]
      }
    };
  },
  mounted() {
    this.loadData()
    this.listDlears()
    this.loadCodeByType()
  },
  methods: {
    loadData() {
      // 根据你的实际需求加载数据
      this.axios.post("/api/newunit/listUnits", this.search)
          .then((response) => {
            if (response.data.success) {
              this.total = response.data.result.totalrecords;
              this.tableData = response.data.result.datalist;
            }
          })
    },
    listDlears() {
      // 根据你的实际需求加载数据
      this.axios.post("/api/newunit/listDlears", this.search)
          .then((response) => {
            if (response.data.success) {
              this.dlearsList = response.data.result
            }
          })
    },
    loadCodeByType() {
      // 根据你的实际需求加载数据
      this.axios.post("/api/common/loadcodebytypes", {types: ['unit_type']})
          .then((response) => {
            if (response.data.success) {
              this.unitTypes = response.data.result.unit_type
            }
          })
    },
    toPage(page) {
      this.search.page = page;
      this.loadData()
    },
    toSearch() {
      this.search.page = 1;
      this.loadData()
    },
    toAdd() {
      this.isEdit = false
      this.formTitle = "新增单位信息"
      for (const key in this.UNITDATA) {
        this.UNITDATA[key] = ''
      }
      this.dialogVisible = true
    },
    toEdit(row) {
      this.formTitle = "编辑单位信息"
      this.isEdit = true
      this.UNITDATA = Object.assign({}, row);
      this.dialogVisible = true
    },
    // 编辑操作
    toDel(row) {
      this.UNITDATA = Object.assign({}, row);
      this.axios.post("/api/newunit/delUnit", this.UNITDATA)
          .then((response) => {
            if (response.data.success) {
              this.loadData()
              this.listDlears()
            } else {
              this.$message.error(response.data.result)
            }
          })
    },
    toSwitchState(row) {
      this.UNITDATA = Object.assign({}, row);
      this.axios.post("/api/newunit/switchUnitState", this.UNITDATA)
          .then((response) => {
            if (response.data.success) {
              this.loadData()
              this.listDlears()
              this.$message.success(response.data.result)

            } else {
              this.$message.error(response.data.result)
            }
          })
    },
    toSave() {
      this.$refs.unitForm.validate((valid) => {
        if (valid) {
          this.axios.post("/api/newunit/saveUnit", this.UNITDATA)
              .then((response) => {
                if (response.data.success) {
                  this.loadData()
                  this.listDlears()
                  this.dialogVisible = false;
                } else {
                  this.$message.error(response.data.result)
                }
              })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
