import { defineStore } from 'pinia'
import { store } from '../index'
import axios from "axios";


export const useCodeStore = defineStore('code', {
    state: () => {
        return {
            codes: {},
        }
    },
    getters: {},
    actions: {
        async getCode (type_code) {
            let code = this.codes[type_code]
            if (code){
                return code
            }else {
                const response = await axios.post("/api/common/loadcodebytype", {type: type_code})
                if (response.data.success) {
                    this.codes[type_code] = response.data.result
                    return response.data.result
                }
            }
        },
    },
    persist: false
})

export const useCodeStoreWithOut = () => {
    return useCodeStore(store)
}
