<!--
考试管理界面
最后编辑人：宁茂
最后编辑时间：2022-5-25
最后编辑细节：增加评卷教师管理跳转
-->
<template>
  <el-container class="base-container">
    <el-main class="main-container">
      <div class="row">

        <el-input siz="default" placeholder="考试名称" v-model="search.examname" @keyup.enter="toSearch" style="width: 200px; min-width: 80px;">
        </el-input>
        <el-select style="width: 100px" v-model="search.examstate" clearable placeholder="状态" >
          <el-option
              v-for="item in stateCodes"
              :key="item.code"
              :label="item.code_name"
              :value="item.code">
          </el-option>
        </el-select>
        <el-date-picker siz="default" type="date" placeholder="选择日期" v-model="search.stime"></el-date-picker>
        <span style="color: #797979;">-</span>
        <el-date-picker siz="default" type="date" placeholder="选择日期" v-model="search.etime"></el-date-picker>
        <el-button bg text @click="toSearch" icon="Search">查询</el-button>
        <el-button bg text type="primary" siz="default" @click="showAdd" icon="Plus">考试</el-button>
      </div>
      <div class="exam-panel">
        <el-card v-for="(exam,index) in tableData"
                 :key="index"
                 style="width: 100%;border: none;"
                 shadow="never"
        >
          <template #header>
            <div style="display: flex">
              <div style="flex-grow: 1;font-weight: bold">
                <el-tag>{{exam.exam_state_name}}</el-tag>
                <el-tag v-if="exam.exam_type == 2" type="warning">联考</el-tag>
                <el-tag v-else type="success">学校考试</el-tag>
                {{exam.exam_name}}
                <span style="font-weight: normal;font-size: .9rem;">{{exam.create_time}}</span>
              </div>
              <div style="width: 700px;text-align: right" >
                <el-button type="primary"
                           text
                           bg
                           @click="menuClick(exam,null,'',{permission_url:'/exam/mgeexamstudent'})"
                           icon="user"
                           size="small"
                           v-show="userStore.user.type != 4"
                >
                   参考学生
                </el-button>
                <el-button type="primary"
                           text
                           bg
                           icon="Coordinate"
                           size="small"
                           v-show="userStore.user.type != 4"
                           @click="menuClick(exam,null,'',{permission_url:'/exam/exampermission'})"
                >
                  考试分工
                </el-button>
                <el-button bg
                           text
                           size="small"
                           type="primary"
                           v-show="userStore.user.type != 4"
                           @click="menuClick(exam,null,'',{permission_url:'/exam/examroommge'})">
                  <template #icon>
                    <i-door-handle theme="multi-color" size="24" :fill="['#409eff' ,'#a0cfff' ,'#086bfc' ,'#c6e2ff']"/>
                  </template>
                  考场管理
                </el-button>
                <el-button bg
                           text
                           size="small"
                           type="primary"
                           v-show="userStore.user.type != 4"
                           @click="generateStatistics(exam)">
                  <template #icon>
                    <i-analysis theme="multi-color" size="24" :fill="['#409eff' ,'#a0cfff' ,'#086bfc' ,'#c6e2ff']"/>
                  </template>
                  生成分析
                </el-button>

                <el-button type="warning"
                           size="small"
                           @click="toBack(exam)"
                           v-if="exam.exam_state !== '1'"
                           icon="caret-left"
                           bg
                           text
                           v-show="userStore.user.type != 4"
                >
                  上一状态
                </el-button>
                <el-button @click="toNext(exam)"
                           size="small"
                           v-if="exam.exam_state !== '3'"
                           icon="caret-right"
                           bg
                           text
                           v-show="userStore.user.type != 4"
                >
                  下一状态
                </el-button>
                <el-button size="small"
                           @click="showEdit(exam)"
                           v-if="exam.exam_state === '1'"
                           icon="edit"
                           bg
                           text
                           v-show="userStore.user.type != 4"
                ></el-button>
                <el-button size="small"
                           @click="toDel(exam)"
                           type="danger"
                           v-if="exam.exam_state === '1'"
                           icon="delete"
                           bg
                           text
                           v-show="userStore.user.type != 4"
                ></el-button>
                <el-tooltip>
                  <template #content>
                    分析与校核
                  </template>
                  <el-button size="small"
                             @click="menuClick(exam,null,'',{permission_url:'/exam/viewDataAnalyseAndCheck'})"
                             type="danger"
                             bg
                             text
                             v-show="userStore.user.type != 4"
                  >
                    <template #icon><i-analysis theme="outline" size="24" fill="#7c00f1"/></template>
                  </el-button>
                </el-tooltip>
              </div>
            </div>
            <el-text style="margin-right: 10px">{{ exam.unit_names.toString() }}</el-text>
            <el-text type="success">{{exam.grades.toString()}}</el-text>
          </template>
          <template #default>
            <div class="task">
<!--              <div class="tags">-->
<!--                <span class="tag">Draggable</span>-->
<!--                <span class="options">123</span>-->
<!--              </div>-->
              <el-descriptions :column="1" >
                <el-descriptions-item v-for="(subject,index) in exam.exam_subjects_name" :key="index" >
                  <template #default>
                    <el-space size="large">
                     <span style="width: 250px">
                       <span style="width: 80px;display: inline-block">
                         <el-text type="primary" size="large" >
                          {{subject}}
                        </el-text>
                       </span>
                        <el-text type="success" size="large">
                          {{examSubjectCountMap[exam.exam_id+'-'+exam.exam_subjects[index]]?.marked ? examSubjectCountMap[exam.exam_id+'-'+exam.exam_subjects[index]]?.marked : 0}}
                          <el-text type="info" size="small">(阅)</el-text>
                        </el-text>
                        <el-text type="danger" size="large">
                          <el-text type="info" size="small">/</el-text>
                          {{examSubjectCountMap[exam.exam_id+'-'+exam.exam_subjects[index]]?.scan ? examSubjectCountMap[exam.exam_id+'-'+exam.exam_subjects[index]]?.scan : 0}}
                          <el-text type="info" size="small">(扫)</el-text>
                        </el-text>
                        <el-text type="info" size="small">
                          <el-text type="info" size="small">/</el-text>
                          {{examSubjectCountMap[exam.exam_id+'-'+exam.exam_subjects[index]]?.total ? examSubjectCountMap[exam.exam_id+'-'+exam.exam_subjects[index]]?.total : 0}}
                          <el-text type="info" size="small">(总)</el-text>
                        </el-text>

                     </span>
                      <el-space>
                      <el-link
                          v-if="exam.exam_state !== '3' && userStore.user.type != 4"
                          @click="menuClick(exam,exam.exam_subjects[index],subject,{permission_url:'/exam/newmgeexamsubjectpapers'})"
                      >试卷模板配置</el-link>

                      <el-link
                          @click="menuClick(exam,exam.exam_subjects[index],subject,{permission_url:'/exam/subquestionanswer'})"
                          v-if="exam.exam_state !== '3' && userStore.user.type != 4"
                      >客观题答案设置</el-link>

                      <i-right-c theme="outline" size="16" fill="#333" v-if="exam.exam_state !== '3'"/>

                        <el-link
                                 v-check="'/scannerApi/login'"
                                 v-show="normalUserScanSubjectIds[exam.exam_id]?.includes(exam.exam_subjects[index])"
                                 @click="connectScanner(exam,exam.exam_subjects[index])"
                        >
                          开始扫描
                        </el-link>
                        <el-link
                                 v-show="['1','2','3','6'].includes(userStore.user.type)"
                                 @click="connectScanner(exam,exam.exam_subjects[index])"
                        >
                          开始扫描
                        </el-link>
                      <el-link
                          @click="menuClick(exam,exam.exam_subjects[index],subject,{permission_url:'/exam/examupload'})"
                          v-if="userStore.user.type != 4"
                      >手动上传试卷</el-link>

                      <el-link
                          @click="menuClick(exam,exam.exam_subjects[index],subject,{permission_url:'/exam/absentstudentmge'})"
                          v-if="userStore.user.type != 4"
                      >学生参考情况校核</el-link>
                      </el-space>

                      <el-dropdown trigger="click" size="small" v-if="(userStore.user.type == 4 ? (normalUserMenu['阅卷数据管理'] && normalUserMenu['阅卷数据管理'][exam.exam_id] && normalUserMenu['阅卷数据管理'][exam.exam_id][exam.exam_subjects[index]] ) : menu['阅卷数据管理']?.children)">
                        <el-button bg text size="small" >
                          <template #icon>
                            <i-data-file theme="multi-color" size="24" :fill="['#409eff' ,'#a0cfff' ,'#086bfc' ,'#c6e2ff']"/>
                          </template>
                          阅卷管理
                        </el-button>
                        <template #dropdown>
                          <el-dropdown-menu>
                            <el-dropdown-item
                                v-for="(item,index2) in (userStore.user.type == 4 ? (normalUserMenu['阅卷数据管理'][exam.exam_id][exam.exam_subjects[index]] ) : menu['阅卷数据管理']?.children)"
                                :key="index2"
                                @click="menuClick(exam,exam.exam_subjects[index],subject,item)"
                            >
                              {{item.permission_name}}
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>

                      <el-dropdown trigger="click" size="small" v-if="(userStore.user.type == 4 ? (normalUserMenu['评卷统计'] && normalUserMenu['评卷统计'][exam.exam_id] && normalUserMenu['评卷统计'][exam.exam_id][exam.exam_subjects[index]] ) : menu['评卷统计']?.children)">
                        <el-button bg text size="small" >
                          <template #icon>
                            <i-pie-five theme="multi-color" size="24" :fill="['#409eff' ,'#a0cfff' ,'#086bfc' ,'#c6e2ff']"/>
                          </template>
                          评卷统计
                        </el-button>
                        <template #dropdown>
                          <el-dropdown-menu>
                            <el-dropdown-item
                                v-for="(item,index2) in (userStore.user.type == 4 ? (normalUserMenu['评卷统计'][exam.exam_id][exam.exam_subjects[index]] ) : menu['评卷统计']?.children)"
                                :key="index2"
                                @click="menuClick(exam,exam.exam_subjects[index],subject,item)"
                            >
                              {{item.permission_name}}
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>

                      <el-dropdown trigger="click" size="small" v-if="(userStore.user.type == 4 ? (normalUserMenu['质量监控'] && normalUserMenu['质量监控'][exam.exam_id] && normalUserMenu['质量监控'][exam.exam_id][exam.exam_subjects[index]] ) : menu['质量监控']?.children)">
                        <el-button bg text size="small" >
                          <template #icon>
                            <i-slide theme="multi-color" size="24" :fill="['#409eff' ,'#a0cfff' ,'#086bfc' ,'#c6e2ff']"/>
                          </template>
                          质量监控
                        </el-button>
                        <template #dropdown>
                          <el-dropdown-menu>
                            <el-dropdown-item
                                v-for="(item,index2) in (userStore.user.type == 4 ? (normalUserMenu['质量监控'][exam.exam_id][exam.exam_subjects[index]] ) : menu['质量监控']?.children)"
                                :key="index2"
                                @click="menuClick(exam,exam.exam_subjects[index],subject,item)"
                            >
                              {{item.permission_name}}
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>

                      <el-dropdown trigger="click" size="small" v-if="(userStore.user.type == 4 ? (normalUserMenu['成绩管理'] && normalUserMenu['成绩管理'][exam.exam_id] && normalUserMenu['成绩管理'][exam.exam_id][exam.exam_subjects[index]] ) : menu['成绩管理']?.children)">
                        <el-button bg text size="small" >
                          <template #icon>
                            <i-arithmetic-one theme="multi-color" size="24" :fill="['#409eff' ,'#a0cfff' ,'#086bfc' ,'#c6e2ff']"/>
                          </template>
                          成绩管理
                        </el-button>
                        <template #dropdown>
                          <el-dropdown-menu>
                            <el-dropdown-item
                                v-for="(item,index2) in (userStore.user.type == 4 ? (normalUserMenu['成绩管理'][exam.exam_id][exam.exam_subjects[index]] ) : menu['成绩管理']?.children)"
                                :key="index2"
                                @click="menuClick(exam,exam.exam_subjects[index],subject,item)"
                            >
                              {{item.permission_name}}
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>

                    </el-space>
                  </template>
                </el-descriptions-item>
              </el-descriptions>

            </div>


          </template>
        </el-card>
      </div>
      <el-pagination
          @current-change="toPage"
          :current-page="search.page"
          :page-size="search.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="total"
          style="margin-left: 10px">
      </el-pagination>
    </el-main>

    <el-dialog :title="formTitle" v-model="dialogVisi" width="650px" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-form :model="examData" :rules="rules" ref="examForm" label-width="100px">
        <el-form-item label="考试名称" prop="examname">
          <el-input v-model="examData.examname" :disabled="unedit"></el-input>
        </el-form-item>
        <el-form-item label="考试类型" prop="examtype">
          <el-select v-model="examData.examtype" placeholder="考试类型" style="min-width: 300px;" @change="examData.unitid = []" >
            <el-option
                v-for="item in examTypes"
                :key="item.code"
                :label="item.code_name"
                :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="考试单位" prop="unitid" v-if="userStore.user.type === '1' || userStore.user.type === '2'">
          <el-select v-model="examData.unitid" placeholder="请选择考试单位" style="min-width: 300px;" multiple :multiple-limit="examData.examtype === '1' ? 1 : 999" @change="unitSelectChange">
            <el-option
                v-for="item in unitData"
                :key="item.unit_id"
                :label="item.unit_name"
                :value="item.unit_id">
              <span style="float: left">{{ item.unit_name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.unit_type === '3' ? '教育管理部门' : '学校' }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试年级" prop="grades">
          <el-checkbox-group v-model="examData.grades">
            <el-checkbox
                v-for="grade in grades"
                :key="grade.grade"
                :label="grade.grade"
                :value="grade.grade"
            />
          </el-checkbox-group>
          <span v-if="grades.length === 0">暂无年级，请先导入学生数据</span>
        </el-form-item>
        <el-form-item v-show="!unedit && grades.length !== 0">
          <el-checkbox v-model="examData.is_auto_import"></el-checkbox>
          是否自动导入参考学校年级下的学生
        </el-form-item>
        <el-form-item label="考试科目">
          <el-tag
              :key="tag.tag_id"
              v-for="tag in subjectTags"
              :type="tag.type"
              effect="dark"
              class="prop-tag"
              @click="tagClick(tag)"
              style="cursor: pointer">
            {{ tag.tag_catalog + ": " + tag.tag_name }}
          </el-tag>
        </el-form-item>
        <el-form-item label="试卷存储位置">
          <el-input v-model="examData.location" :disabled="formTitle.indexOf('编辑') !== -1"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="toSave">保 存</el-button>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>

import {useCodeStoreWithOut} from "@/store/modules/code";
import {useTagStoreWithOut} from "@/store/modules/tag";
import {useUserStoreWithOut} from "@/store/modules/user";
import {useAppStore} from "@/store/modules/app";
import Config from "@/utils/Config";

export default {
  name: "ManageExam",
  components: {},
  setup(){
    const codeStore = useCodeStoreWithOut()
    const tagStore = useTagStoreWithOut()
    const userStore = useUserStoreWithOut()
    const appStore = useAppStore()
    return {codeStore,tagStore,userStore,appStore}
  },
  computed:{
    menu(){
      const tree = this.userStore.treePages
      const res = {}
      if (tree){
        let examMge = []
        for (let i = 0; i < tree.length; i++) {
          if (tree[i].permission_name === "考试管理"){
            const t2 = tree[i].children
            for (let j = 0; i < t2.length; j++) {
              if (t2[j].permission_name === "考试管理"){
                examMge = t2[j]
                break
              }
            }
            break
          }
        }
        if(examMge.children){
          const children = examMge.children.filter(item => item.permission_type == 2)

          children.forEach(item => {
            res[item.permission_name] = item
          })
        }
      }

      return res
    },
    normalUserMenu(){
      const res = {}
      Object.keys(this.menu).forEach(key => {

        const examMap = {}

        this.menu[key].children.forEach(item => {
          if (!examMap[item.exam_id]){
            examMap[item.exam_id] = {}
          }
          const subject = examMap[item.exam_id]
          // 开始判断这个item即这个权限适用于哪个subject
          if (item.subject_ids.length !== 0) {
            // 科目权限
            item.subject_ids.forEach(subId => {
              if (!subject[subId]){
                subject[subId] = []
              }
              subject[subId].push(item)
            })
          }
          else if (item.question_ids.length !== 0) {

            const tempSet = new Set()
            // 题组权限
            item.question_ids.forEach(qid => {
              const subId = this.qIdToSubjectMap[qid]?.subject_id
              if (!subject[subId]){
                subject[subId] = []
              }
              if (!tempSet.has(subId)){
                subject[subId].push(item)
                tempSet.add(subId)
              }

            })
          }

        })

        res[key] = examMap

      })
      return res
    },
    qIdToSubjectMap(){
      const res = {}
      if (this.examQuestions){
        this.examQuestions.forEach(item => {
          res[item.question_id] = item
        })
      }
      return res
    },
    gradeNameMap(){
      const res = {}
      if (this.grades){
        this.grades.forEach(item => {
          res[item.grade] = item
        })
      }
      return res
    },
    subjectIdMap(){
      const res = {}
      if (this.subjectTags){
        this.subjectTags.forEach(item => {
          res[item.tag_id] = item
        })
      }
      return res
    },
    examSubjectCountMap(){
      const res = {}
      this.subjectScanCountList.forEach(item => {
        const key = item.exam_id + '-' + item.subject_id
        res[key] = item
      })
      return res
    }
  },
  data () {
    return {
      examQuestions:[],
      utype: null,
      total: 0,
      search: {
        page: 1,
        pagesize: 2,
        examname: "",
        examstate: "",
        stime: "",
        etime: ""
      },
      examTypes:[],
      tableData: [],
      stateCodes: [],
      unitData: [],
      subjectTags: [],
      unedit: true,
      dialogVisi: false,
      formTitle: "考试",
      examData: {
        examid: null,
        examname: "",
        unitid: [],
        grades:[],
        examtype: "1",
        subjects: null,
        location: "d:/uppath/files/",
        is_auto_import: true
      },
      grades:[],
      rules: {
        examname: [{required: true, message: "请填写考试名称", trigger: "blur"}, {max: 50, message: "不能超过50个字", trigger: "blur"}],
        location: [{required: true, message: "请填写试卷存储路径", trigger: "blur"}],
        grades: [{required: true,message:"请选择年级",trigger:'blur'}]
      },
      subjectScanCountList:[],
      normalUserScanSubjectIds:{} // 普通用户的扫描科目的权限
    }
  },
  watch: {
    subjectTags: function (val) {
      let checkedtags = []
      for (const tag of val) {
        if (tag.type === "success")
          checkedtags.push(tag.tag_id)
      }
      this.examData.subjects = checkedtags.length === 0 ? null : checkedtags
    },
    "userStore.user"(user){
      this.utype = user.type
      if (user.type === '1' || user.type === '2')
        this.loadUnits()
      if (user.type == "3"){
        // 学校管理员
        this.unitSelectChange(this.userStore.unit.unit_id)
      }
    }
  },
  mounted() {
    this.utype = this.userStore.user.type
    if (this.userStore.user.type === '1' || this.userStore.user.type === '2')
      this.loadUnits()
    if (this.userStore.user.type == "3"){
      // 学校管理员
      this.unitSelectChange(this.userStore.unit.unit_id)
    }
    this.loadCodes()
    this.loadTags()
    this.loadCodeByType()
    this.loadExam()
  },
  methods: {

    async unitSelectChange(val){
      if (val=== undefined || val.length !== 0){
        const response =  await this.axios.post("/api/exam/getGrade", {unitid:val})
        if (response.data.success) {
          this.grades = response.data.result
          this.examData.grades = []
        }
      }
    },

    //载入标签列表
    async loadTags () {
      let taglist = await this.tagStore.getTag("subject")
      taglist.forEach(function (tag) {
        tag.type = "info"
      })
      this.subjectTags = taglist
    },

    //载入代码列表
    async loadCodes () {
      this.stateCodes = await this.codeStore.getCode("exam_state")
    },

    async  loadCodeByType() {
      this.examTypes = await this.codeStore.getCode("exam_type")
    },


    //载入代码列表
    loadUnits () {
      this.axios.post("/api/common/loadlisteduunit")
          .then((response) => {
            if (response.data.success) {
              this.unitData = response.data.result
            }

          })

    },
    //载入考试列表
    loadExam () {
      this.axios.post("/api/exam/loadexam", this.search)
          .then((response) => {
            if (response.data.success) {
              this.total = response.data.result.totalrecords
              this.tableData = response.data.result.datalist
              if (response.data.result.otherInfo?.perms){
                // 这里是 普通用户 当前查出来考试的考试权限
                this.userStore.setPages(response.data.result.otherInfo.perms)

                response.data.result.otherInfo.perms.forEach(p => {
                  if(p.permission_url === '/scannerApi/login'){
                    this.normalUserScanSubjectIds[p.exam_id] = p.subject_ids
                  }
                })

                this.examQuestions =response.data.result.otherInfo.questions
              }
              this.subjectScanCountList = response.data.result.otherInfo.subjectScanCountList
            }
          })

    },
    //页码跳转
    toPage (page) {
      this.search.page = page
      this.loadExam()
    },
    //条件查询
    toSearch () {
      this.search.page = 1
      this.loadExam()
    },
    //标签点击事件
    tagClick (tag) {
      if (!this.unedit) {
        let tagarr = [...this.subjectTags]
        let t = tagarr.find((item) => { return item.tag_id === tag.tag_id })
        if (t.type === "info")
          t.type = "success"
        else
          t.type = "info"
        this.subjectTags = tagarr
      }
    },
    //关闭对话框
    closeDialog () {
      this.$refs["examForm"].resetFields()
      this.dialogVisi = false
      this.examData.examid = null
      let tagarr = [...this.subjectTags]
      for(const tag of tagarr) {
        tag.type = "info"
      }
      this.subjectTags = tagarr
    },
    //新增
    showAdd () {
      this.dialogVisi = true
      this.unedit = false
      this.examData.examid = null
      this.examData.examname = ""
      this.examData.grades = []
      this.examData.unitid = []
      let tagarr = this.subjectTags
      for (const tag of tagarr) {
        tag.type = "info"
      }
      this.subjectTags = tagarr
      this.formTitle = "新增考试"
      if (this.$refs["examForm"])
        this.$refs["examForm"].clearValidate()
    },
    //编辑
    async showEdit (data) {
      this.dialogVisi = true
      this.unedit = false
      this.examData.examid = data.exam_id
      this.examData.examname = data.exam_name
      this.examData.unitid = data.unit_ids
      await this.unitSelectChange(data.unit_ids)
      this.examData.grades = data.grades
      let tagarr = [...this.subjectTags]
      for(const tag of tagarr) {
        if (data.exam_subjects != null && data.exam_subjects.indexOf(tag.tag_id) > -1)
          tag.type = "success"
        else
          tag.type = "info"
      }
      this.subjectTags = tagarr
      this.formTitle = "编辑考试"
      if (this.$refs["examForm"])
        this.$refs["examForm"].clearValidate()
    },
    //删除
    toDel (data) {
      this.$confirm("确认删除此考试?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/exam/delexam", {examid: data.exam_id})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "考试删除成功", type: "success"})
                this.loadExam()
              }

            })

      }).catch(() => {})
    },
    //保存
    toSave () {
      this.$refs["examForm"].validate((valid) => {
        if (valid) {
          if ((this.utype === '1' || this.utype === '2') && this.examData.unitid === "") {
            this.$message({message: "请选择考试单位", type: "error"})
            return
          }
          if (this.examData.subjects == null) {
            this.$message({message: "请选择考试科目", type: "error"})
            return
          }
          const unit_names = []
          const unit_id = []
          this.unitData.forEach(unit => {
            if (this.examData.unitid.includes(unit.unit_id)){
              unit_names.push(unit.unit_name)
              unit_id.push(unit.unit_id)
            }
          })

          const exam_subject_names = []
          const grade_ids = []
          this.examData.grades.forEach(grade => {
            grade_ids.push(this.gradeNameMap[grade].grade_id)
          })
          this.examData.subjects.forEach(tag_id =>{
            exam_subject_names.push(this.subjectIdMap[tag_id].tag_name)
          })

          const data = {...this.examData,unit_names,unitid:unit_id,exam_subject_names,grade_ids}
          this.axios.post("/api/exam/saveexam", data)
              .then((response) => {
                if (response.data.code == 1) {
                  this.$message({message: "考试保存成功", type: "success"})
                  this.loadExam()
                  this.closeDialog()
                }else if (response.data.code == -3) {
                  // 弹出确认框
                  this.$confirm(response.data.result + '是否继续?注意此操作无法撤销', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                  }).then(async () => {
                    let resp = await this.axios.post("/api/exam/saveexam", {...data,confirmSubject: '1'})
                    if (resp.data.success) {
                      this.$message.success(resp.data.result)
                      this.loadExam()
                      this.closeDialog()
                    }
                  }).catch(() => {});
                }

              })

        }
        else
          return false
      })
    },
    //进入下一阶段
    toNext (data) {
      this.$confirm("确认进入下一阶段?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/exam/forwardexam", {examid: data.exam_id})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "操作成功", type: "success"})
                this.loadExam()
              }
            })
      }).catch(() => {})
    },
    //返回上一阶段
    toBack (data) {
      this.$confirm("`确认返回上一阶段?`", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/exam/backwardexam", {examid: data.exam_id})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "操作成功", type: "success"})
                this.loadExam()
              }

            })

      }).catch(() => {})
    },

    menuClick(data,subject_id,subject_name,menu){
      const name= menu.permission_url.split("/").join("-").substr(1)
      this.appStore.setExamUnit(data.unit_ids,data.unit_names)
      this.appStore.setExam(data.exam_id,data.exam_name)
      this.appStore.setSubject(subject_id,subject_name)
      this.appStore.setExamSubject(data.exam_subjects,data.exam_subjects_name)
      this.appStore.setGrades(data.grades)
      // 路由前进为url
      this.$router.push({name})
    },
    // 生成统计分析
    generateStatistics(exam){
      this.axios.post("/api/exam/generateStatistics", {exam_id:exam.exam_id})
          .then((response) => {
            if (response.data.success) {
              this.$message({message: response.data.result, type: "success"})
            }

          })
    },
    // 连接扫描仪
    connectScanner(exam,subject_id) {
      this.axios.post("/api/exam/connectScanner", {exam_id: exam.exam_id})
          .then((response) => {
            if (response.data.success) {
              this.$message({message: '获取扫描参数成功！正在连接...', type: "success"})
              const filter = response.data.result.scanPerm.filter(item => item.exam_id === exam.exam_id && item.subject_id === subject_id)
              if (filter.length === 0){
                this.$message({message: '暂无权限，请您刷新页面！', type: "warning"})
                return
              }else {
                response.data.result.scanPerm = filter[0]
              }

              this.axios.post("/scan/connect", response.data,{
                baseURL: Config.localScannerUrl,
                // 设置编码utf-8
                headers: {
                  'Content-Type': 'application/json;charset=UTF-8'
                }
              })
                  .then((r) => {
                    if (r.data.success) {
                      this.$message({message: '连接成功,请勿重复点击！', type: "success"})
                    }
                  }).catch((e) => {
                    this.$message({message: '请检查扫描客户端是否打开！', type: "error"})
              })
            }
          })
    }
  }
}
</script>

<style scoped>
.prop-tag {
  margin-right: 15px;
}

.exam-panel {
  display: flex;
  padding: 20px;
  width: calc(100% - 40px);
  flex-wrap: wrap;
}

.header-container {
  display: flex; /* 使用Flexbox布局 */
}
.left-content {
  flex-grow: 1; /* 左侧内容占剩余空间 */
}
.right-content {
  width: 300px; /* 右侧内容固定宽度 */
}


.task {
  position: relative;
  color: #2e2e2f;
  background-color: #fff;
  padding: 1rem;
  padding-bottom: 0;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  width: calc(100% - 20px);
}

.task:hover {
  /*box-shadow: var(--el-color-primary) 0px 2px 8px 0px;*/
  border-color: var(--el-color-primary) !important;
}

.task > p {
  font-size: 15px;
  margin: 1.2rem 0;
}

.tag {
  border-radius: 100px;
  padding: 4px 13px;
  font-size: 12px;
  color: #ffffff;
  background-color: #1389eb;
}

.tags {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.options {
  background: transparent;
  border: 0;
  color: #c4cad3;
  font-size: 17px;
}

:deep(.el-card__header){
  padding-bottom: 2px;
}










</style>
