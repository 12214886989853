<!--
扫描管理管理界面
最后编辑人：蒋浩翔
最后编辑时间：2024/5/11
最后编辑细节：新建
-->
<template>
  <el-container class="base-container">
    <el-header style="height: 20px; padding-right: 0;">
      <el-breadcrumb separator="/" style="display: inline-block; width: calc(100% - 100px);">
        <el-breadcrumb-item>考试管理</el-breadcrumb-item>
        <el-breadcrumb-item>扫描管理</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button siz="default" class="page-back-button" style="float: right;" icon="back" @click="$router.back()">返回</el-button>
    </el-header>
    <el-container class="main-container"   ref="mainSector">
      <el-header style="height: 32px; padding-right: 0;">
        <div style="display: flex; justify-content: space-between;">
          <div class="exam-title">{{ exam.exam_name }}</div>
          <div class="exam-subject">
            <el-button size="default" circle icon="refresh" @click="loadTeacherOfExam"></el-button>
            <span style="margin: 0 5px;">科目：</span>
            <el-select siz="default" v-model="search.subjectid" placeholder="请选择科目" @change="selectSubject">
              <el-option
                  v-for="item in exam.subjects"
                  :key="item.subject_id"
                  :label="item.subject_name"
                  :value="item.subject_id">
              </el-option>
            </el-select>
          </div>
        </div>
      </el-header>
      <el-container style="height: calc(100% - 32px);">
        <el-main>
          <div class="row">
            <div>查找教师：</div>
            <el-input siz="default" placeholder="请输入教师姓名" v-model="search1.username" @keyup.enter="toSearch1" style="width: 200px; min-width: 80px;">
            </el-input>
            <el-button siz="default" @click="toSearch1" icon="Search">查询</el-button>
            <el-button type="primary" siz="default" @click="showAdd" icon="Plus">添加扫描人员</el-button>
          </div>
          <el-table :data="tableData1" stripe>
            <el-table-column prop="user_name" label="用户名称" min-width="100"></el-table-column>
            <el-table-column prop="user_account" label="用户账号" min-width="120"></el-table-column>
            <el-table-column prop="cellphone" label="手机号" min-width="100"></el-table-column>
<!--            <el-table-column prop="is_online" label="状态" width="100">
              <template v-slot="scope">
                <el-tag v-if="scope.row.is_online === true" type="success">在线</el-tag>
                <el-tag v-else type="danger">离线</el-tag>
              </template>
            </el-table-column>-->
            <el-table-column label="操作" fixed="right" width="350">
              <template v-slot="scope">
                <el-button class="table-button" @click="toDel(scope.row)" type="danger" bg >
                  <template #icon><Delete/></template>
                  删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              @current-change="toPage"
              :current-page="search1.page"
              :page-size="search1.pagesize"
              layout="total, prev, pager, next, jumper"
              :total="total1">
          </el-pagination>
        </el-main>
      </el-container>
    </el-container>
    <el-dialog :title="tableTitle" v-model="dialogVisi" width="950px" >
      <div class="row">
        <div>查找教师：</div>
        <el-input siz="default" placeholder="教师姓名" v-model="search2.username" @keyup.enter="toSearch2" style="width: 150px; min-width: 80px;">
        </el-input>
        <el-button siz="default" @click="toSearch2" icon="Search">查询</el-button>
        <el-button siz="default" type="primary" @click="toSave">保 存</el-button>
      </div>
      <el-table :data="tableData2" stripe @selection-change="handleSelectionChange">
        <el-table-column
            type="selection"
            width="55">
        </el-table-column>
        <el-table-column prop="user_name" label="用户名称" min-width="100"></el-table-column>
        <el-table-column prop="user_account" label="用户账号" min-width="120"></el-table-column>
        <el-table-column prop="cellphone" label="手机号" min-width="100"></el-table-column>
      </el-table>
      <div slot="footer">
        <el-button type="primary" @click="toSave">保 存</el-button>
      </div>
    </el-dialog>


  </el-container>
</template>

<script>
import {Delete } from "@element-plus/icons-vue";

export default {
  name: "ManageExamScan",
  components: {Delete},
  data () {
    return {
      errorData:null,
      errorVisi:false,
      uploadVisi:false,
      exam: {
        exam_id: null,
        exam_name: "",
        unit_id: null,
        unit_name: "",
        exam_state: "",
        subjects: []
      },
      search: {
        examid: null,
        subjectid: null
      },
      search1: {
        page: 1,
        pagesize: 15,
        examid: null,
        subjectid: null,
        username: ""
      },
      search2: {
        examid: null,
        subjectid: null,
        unitid: null,
        username: ""
      },

      total1: 0,
      total2: 0,
      tableData1: [],
      tableData2: [],
      multipleSelection:[],
      dialogVisi: false,
      tableTitle: "未分配教师列表",
    }
  },

  mounted() {
    this.exam.exam_id= this.search.examid = this.search1.examid  = this.search2.examid  = sessionStorage.getItem("exam-mge-exam-id")
    this.exam.unit_id = sessionStorage.getItem("exam-mge-exam-unit-id")
    if (this.exam.exam_id != null) {
      this.exam.exam_id = Number(this.exam.exam_id)
      this.search.examid = this.exam.exam_id
    }
    if (this.exam.unit_id != null) {
      this.exam.unit_id = Number(this.exam.unit_id)
      this.search2.unitid = this.exam.unit_id
    }
    this.loadExamInfo()
  },

  methods: {
    //载入考试科目信息
    loadExamInfo () {
      this.axios.post("/api/common/loadexaminfo", {examid: this.exam.exam_id})
          .then((response) => {
            if (response.data.success) {
              this.exam = response.data.result
              if (this.exam.subjects.length > 0) {
                this.search.examid = this.examID
                this.search.subjectid = this.search1.subjectid = this.search2.subjectid = this.exam.subjects[0].subject_id
                this.loadTeacherOfExam()
                this.loadTeacherOfUnit()
              }
            }
          })
    },

    //选择科目重新加载
    selectSubject () {
      if (this.search.subjectid != null) {
        this.search1.subjectid = this.search2.subjectid = this.search.subjectid
        this.loadTeacherOfExam()
      }
    },


    //载入已分配教师列表
    loadTeacherOfExam () {
      this.axios.post("/api/exam/loadexamscanner", this.search1)
          .then((response) => {
            if (response.data.success) {
              this.total1 = response.data.result.totalrecords
              this.tableData1 = response.data.result.datalist
            }
          })
    },

    //载入未分配教师列表
    loadTeacherOfUnit () {
      this.axios.post("/api/exam/loadunitscanner", this.search2)
          .then((response) => {
            if (response.data.success) {
              this.tableData2 = response.data.result
            }
          })
    },

    //页码跳转
    toPage (page) {
      this.search1.page = page
      this.loadTeacherOfExam()
    },

    toSearch1 () {
      this.search1.page = 1
      this.loadTeacherOfExam()
    },

    //新增
    showAdd () {
      if (this.search2.examid !== null) {
        this.dialogVisi = true
        this.loadTeacherOfUnit()
        this.multipleSelection = []
      }
      else
        this.$message({message: "考试ID缺失", type: "warning"})
    },

    //删除
    toDel (data) {
      this.$confirm("确认删除该教师?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/exam/delscanner", { teacherid: data.user_id, examid : this.search1.examid, subjectid: this.search1.subjectid })
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "删除成功", type: "success"})
                this.loadTeacherOfExam()
              }
            })
      }).then(() => {
      }).catch(() => {
      })
    },

    //保存
    toSave () {
      if (this.multipleSelection.length !== 0) {
        this.axios.post("/api/exam/savescanner", { examid: this.search2.examid, subjectid: this.search2.subjectid, teacherList: this.multipleSelection})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "保存成功",type: "success"})
                this.loadTeacherOfExam()
                this.loadTeacherOfUnit()
              }
            })

      }
      else
        this.$message({message: "请勾选教师！", type: "warning"})
    },

    toSearch2 () {
      this.loadTeacherOfUnit()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

  }
}
</script>

<style scoped>

</style>
