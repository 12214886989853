<!--
学生管理界面
最后编辑人：宁茂
最后编辑时间：2022-5-8
最后编辑细节：增加年级
-->
<template>
  <el-container class="base-container">
    <el-container class="main-container">
      <el-aside id="unitTree" style="padding: 20px;">
        <div class="row">
          <el-input
              siz="default"
              placeholder="请输入单位名称"
              prefix-icon="Search"
              v-model="filterName"
              style="width: 200px; min-width: 80px;"></el-input>
        </div>
        <el-tree
            :data="treeData"
            :props="treeProps"
            node-key="unit_id"
            default-expand-all
            :filter-node-method="filterNode"
            :expand-on-click-node="false"
            :highlight-current="true"
            @node-click="clickNode"
            ref="unitTree">
          <template class="tree-node" v-slot="{ node, data }">
            <span>
              <i class="school" v-if="data.unit_type === '4'"></i>
              <i class="office-building" v-else></i>
              {{ node.label }}
            </span>
          </template>
        </el-tree>
      </el-aside>
      <el-main style="border-left: 1px solid #EBEEF5;">
        <div class="row">
          <el-button type="info" siz="default" @click="classMgeVisi = true" icon="Setting">班级管理</el-button>
          <el-button type="primary" siz="default" @click="showAdd" icon="Plus">学生</el-button>
          <el-button type="success" siz="default" v-show="!(progressData.isloading)" v-popover:popover-upload
                     icon="upload" @click="showUpload">导入
          </el-button>
          <el-button type="danger" icon="Delete" :disabled="selectStudents.length === 0" @click="batchDelStudent()">批量删除</el-button>
          <el-button type="primary" siz="default" icon="download" @click="toDownload">下载模板</el-button>
          <el-progress :text-inside="true" :stroke-width="20" :percentage="progressData.progressPercent"
                       v-show="progressData.isloading" :format="setItemText()"></el-progress>
          <a id="downlink" href="/api/info/student/downstuimporttemplate" v-show="false"></a>
          <el-popover
              trigger="click"
              ref="popover-upload"
              placement="bottom"
              :visible="popVisible"
              width="360px">
            <div style="width: 100%; text-align: right;">
              <el-button link icon="Close" @click="closeUpload"></el-button>
            </div>
            <el-upload
                action="/api/info/student/importstu"
                :data="uploadData"
                name="excelfile"
                ref="upload"
                drag
                :multiple="false"
                :before-upload="checkUpload"
                :on-success="afterSuccess">
              <i class="upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传xls或xlsx文件，且不超过10MB</div>
            </el-upload>
          </el-popover>
        </div>
        <div class="row">
          <el-input siz="default" placeholder="请输入学生姓名" v-model="search.stuname" @keyup.enter="toSearch"
                    style="width: 200px; min-width: 80px;">
          </el-input>
          <el-input siz="default" placeholder="请输入学生学号" v-model="search.stunum" @keyup.enter="toSearch"
                    style="width: 200px; min-width: 80px;">
          </el-input>
          <el-select siz="default" placeholder="请选择年级" v-model="search.grade_id" @change="toSearch" clearable
                     style="width: 200px; min-width: 80px;">
            <el-option v-for="(item,index) in gradeTags" :key="index"
                       :label="item.tag_name"
                       :value="item.tag_id"/>
          </el-select>
          <el-select siz="default" placeholder="请选择班级" v-model="search.class_id" @change="toSearch" clearable
                     style="width: 200px; min-width: 80px;">
            <el-option v-for="(item,index) in class_list" :key="index"
                       :label="item.grade +' '+item.class_name"
                       :value="item.class_id"/>
          </el-select>
          <el-button siz="default" @click="toSearch" icon="Search">查询</el-button>
        </div>
        <el-table :data="tableData" stripe
                  row-key="student_number"
                  @selection-change="(arr) => selectStudents = arr"
        >
          <!--选择框-->
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="unit_name" label="学校" min-width="100"></el-table-column>
          <el-table-column prop="student_name" label="姓名" min-width="100"></el-table-column>
          <el-table-column prop="student_number" label="学号" min-width="120"></el-table-column>
          <el-table-column prop="grade" label="年级" min-width="80"></el-table-column>
          <el-table-column prop="class_name" label="班级" min-width="120"></el-table-column>
          <el-table-column label="操作" fixed="right" width="250">
            <template v-slot="scope">
              <el-button class="table-button" @click="showEdit(scope.row)">编辑</el-button>
              <el-button class="table-button" @click="toDel(scope.row)" type="danger">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            @current-change="toPage"
            :current-page="search.page"
            v-model:page-size="search.pagesize"
            layout="prev, sizes, pager, next, jumper, ->, total"
            :page-sizes="[15,150,1500]"
            @sizeChange="toPage"
            :total="total">
        </el-pagination>
      </el-main>
    </el-container>

    <el-dialog :title="formTitle" v-model="dialogVisi" width="650px" :close-on-click-modal="false"
               :close-on-press-escape="false">
      <el-form :model="stuData" :rules="rules" ref="stuForm" label-width="100px">
        <el-form-item label="姓名" prop="stuname">
          <el-input v-model="stuData.stuname"></el-input>
        </el-form-item>
        <el-form-item label="学号" prop="stunum">
          <el-input v-model="stuData.stunum"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="stuData.password" placeholder="用于学生绑定账号，为空则与学号一致"></el-input>
        </el-form-item>
        <el-form-item label="年级" prop="grade_id">
          <el-select v-model="stuData.grade_id" clearable @change="changeGrade(stuData.grade_id)">
            <el-option v-for="(item,index) in gradeTags" :key="index"
                       :label="item.tag_name"
                       :value="item.tag_id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="班级" prop="class_name">
          <el-select v-model="stuData.class_id" clearable>
            <el-option v-for="(item,index) in all_class_list" :key="index"
                       :label="item.class_name"
                       :value="item.class_id"/>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="toSave">保 存</el-button>
      </div>
    </el-dialog>

    <el-dialog title="导入数据出错" v-model="errorVisi" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-table :data="errorData">
        <el-table-column prop="row" label="行号" width="80"></el-table-column>
        <el-table-column prop="msg" label="信息" width="600"></el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="班级管理" width="50%" v-model="classMgeVisi">
      <div style="margin-bottom: 10px">
        <el-select siz="default" placeholder="请选择年级" v-model="classMgeSelectGrade"  clearable
                   style="width: 200px; min-width: 80px;">
          <el-option v-for="(item,index) in gradeTags" :key="index"
                     :label="item.tag_name"
                     :value="item.tag_id"/>
        </el-select>
      </div>
      <el-space>
        <el-tag v-for="(clazz,index) in classMgeClassList"
                closable
                size="large"
                @close="delClass(clazz)"
                :key="index"
        >
          {{clazz.class_name}}
        </el-tag>
        <el-input
            v-if="classMgeInputVisible"
            ref="InputRef"
            v-model="classMgeInputValue"
            @keyup.enter="handleInputConfirm"
            @blur="handleInputConfirm"
        />
        <el-button v-else class="button-new-tag" @click="() => {
          classMgeInputVisible = true
          $nextTick(()=>$refs.InputRef.focus())
        }">
          + 新班级
        </el-button>
      </el-space>
    </el-dialog>
  </el-container>
</template>

<script>
import {useTagStoreWithOut} from "@/store/modules/tag";

export default {
  name: "StudentMge",
  data() {
    return {
      filterName: "",
      treeData: [],
      treeProps: {
        children: 'subunit',
        label: 'unit_name'
      },
      uploadData: {
        unitid: null
      },
      errorVisi: false,
      errorData: [],
      tableTitle: "",
      total: 0,
      search: {
        page: 1,
        pagesize: 15,
        unitid: null,
        stuname: "",
        stunum: "",
        class_id: "",
        grade_id: ""
      },
      class_list: [],
      grade_list: [],
      all_class_list: [],
      alL_grade_list: [],
      tableData: [],
      unedit: true,
      dialogVisi: false,
      formTitle: "用户",
      stuData: {
        stuname: "",
        stunum: "",
        password: "",
        grade_id: "",
        class_name: "",
        class_id: "",
        unitid: null
      },
      progressData: {
        total: 0,
        num: 0,
        progressPercent: 0,
        isloading: false,
      },
      interval: null,
      rules: {
        stuname: [{required: true, message: "请填写姓名", trigger: "blur"}, {
          max: 30,
          message: "不能超过30个字",
          trigger: "blur"
        }],
        stunum: [{required: true, message: "请填写学号", trigger: "blur"}, {
          pattern: /^\d*$/,
          message: "学号必须是数字",
          trigger: "blur"
        }],
        grade_id: [{required: true, message: "请选择年级", trigger: "change"},],
        class_id: [{required: true, message: "请选择班级", trigger: "change"},]
      },
      popVisible: false,
      gradeTags:[],
      classMgeVisi: false,
      classList:[],
      classMgeSelectGrade:null,
      classMgeInputVisible: false,
      classMgeInputValue: null,
      selectStudents: []
    }
  },
  computed:{
    classMgeClassList(){
      return this.class_list.filter(item => item.grade_id === this.classMgeSelectGrade)
    }
  },
  watch: {
    filterName(val) {
      this.$refs.unitTree.filter(val)
    }
  },
  mounted() {
    this.loadTreeData()
    this.tagStore.getTag("student_tag").then((tags) => {
      this.gradeTags = tags.filter((item) => item.tag_catalog === '年级')
    })
  },
  setup(){
    const tagStore = useTagStoreWithOut()
    return {tagStore}
  },
  methods: {
    // 批量删除学生
    batchDelStudent(){
      this.$confirm("确认删除选中的学生？此操作无法恢复！", "提示",  {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/info/student/batchDelStudent", {
          stuNumbers:this.selectStudents.map(stu=> stu.student_number),
          unitid: this.search.unitid
        })
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "删除成功", type: "success"})
                this.toSearch()
              }
            })

      }).catch(()=>{})
    },

    // 保存班级
    handleInputConfirm(){
      if(this.classMgeInputValue){
        this.axios.post("/api/info/student/saveClass", {class_name: this.classMgeInputValue, grade_id: this.classMgeSelectGrade,unitid: this.search.unitid})
            .then(async (response) => {
              if (response.data.success) {
                this.$message({message: "添加成功", type: "success"})
                this.class_list = await this.loadCla({unitid: this.search.unitid})
              }
            })
      }
      this.classMgeInputVisible = false
      this.classMgeInputValue = null
    },
    // 删除班级
    delClass(clazz){
      this.$confirm("确认删除此班级?这将删除该班级下的所有学生！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      } ).then(() => {
        this.axios.post("/api/info/student/delClass", {class_id: clazz.class_id})
            .then(async (response) => {
              if (response.data.success) {
                this.$message({message: "删除成功", type: "success"})
                this.class_list = await this.loadCla({unitid: this.search.unitid})
              }
            })
      }).catch(()=>{})
    },
    //载入单位树形列表
    loadTreeData() {
      this.axios.get("/api/info/student/loadtreeeduunit")
          .then((response) => {
            if (response.data.success) {
              this.treeData = response.data.result
            }

          })

    },
    //查找树节点
    filterNode(value, data) {
      if (!value) return true;
      return data.unit_name.indexOf(value) !== -1;
    },
    //点击树节点
    async clickNode(data) {
      this.tableTitle = data.unit_name
      this.search.unitid = data.unit_id
      this.uploadData.unitid = data.unit_id
      this.loadStu()
      this.class_list = await this.loadCla(this.search)
    },
    //载入学生列表
    loadStu() {
      this.axios.post("/api/info/student/loadstu", this.search)
          .then((response) => {
            if (response.data.success) {
              this.total = response.data.result.totalrecords
              this.tableData = response.data.result.datalist
            }

          })

    },
    //载入班级列表
    async loadCla(params) {
      const response = await this.axios.post("/api/info/student/loadcla", params);
      if (response.data.success) {
        return response.data.result;
      } else {
        throw new Error('加载班级列表失败！');
      }
    },
    //页码跳转
    toPage(page) {
      this.search.page = page
      this.loadStu()
    },
    //条件查询
    async toSearch() {
      this.search.page = 1
      this.loadStu()
      this.class_list = await this.loadCla(this.search)
    },
    //删除班级
    toBatchDel(delType) {
      this.$messageBox.confirm("确认删除此班级?这将删除该班级下的所有学生！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        let data = {}
        if (delType === "grade")
          data = {unitid: this.search.unitid, grade_id: this.search.grade_id, delType: delType}
        if (delType === "class")
          data = {unitid: this.search.unitid, class_id: this.search.class_id, delType: delType}
        this.axios.post("/api/info/student/batchdel", data)
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "删除成功", type: "success"})
                if (delType === "grade") {
                  this.search.grade_id = ""
                  this.search.class_id = ""
                }
                if (delType === "class")
                  this.search.class_id = ""
                this.toSearch()
              }
            })
      })
    },
    //关闭对话框
    closeDialog() {
      this.$refs["stuForm"].resetFields()
      this.dialogVisi = false
      this.stuData.stuname = ""
      this.stuData.stunum = ""
      this.stuData.grade_id = ""
      this.stuData.class_id = ""
      this.stuData.unitid = this.search.unitid
    },
    //新增
    async showAdd() {
      if (this.search.unitid !== null) {
        this.dialogVisi = true
        this.unedit = false
        this.stuData.stuname = ""
        this.stuData.stunum = ""
        this.stuData.grade_id = ""
        this.stuData.class_id = ""
        this.stuData.unitid = this.search.unitid
        this.formTitle = "新增学生"
        if (this.$refs["stuForm"])
          this.$refs["stuForm"].clearValidate()
        this.all_class_list = await this.loadCla({unitid: this.search.unitid, grade_id: this.stuData.grade_id})
      } else
        this.$message({message: "请先选择单位", type: "warning"})
    },
    //编辑
    async showEdit(data) {
      this.dialogVisi = true
      this.unedit = false
      this.stuData.stuname = data.student_name
      this.stuData.stunum = data.student_number
      this.stuData.grade_id = data.grade_id
      this.stuData.class_id = data.class_id
      this.stuData.unitid = this.search.unitid
      this.formTitle = "编辑学生"
      if (this.$refs["stuForm"])
        this.$refs["stuForm"].clearValidate()
      this.all_class_list = await this.loadCla({unitid: this.search.unitid, grade_id: this.stuData.grade_id})
    },
    //年级改变
    async changeGrade(value) {
      this.all_class_list = await this.loadCla({unitid: this.search.unitid, grade_id: value})
      this.stuData.class_id = ""
    },
    //删除
    toDel(data) {
      this.$confirm("确认删除此学生?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/info/student/delstu", {stunum: data.student_number})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "删除成功", type: "success"})
                if (data.student_number === this.stuData.stunum) {
                  this.$refs["stuForm"].resetFields()
                  this.unedit = true
                  this.stuData.stuname = ""
                  this.stuData.stunum = ""
                  this.stuData.grade_id = ""
                  this.stuData.class_id = ""
                  this.stuData.unitid = null
                  this.formTitle = "学生"
                }
                this.loadStu()
              }

            })

      })
    },
    //保存
    toSave() {
      this.$refs["stuForm"].validate((valid) => {
        if (valid) {
          this.axios.post("/api/info/student/savestu", this.stuData)
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "保存成功", type: "success"})
                  this.loadStu()
                  this.closeDialog()
                }
              })

        } else
          return false
      })
    },

    //显示上传框
    showUpload() {
      if (this.uploadData.unitid != null) {
        this.popVisible = true
        this.$refs["upload"].clearFiles()
      } else
        this.$message({message: "请先选择单位", type: "warning"})
    },
    //关闭上传框
    closeUpload() {
      this.popVisible = false
    },
    //上传前检查
    checkUpload(file) {
      this.getProgress()
      clearInterval(this.interval)
      this.interval = setInterval(this.getProgress, 2000)
      if (this.uploadData.unitid == null) {
        this.$message({message: "请先选择单位", type: "warning"})
        return false
      }
      let name = file.name.toLowerCase()
      if (!name.endsWith(".xls") && !name.endsWith(".xlsx")) {
        this.$message({message: "只能上传xls或xlsx文件", type: "warning"})
        return false
      }
      return true
    },
    //上传后处理
    afterSuccess(data) {
      this.$refs["upload"].clearFiles()
      if (data.success) {
        // this.$message({message: "导入成功", type: "success"})
        this.loadStu()
        this.popVisible = false
      } else {
        if (typeof data.result === "string")
          this.$alert(data.result, "导入出错")
        else {
          this.errorData = data.result
          this.errorVisi = true
        }
      }
    },
    //下载模板
    toDownload() {
      document.getElementById("downlink").click()
    },

    //获取进度
    getProgress() {
      const that = this;
      that.axios.get("/api/info/student/getprogress")
          .then((response) => {
            if (response.data.success) {
              that.progressData.total = response.data.result.TOTAL
              that.progressData.num = response.data.result.NUM
              that.progressData.progressPercent = ((response.data.result.NUM / response.data.result.TOTAL) * 100) | 0;
              if (that.progressData.total !== 0) {
                that.progressData.isloading = true
              }
              if (response.data.result.ISEXCEPTIONAL) {
                this.$alert("导入出错,请重试！")
                clearInterval(this.interval)
                this.interval = null
                that.progressData.isloading = false
              }
              if (response.data.result.ISEND) {
                that.progressData.isloading = false
                clearInterval(this.interval);
                this.interval = null;
              }
              if (that.progressData.num === that.progressData.total) {
                that.progressData.isloading = false
                clearInterval(this.interval);
                this.interval = null;
                this.toSearch()
              }
            } else {
              that.$alert(response.data.result, "进度条数据获取错误");
              clearInterval(this.interval);
            }
          })
    },

    setItemText() {
      return () => {
        return '上传中：' + this.progressData.progressPercent + '%'
      }
    }

  }
}
</script>

<style scoped>

</style>
