<!--
用户类型授权管理界面
最后编辑人：郑志强
最后编辑时间：2022-3-8
最后编辑细节：由AXIOS对错误信息进行拦截处理，删除每个函数的错误处理。
-->
<template>
  <el-container class="base-container">
    <el-container class="main-container">
      <el-aside width="50%" style="padding: 20px;">
        <div v-for="t in userTypeData" :key="t.user_type" @click="toEdit(t)" :class="typeClass(t)">
          {{ t.user_type_name }}
        </div>
      </el-aside>
      <el-main style="background-color: #FBFBF9; border-left: 1px solid #EBEEF5;">
        <el-row>
          <el-col :span="24"><div style="padding: 7px 15px">{{ formTitle }}</div></el-col>
        </el-row>
        <el-tree
            :data="treeData"
            :props="treeProps"
            node-key="permission_id"
            default-expand-all
            show-checkbox
            ref="permissionTree">
        </el-tree>
        <el-row style="text-align: center;">
          <el-button type="primary" @click="toSave" :disabled="unedit">保 存</el-button>
        </el-row>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "UserTypeAssign",
  data () {
    return {
      unedit: true,
      formTitle: "用户类型",
      permissionData: [],
      userTypeData: [],
      treeData: [],
      treeProps: {
        children: 'subpermission',
        label: 'permission_name'
      },
      assignData: {
        usertype: "",
        permissionids: null
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    typeClass: function (t) {
      return {user_type: true, user_type_actived: this.assignData.usertype === t.user_type}
    },
    loadData () {
      this.axios.get("/api/permission/loadallpermission")
          .then((response) => {
            if (response.data.success) {
              this.permissionData = response.data.result
            }
          })

      this.axios.get("/api/permission/loadallusertype")
          .then((response) => {
            if (response.data.success) {
              this.userTypeData = response.data.result
            }

          })

    },
    toEdit (data) {
      this.formTitle = "用户类型：" + data.user_type_name
      this.assignData.usertype = data.user_type
      this.assignData.permissionids = data.permission_ids
      this.treeData = this.permissionData
      this.$refs.permissionTree.setCheckedKeys(data.permission_ids == null ? [] : data.permission_ids)
      this.unedit = false
    },
    toSave () {
      this.assignData.permissionids = this.$refs.permissionTree.getCheckedKeys()
      if (this.assignData.permissionids.length === 0)
        this.assignData.permissionids = null
      this.axios.post("/api/permission/saveusertypeassign", this.assignData)
          .then((response) => {
            if (response.data.success) {
              this.$message({message: "授权保存成功", type: "success"})
              var type = this.userTypeData.find((item) => {
                return item.user_type === this.assignData.usertype
              })
              type.permission_ids = this.assignData.permissionids
            }

          })

    }
  }
}
</script>

<style scoped>
.user_type {
  cursor: pointer;
  margin: 5px;
  padding: 5px;
}
.user_type:hover {
  background-color: #1890ff;
  color: #ffffff;
}

.user_type_actived {
  color: #1890ff;
}
.user_type.user_type_actived:hover {
  background-color: #1890ff;
  color: #ffffff;
}
</style>