import axios from "axios";
import {ElMessage} from "element-plus";




export const download = (api,params) =>{
  // 下载文件
  axios.get(api, { responseType: 'blob',params })
      .then(response => {
        if (response.data.type === "application/json"){
          // 请求失败
          const reader = new FileReader();  // 创建FileReader对象
          reader.readAsText(response.data);  // 将Blob数据转换为文本
          reader.onload = () => {
            const json = JSON.parse(reader.result); // 解析文本为JSON对象
            ElMessage.error(json.result)
          };
        }else{
          // 构造一个 Blob 对象
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          // 创建一个 URL 对象
          const url = window.URL.createObjectURL(blob)
          // 创建一个 <a> 元素
          const link = document.createElement('a')
          link.href = url

          // 获取文件名的正则
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

          const disposition = response.headers['content-disposition'];
          const matches = filenameRegex.exec(disposition);

          const filename = matches && matches[1].replace(/['"]/g, '');
// 解码
          const decodeFilename = decodeURIComponent(escape(filename));
          link.download = decodeFilename;
          // 点击链接进行下载
          link.click()
          // 释放 URL 对象
          window.URL.revokeObjectURL(url)
        }

      })
      .catch(error => {
        console.log(error)
      })
}
