<!--
权限管理界面
最后编辑人：郑志强
最后编辑时间：2022-3-8
最后编辑细节：由AXIOS对错误信息进行拦截处理，删除每个函数的错误处理。
-->
<template>
  <el-container class="base-container">
    <el-container class="main-container">
      <el-aside width="50%" id="permissionTree" style="padding: 20px;">
        <div class="row">
            <el-button type="primary" siz="default" icon="Plus" @click="toAdd(null)">根权限</el-button>
            <div>查找权限：</div>
            <el-input
                siz="default"
                placeholder="请输入权限名称"
                prefix-icon="Search"
                v-model="filterName"
                style="width: 200px; min-width: 80px;"></el-input>
        </div>
        <el-tree
            :data="treeData"
            :props="treeProps"
            node-key="permission_id"
            default-expand-all
            draggable
            @node-drop="nodeDrop"
            :filter-node-method="filterNode"
            :expand-on-click-node="false"
            ref="permissionTree">
          <template class="tree-node"  v-slot="{ node, data }">
            <span @click="() => toEdit(data)">
              <i class="connection" v-if="data.permission_type === '1'"></i>
              <i class="folder-opened" v-else-if="data.permission_type === '2'"></i>
              <i class="postcard" v-else></i>
              {{ node.label }}
            </span>
            <span style="padding-left:10px">
              <el-button-group>
                <el-tooltip content="上移" placement="top" effect="light" transition="" :enterable="false">
                  <el-button type="info" class="tree-button" icon="arrow-up" @click="() => moveUp(data)"></el-button>
                </el-tooltip>
                <el-tooltip content="下移" placement="top" effect="light" transition="" :enterable="false">
                  <el-button type="info" class="tree-button" icon="arrow-down" @click="() => moveDown(data)"></el-button>
                </el-tooltip>
                <el-tooltip content="新增子权限" placement="top" effect="light" transition="" :enterable="false">
                  <el-button type="primary" class="tree-button" icon="Plus" @click="() => toAdd(data)"></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top" effect="light" transition="" :enterable="false">
                  <el-button type="danger" class="tree-button" icon="delete" @click="() => toDel(data)"></el-button>
                </el-tooltip>
              </el-button-group>
            </span>
          </template>
        </el-tree>
      </el-aside>
      <el-main style="background-color: #FBFBF9; border-left: 1px solid #EBEEF5;">
        <el-row>
          <el-col :span="24"><div style="padding: 7px 15px">{{ formTitle }}</div></el-col>
        </el-row>
        <el-form :model="permissionData" :rules="rules" ref="permissionForm" label-width="100px">
          <el-form-item label="权限名称" prop="permissionname">
            <el-input v-model="permissionData.permissionname" :disabled="unedit"></el-input>
          </el-form-item>
          <el-form-item label="权限类型" prop="type">
            <el-select v-model="permissionData.type" placeholder="请选择权限类型" @change="typeChange" :disabled="unedit">
              <el-option label="功能" value="1"></el-option>
              <el-option label="目录" value="2"></el-option>
              <el-option label="页面" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="权限地址" prop="link" v-show="permissionData.type !== '2'">
            <el-input v-model="permissionData.link" :disabled="unedit"></el-input>
          </el-form-item>
          <el-form-item label="排序号" prop="sort">
            <el-input v-model="permissionData.sort" :disabled="unedit"></el-input>
          </el-form-item>
          <el-form-item label="考试数据权限" prop="data_scope" v-show="permissionData.type == 3 || permissionData.type == 1">
            <el-radio-group v-model="permissionData.data_scope">
              <el-radio :value="null">无</el-radio>
              <el-radio :value="'1'">考试</el-radio>
              <el-radio :value="'2'">科目</el-radio>
              <el-radio :value="'3'">题组</el-radio>
              <el-radio :value="'4'">题组轮次</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="toSave" :disabled="unedit">保 存</el-button>
          </el-form-item>
        </el-form>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "Permission",
  watch: {
    filterName (val) {
      this.$refs.permissionTree.filter(val)
    }
  },
  data () {
    var validateLink = (rule, value, callback) => {
      if (this.permissionData.type !== "2") {
        if (value === "") {
          callback(new Error('请填写权限地址'))
        } else {
          callback()
        }
      }
      else {
        callback()
      }
    };
    return {
      unedit: true,
      formTitle: "权限",
      filterName: "",
      treeData: [],
      rules: {
        permissionname: [{required: true, message: "请填写权限名称", trigger: "blur"}, {max: 255, message: "不能超过255个字", trigger: "blur"}],
        type: [{required: true, message: "请选择权限类型", trigger: "change"}],
        sort: [{required: true, message: "请填写排序号", trigger: "blur"}, {pattern: /^\d+$/, message: "必须是整数", trigger: "blur"}],
        link: [{validator: validateLink, trigger: "blur"}, {max: 255, message: "不能超过255个字符", trigger: "blur"}]
      },
      treeProps: {
        children: 'subpermission',
        label: 'permission_name'
      },
      permissionData: {
        permissionid: "",
        permissionname: "",
        type: "1",
        link: "",
        sort: "",
        parentid: "",
        data_scope: null
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    //权限类型选择发生变更
    typeChange () {
      if (this.permissionData.type === "2")
        this.permissionData.link = ""
    },
    //查找树节点
    filterNode (value, data) {
      if (!value) return true;
      return data.permission_name.indexOf(value) !== -1;
    },
    //拖拽节点变更父级单位
    nodeDrop (dragging, drop, type) {
      this.axios.post("/api/permission/changepermission", {srcid: dragging.data.permission_id, tgtid: drop.data.permission_id, type: type})
          .then((response) => {
            if (!response.data.success) {
              this.loadData()
            }
          })

    },
    //载入全部权限树形列表
    loadData () {
      this.axios.get("/api/permission/loadallpermission")
          .then((response) => {
            if (response.data.success) {
              this.treeData = response.data.result
            }

          })

    },
    //权限上移
    moveUp (data) {
      this.axios.post("/api/permission/moveuppermission", {permissionid: data.permission_id, sort: data.sort_number, parentid: data.parent_id})
          .then((response) => {
            if (response.data.success) {
              this.$message({message: "上移成功", type: "success"})
              this.loadData()
              if (data.permission_id === this.permissionData.permissionid)
                this.permissionData.sort = (data.sort_number - 1).toString()
            }
          })

    },
    //权限下移
    moveDown (data) {
      this.axios.post("/api/permission/movedownpermission", {permissionid: data.permission_id, sort: data.sort_number, parentid: data.parent_id})
          .then((response) => {
            if (response.data.success) {
              this.$message({message: "下移成功", type: "success"})
              this.loadData()
              if (data.permission_id === this.permissionData.permissionid)
                this.permissionData.sort = (data.sort_number + 1).toString()
            }
          })

    },
    //权限新增
    toAdd (data) {
      this.$refs["permissionForm"].resetFields()
      this.unedit = false
      this.permissionData.permissionid = ""
      if (data === null) {
        this.permissionData.parentid = ""
        this.formTitle = "新增根权限"
      }
      else {
        this.permissionData.parentid = data.permission_id
        this.formTitle = "新增子权限（父权限：" + data.permission_name + "）"
      }
    },
    //权限编辑
    toEdit (node) {
      this.$refs["permissionForm"].resetFields()
      this.unedit = false
      this.permissionData.permissionid = node.permission_id
      this.permissionData.permissionname = node.permission_name
      this.permissionData.type = node.permission_type
      this.permissionData.link = node.permission_url === null ? "" : node.permission_url
      this.permissionData.sort = node.sort_number.toString()
      this.permissionData.parentid = node.parent_id
      this.permissionData.data_scope = node.data_scope
      this.formTitle = "编辑权限"
    },
    //权限删除
    toDel (data) {
      this.$confirm("删除此权限将一并删除其下子权限，确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/permission/delpermission", {permissionid: data.permission_id})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "权限删除成功", type: "success"})
                if (data.permission_id === this.permissionData.permissionid) {
                  this.$refs["permissionForm"].resetFields()
                  this.unedit = true
                  this.permissionData.permissionid = ""
                  this.permissionData.parentid = ""
                }
                this.loadData()
              }
            })

      })
    },
    //权限保存
    toSave () {
      this.$refs["permissionForm"].validate((valid) => {
        if (valid) {
          this.axios.post("/api/permission/savepermission", {data_scope:null,...this.permissionData})
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "权限保存成功", type: "success"})
                  this.loadData()
                }
              })

        }
        else
          return false
      })
    }
  }
}
</script>

<style scoped>

</style>