<script>
import ProgressComponent from "@/components/utils/ProgressComponent.vue";

export default {
  name: "TestPage",
  components: {ProgressComponent},
  methods:{
    startTask() {
      this.axios.post('/api/common/test-progress',{},{establishWS: true})
          .then(response => {
            if (response.data.success){
              this.$message.success(response.data.result)
            }else {
              this.$message.error(response.data.result)
            }
          })
          .catch(error => {
            console.error("Error starting task:", error);
          });
    },
  }
}
</script>

<template>
<div>
  <h3>进度条测试</h3>
  <div>
    <el-button @click="startTask">开始任务2</el-button>
<!--注意这里的name需要和后端创建进度条类的name属性一致！！！！！！！！！！！！！    -->
    <ProgressComponent name="测试进度条"/>
  </div>
</div>
</template>

<style scoped>

</style>