<!--
经销商管理界面
最后编辑人：郑志强
最后编辑时间：2022-3-8
最后编辑细节：由AXIOS对错误信息进行拦截处理，删除每个函数的错误处理。
-->
<template>
  <el-container class="base-container">
    <el-container class="main-container">
      <el-aside width="50%" id="unitTree" style="padding: 20px;">
        <div class="row">
          <el-button type="primary" siz="default" icon="Plus" @click="toAdd(null)">最上级单位</el-button>
          <div>查找单位：</div>
          <el-input
              siz="default"
              placeholder="请输入单位名称"
              prefix-icon="Search"
              v-model="filterName"
              style="width: 200px; min-width: 80px;"></el-input>
        </div>
        <el-tree
            :data="treeData"
            :props="treeProps"
            node-key="unit_id"
            default-expand-all
            draggable
            :allow-drop="allowNodedrop"
            @node-drop="nodeDrop"
            :filter-node-method="filterNode"
            :expand-on-click-node="false"
            ref="unitTree">
          <template class="tree-node" v-slot="{ node, data }">
            <span @click="() => toEdit(data)">
              <i class="school " v-if="data.unit_type === '4'"></i>
              <i class="office-building" v-else></i>
              {{ node.label }}
              <el-tag effect="dark" type="info" size="default" v-if="data.state === '0'">停用</el-tag>
            </span>
            <span style="padding-left:10px">
    <el-button-group :enterable="false">
      <el-tooltip content="停用" placement="top" effect="light" transition="" :enterable="false"
                  v-if="data.state === '1'">
        <el-button type="success" class="tree-button" @click="() => toSwitch(data)">
          <el-icon>
            <Open/>
          </el-icon>
        </el-button>
      </el-tooltip>
      <el-tooltip content="启用" placement="top" effect="light" transition="" :enterable="false"
                  v-if="data.state === '0'">
        <el-button type="info" class="tree-button" @click="() => toSwitch(data)">
          <el-icon>
            <TurnOff/>
          </el-icon>
        </el-button>
      </el-tooltip>
      <el-tooltip content="新增子单位" placement="top" effect="light" transition="" :enterable="false">
        <el-button type="primary" class="tree-button" @click="() => toAdd(data)">
          <el-icon>
            <Plus/>
          </el-icon>
        </el-button>
      </el-tooltip>
      <el-tooltip content="删除" placement="top" effect="light" transition="" :enterable="false">
        <el-button type="danger" class="tree-button" @click="() => toDel(data)">
          <el-icon>
            <Delete/>
          </el-icon>
        </el-button>
      </el-tooltip>
    </el-button-group>
  </span></template>
        </el-tree>
      </el-aside>
      <el-main style="background-color: #FBFBF9; border-left: 1px solid #EBEEF5;">
        <el-row>
          <el-col :span="24">
            <div style="padding: 7px 15px">{{ formTitle }}</div>
          </el-col>
        </el-row>
        <el-form :model="unitData" :rules="rules" ref="unitForm" label-width="100px">
          <el-form-item label="单位名称" prop="unitname">
            <el-input v-model="unitData.unitname" :disabled="unedit"></el-input>
          </el-form-item>
          <el-form-item label="单位编码" prop="unitcode">
            <el-input v-model="unitData.unitcode" :disabled="unedit"></el-input>
          </el-form-item>
          <el-form-item label="简写代码" prop="shortcode">
            <el-input v-model="unitData.shortcode" :disabled="unedit"></el-input>
          </el-form-item>
          <el-form-item label="单位类型" prop="unittype">
            <el-select v-model="unitData.unittype" placeholder="请选择单位类型" :disabled="unedit">
              <el-option
                  v-for="item in unitTypeList"
                  :key="item.code"
                  :label="item.code_name"
                  :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="单位标签">
            <el-tag
                :key="tag.tag_id"
                v-for="tag in tags"
                :type="tag.type"
                effect="dark"
                class="prop-tag"
                @click="tagClick(tag)"
                style="cursor: pointer">
              {{ tag.tag_catalog + ": " + tag.tag_name }}
            </el-tag>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="toSave" :disabled="unedit">保 存</el-button>
          </el-form-item>
        </el-form>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "EducationUnit",
  data() {
    return {
      unedit: true,
      formTitle: "单位",
      filterName: "",
      treeData: [],
      treeProps: {
        children: 'subunit',
        label: 'unit_name'
      },
      tags: [],
      unitTypeList: [],
      unitData: {
        unitid: null,
        unitname: "",
        unitcode: "",
        shortcode: "",
        unittype: "",
        parentid: null,
        unittags: null
      },
      rules: {
        unitname: [{required: true, message: "请填写单位名称", trigger: "blur"}, {
          max: 30,
          message: "不能超过30个字",
          trigger: "blur"
        }],
        unitcode: [{required: true, message: "请选择单位编码", trigger: "change"}, {
          max: 20,
          message: "不能超过20个字符",
          trigger: "blur"
        }],
        shortcode: [{required: true, message: "请填写简写代码", trigger: "blur"}, {
          max: 4,
          message: "不能超过4个字符",
          trigger: "blur"
        }],
        unittype: [{required: true, message: "请选择单位类型", trigger: "change"}]
      },
    }
  },
  watch: {
    tags: function (val) {
      let checkedtags = []
      for (const tag of val) {
        if (tag.type === "success")
          checkedtags.push(tag.tag_id)
      }
      this.unitData.unittags = checkedtags.length === 0 ? null : checkedtags
    },
    filterName(val) {
      this.$refs.unitTree.filter(val)
    }
  },
  mounted() {
    this.loadData()
    this.loadTags()
    this.loadUnitType()
  },
  methods: {
    //查找树节点
    filterNode(value, data) {
      if (!value) return true;
      return data.unit_name.indexOf(value) !== -1;
    },
    //只允许拖拽进入方式
    allowNodedrop(dragging, drop, type) {
      return type === "inner"
    },
    //拖拽节点变更父级单位
    nodeDrop(dragging, drop) {
      this.axios.post("/api/unit/changeparent", {unitid: dragging.data.unit_id, parentid: drop.data.unit_id})
          .then((response) => {
            if (!response.data.success) {
              this.loadData()
            }
          })

    },
    //载入单位树形列表
    loadData() {
      this.axios.get("/api/unit/loadeduunit")
          .then((response) => {
            if (response.data.success) {
              this.treeData = response.data.result
            }

          })

    },
    //载入单位标签列表
    loadTags() {
      this.axios.post("/api/common/loadtagbytype", {type: "unit_tag"})
          .then((response) => {
            if (response.data.success) {
              let taglist = response.data.result
              taglist.forEach(function (tag) {
                tag.type = "info"
              })
              this.tags = taglist
            }

          })

    },

    //载入单位类型列表
    loadUnitType() {
      this.axios.post("/api/common/loadcodebytypes", {types: ['unit_type']})
          .then((response) => {
            if (response.data.success) {
              this.unitTypeList = response.data.result.unit_type

            }

          })

    },

    //新增
    toAdd(data) {
      this.$refs["unitForm"].resetFields()
      this.unedit = false
      this.unitData.unitid = null
      let tagarr = [...this.tags]
      for (const tag of tagarr) {
        tag.type = "info"
      }
      this.tags = tagarr
      if (data === null) {
        this.unitData.parentid = null
        this.formTitle = "新增最上级单位"
      } else {
        this.unitData.parentid = data.unit_id
        this.formTitle = "新增子单位（父单位：" + data.unit_name + "）"
      }
    },
    //编辑
    toEdit(node) {
      this.$refs["unitForm"].resetFields()
      this.unedit = false
      this.unitData.unitid = node.unit_id
      this.unitData.unitname = node.unit_name
      this.unitData.unitcode = node.unit_code
      this.unitData.shortcode = node.short_code
      this.unitData.unittype = node.unit_type
      this.unitData.parentid = node.unit_id
      let tagarr = [...this.tags]
      for (const tag of tagarr) {
        if (node.unit_tags != null && node.unit_tags.indexOf(tag.tag_id) > -1)
          tag.type = "success"
        else
          tag.type = "info"
      }
      this.tags = tagarr
      this.formTitle = "编辑单位"
    },
    //删除
    toDel(data) {
      this.$confirm("确认删除此单位?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/unit/delunit", {unitid: data.unit_id})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "单位删除成功", type: "success"})
                if (data.unit_id === this.unitData.unitid) {
                  this.$refs["unitForm"].resetFields()
                  this.unedit = true
                  this.unitData.unitid = null
                  let tagarr = [...this.tags]
                  for (const tag of tagarr) {
                    tag.type = "info"
                  }
                  this.tags = tagarr
                  this.unitData.parentid = null
                  this.formTitle = "单位"
                }
                this.loadData()
              }

            })

      })
    },
    //保存
    toSave() {
      this.$refs["unitForm"].validate((valid) => {
        if (valid) {
          this.axios.post("/api/unit/saveunit", this.unitData)
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "单位保存成功", type: "success"})
                  this.loadData()
                }
              })

        } else
          return false
      })
    },
    //切换状态
    toSwitch(data) {
      let w = (data.state === "1" ? "停用" : "启用")
      let msg = "是否" + w + "单位：" + data.unit_name + " 及其子单位和各单位下用户？"
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/unit/switchunitstate", {unitid: data.unit_id})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "单位" + w + "成功", type: "success"})
                this.loadData()
              }
            })

      })
    },
    //标签点击事件
    tagClick(tag) {
      if (!this.unedit) {
        let tagarr = [...this.tags]
        let t = tagarr.find((item) => {
          return item.tag_id === tag.tag_id
        })
        if (t.type === "info")
          t.type = "success"
        else
          t.type = "info"
        this.tags = tagarr
      }
    }
  }
}
</script>

<style scoped>
.prop-tag {
  margin-right: 15px;
}
</style>