<script>
import {useUserStoreWithOut} from "@/store/modules/user";
import {ArrowRight} from "@element-plus/icons";

export default {
  name: "DisbindAccount",
  setup() {
    const userStore = useUserStoreWithOut()
    return { userStore }
  },
  computed:{
    ArrowRight() {
      return ArrowRight
    },
    studentUnitRolesMap(){
      const res = {}
      if (this.userStore?.user){
        this.userStore.unitRoles.forEach(item => {
          if (item.user_type == 9){
            res[item.unit_id + '-'+ item.user_type] = item
          }
        })
      }

      return  res
    }
  },
  methods:{
    disbindStudentAccount(item){
      this.$confirm('确定要解绑吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.axios.post("/api/login/disbindStudentAccount", item)
        this.userStore.loadUserInfo()
      }).catch(()=>{})
    }
  },
  mounted() {
    this.userStore.loadUserInfo()
  }
}
</script>

<template>
  <div style="box-sizing: border-box;padding: 20px">
    <el-breadcrumb :separator-icon="ArrowRight" style="margin-bottom: 10px">
      <el-breadcrumb-item :to="{ path: '/main/cardMenu' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>学生账号绑定</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card style="max-width: 480px;"
             v-for="(item,index) in studentUnitRolesMap"
             :key="index"
    >
      <template #header>
        <div>
          {{item.unit_name}}
        </div>
      </template>
      <template #default>
        <div>姓名：{{item.student_name}}</div>
        <div>学号：{{item.student_number}}</div>
      </template>
      <template #footer>
        <el-button
            type="warning"
            @click="disbindStudentAccount(item)"
        >解绑</el-button>
      </template>
    </el-card>
  </div>
</template>

<style scoped>

</style>