<template>
  <el-container class="base-container">
    <el-header style="height: 20px;">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>考试管理</el-breadcrumb-item>
        <el-breadcrumb-item>题组配置</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="main-container">
      <div class="row">科目列表：
        <el-select siz="default" v-model="paper" clearable placeholder="请选上传试卷科目">
          <el-option
              v-for="item in paperList"
              :key="item.paper_id"
              :label="item.paper_name"
              :value="item">
          </el-option>
        </el-select>
      </div>
      <div class="main-container-config">
        <div class="main-container-left" style="flex-wrap: nowrap">
          <el-input
              placeholder="输入关键字进行过滤"
              v-model="filterText">
          </el-input>
          <el-tabs v-model="activeName">
            <el-tab-pane label="题组列表" name="view">
              <div class="row">
                <el-button type="success" siz="default" icon="Plus" @click="show=true">创建题组</el-button>
                <el-button type="success" siz="default" icon="check" @click="saveSubQuestions">保存勾选子题</el-button>
                <el-button type="primary" siz="default" icon="orange"   @click="autoGenerateGroups">按照大题名称生成题组</el-button>

              </div>
              <el-tree
                  class="filter-tree"
                  :data="groupList"
                  :props="groupProps"
                  :filter-node-method="filterNode"
                  default-expand-all
                  @node-click="changeColor"
                  node-key="uid"
                  ref="groupTree">
               <template class="paper-row"   :class="getClass(data)" v-slot="{ node, data }">
                 <span>{{ data.subquestion_name }}</span>
                 <span style="padding-left:10px">
                        <el-button type="danger" class="tree-button" icon="delete"
                                   @click="() => toQuestionDel(node,data)"></el-button>
                 </span>
             </template>
              </el-tree>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="main-container-right">
          <el-tree
              ref="tree"
              class="filter-tree"
              :data="subQuestionsList"
              :props="questionProps"
              show-checkbox
              default-expand-all
              :check-strictly="true"
              node-key="subquestion_id"
          ></el-tree>
        </div>
      </div>
      <el-dialog
          center
          :title="title"
          v-model="show"
          width="40%">
        <el-form :model="groupInfo" :rules="rules" ref="groupForm" label-width="100px">
          <el-form-item label="题组名称" prop="groupname">
            <el-input v-model="groupInfo.groupname"></el-input>
          </el-form-item>
        </el-form>
        <el-row style="display: flex;flex-direction: row;flex-wrap: nowrap;justify-content: center">
          <el-button @click="show = false">取 消</el-button>
          <el-button type="primary" @click="save">保 存</el-button>
        </el-row>

      </el-dialog>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: "SubQuestionConfig",
  data() {
    return {
      checked: [],
      questionProps: {
        label: 'subquestion_name'
      },
      groupProps: {
        label: 'subquestion_name',
        children: 'sublist'
      },
      groupInfo: {
        examid: "",
        subjectid: "",
        groupname: "",
        questions: null,
        groupid: ""
      },
      paperInfo: {
        page: 1,
        pagesize: 15,
        paperid: "",
        examid: "",
        subjectid: ""
      },
      activeColor: "",
      filterText: "",
      title: "新增题组",
      show: false,
      paper: null,
      activeName: "view",
      paperList: [],
      subQuestionsList: [],
      groupList: [],
      selectedList: [],
      rules: {
        groupname: [{required: true, message: "请填写题组名称", trigger: "change"}, {
          max: 25,
          message: "不能超过25个字符",
          trigger: "blur"
        }],
      },
    }
  },
  watch: {
    filterText(val) {
      this.$refs.groupTree.filter(val);
    },
    paper: {
      immediate: true,
      handler(value) {
        if (value !== null && value !== "") {
          this.paperInfo.paperid = value.paper_id
          this.paperInfo.examid = value.exam_id
          this.paperInfo.subjectid = value.subject_id
          this.getGroupList()
          this.getSubQuestions()
        } else {
          this.paperInfo.paperid = ""
          this.paperInfo.examid = ""
          this.paperInfo.subjectid = ""
        }


      }
    }
  },
  mounted() {
    this.getPaperList()
  },
  methods: {
    changeColor(node, data) {
      if (typeof node.subquestion_id === "undefined") {
        this.checked = []
        this.activeColor = node.group_id
        var nodeList = data.childNodes
        nodeList.forEach(node => {
          this.checked.push(node.data.subquestion_id)
        })
        this.$refs.tree.setCheckedKeys(this.checked)
      }
    },
    getClass(data) {
      if (this.activeColor !== "") {
        if (data.group_id === this.activeColor)
          return "group_selected"
      } else {
        return ""
      }
    },

    filterNode(value, data) {
      if (!value) return true;
      return data.subquestion_name.indexOf(value) !== -1;
    },
    autoGenerateGroups(){
      this.axios.post("/api/subquestionconfig/autogenerategroups", {
        "examid": this.paperInfo.examid,
        "subjectid": this.paperInfo.subjectid
      })
          .then((response) => {
            if (response.data.success) {
              this.$message({message: response.data.result, type: "success"})
              this.getGroupList()
            }
          })
    },
    saveSubQuestions() {
      var keyList = this.$refs.tree.getCheckedKeys()
      this.axios.post("/api/subquestionconfig/addsubquestion", {
        "subquestions": keyList.toString(),
        "examid": this.paperInfo.examid,
        "groupid": this.activeColor,
        "subjectid": this.paperInfo.subjectid
      })
          .then((response) => {
            if (response.data.success) {
              this.$message({message: response.data.result, type: "success"})
              this.getGroupList()
            }
          })
    },
    toQuestionDel(node, data) {
      if (typeof data.subquestion_id === "undefined") {//删除题组
        this.$confirm("确认删除题组?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.axios.post("/api/subquestionconfig/deletegroupquestion", {
            examid: this.paperInfo.examid,
            subjectid: this.paperInfo.subjectid,
            groupid:data.group_id
          })
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: response.data.result, type: "success"})
                  this.getGroupList()
                  this.$refs.tree.setCheckedKeys([])
                }
              })

        })
      } else {  //删除子题
        this.$confirm("确认删除子题?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          var parentNode = node.parent.data
          this.axios.post("/api/subquestionconfig/deletesubgroupquestion", {
            examid: this.paperInfo.examid,
            subjectid: this.paperInfo.subjectid,
            subquestionid:data.subquestion_id,
            groupid:parentNode.group_id

          })
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: response.data.result, type: "success"})
                  this.getGroupList()
                  this.$refs.tree.setCheckedKeys([])
                }
              })

        })
      }
    },

    save() {
      if (this.paperInfo.examid === '') {
        this.$message.error("请先选择试卷在进行题组创建。")
        return false
      }
      this.groupInfo.examid = this.paperInfo.examid
      this.groupInfo.subjectid = this.paperInfo.subjectid
      this.axios.post("/api/subquestionconfig/addorupdate", this.groupInfo).then((response) => {
        if (response.data.success) {
          this.show = false
          this.$message.success(response.data.result)
          this.getGroupList()
        }
      })
    },
    //对题组和子题生成唯一ID
    addQuestionUniID(list) {
      let i = 1
      list.forEach(ques => {
        ques.uid = i
        i++
        if (typeof ques.sublist !== "undefined") {
          ques.sublist.forEach(sub => {
            sub.uid = i
            i++
          })
        }
      })
    },
    getGroupList() {
      this.axios.post("/api/subquestionconfig/getgrouplist", this.paperInfo).then((response) => {
        if (response.data.success) {
          var list = response.data.result
          this.addQuestionUniID(list)
          this.groupList = list

        }
      })
    },
    getPaperList() {
      var data = {}
      this.axios.post("/api/examupload/getpaperlist", data).then((response) => {
        if (response.data.success) {
          this.paperList = response.data.result
        }
      })
    }
    ,
    getSubQuestions() {
      this.axios.post("/api/subquestionconfig/querysubquestions", this.paperInfo).then((response) => {
        if (response.data.success) {
          this.subQuestionsList = response.data.result

        }
      })
    }
  }
}
</script>

<style scoped>
.main-container-config {
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.main-container-right {
  width: 50%;
  height: 100%;
  max-height: 800px;
  overflow: auto;
}

.main-container-left {

  width: 50%;
  height: 100%;
  max-height: 800px;
  overflow: auto;

}

.paper-row {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

</style>
