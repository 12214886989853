import { defineStore } from 'pinia'
import { store } from '../index'


// 这是一个持久化的仓库，存储在本地
export const useAppStore = defineStore('app', {
    state: () => {
        return {
            exam_id: null,
            exam_name: "",
            exam_type: "",
            subject_id:null,
            subject_name:"",
            exam_subject_ids:[],
            exam_subjects_name:[],
            exam_unit_id: null,
            exam_unit_name: "",
            exam_grades: [], // 考试年级
            markingConfig:{} // 阅卷的配置 question_id -> {zoom: 1,scrollLeft: 0,scrollRight: 0}
        }
    },
    getters: {},
    actions: {
        setExam(exam_id, exam_name) {
            this.exam_id = exam_id
            this.exam_name = exam_name
        },
        setSubject(subject_id,subject_name) {
            this.subject_id = subject_id
            this.subject_name = subject_name
        },
        setExamSubject(exam_subject_ids,exam_subjects_name) {
            this.exam_subject_ids = exam_subject_ids
            this.exam_subjects_name = exam_subjects_name
        },
        setExamUnit(exam_unit_id,exam_unit_name) {
            this.exam_unit_id = exam_unit_id
            this.exam_unit_name = exam_unit_name
        },
        setGrades(grades){
            this.exam_grades = grades
        },
        setExamType(exam_type){
            this.exam_type = exam_type
        },
        setMarkingConfigByQuestion(qid,config){
            this.markingConfig[qid] = config
        },
        getMarkingConfigByQuestion(qid){
            return this.markingConfig[qid]
        },
        removeMarkingConfigByQuestion(qid){
            delete this.markingConfig[qid]
        }
    },
    persist: true
})

export const useAppStoreWithOut = () => {
    return useAppStore(store)
}
