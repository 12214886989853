import { defineStore } from 'pinia'
import { store } from '../index'
import {ElMessage, ElMessageBox} from 'element-plus'
import {router} from "@/main";
import axios from "axios";
import {unref} from "vue";
import Config from "@/utils/Config";

//[{
  //     "permission_id": 4,
  //     "permission_name": "信息管理",
  //     "permission_type": "2",
  //     "permission_url": null,
  //     "parent_id": null,
  //     "sort_number": 1,
  //     "create_time": 1609948800000,
  //     "last_edit_time": 1609948800000,
  //     "permission_level": 1,
  //     "last_editor": null,
  //     "data_scope": null
  // }],根据permission_id和parent_id来构建
const buildTree = (pages) => {
    const tree = []
    const map = {}
    pages.forEach(page => {
        if (page.permission_id) map[page.permission_id] = page
    })
    pages.forEach(page => {
        if (page.parent_id) {
        if (map[page.parent_id]) {
            if (!map[page.parent_id].children) {
            map[page.parent_id].children = []
            }
            map[page.parent_id].children.push(page)
        }
        } else if(page.permission_id){
        tree.push(page)
        }
    })
    return tree
}

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      user: {}, // 用户信息
      tokenValue: "", // token的值
      pages:[], // 权限list
      unit: {}, // 当前单位
      unitRoles:[], // 所有单位角色
      pagePath:[], //当前页面面包屑路径
      treePages:[], // 由pages组成的树
      loading: false,// 是否全局加载中，如果是的话，不应该进行任何操作，包括键盘操作
      websocket: null, // websocket连接,
      websocketOnMessageFunc:[], // websocket消息处理函数
    }
  },
  getters: {
    getUserInfo() {
      return this.user
    },
    getPages() {
      return this.pages
    }
  },
  actions: {
      setLoading(loading) {
          this.loading = loading
      },
    addPathFromRouter(routerPath,otherInfo){
      const r = unref(routerPath)
      this.addPath(r.fullPath, r.meta.title,otherInfo)
    },
    addPath(fullPath,title,otherInfo) {
      this.pagePath.push({fullPath,title,otherInfo})
    },
    setPathFromRouter(routerPath){
      this.clearPath()
      this.addPathFromRouter(routerPath)
    },
    clearPath(){
      this.pagePath = []
    },
    delPath(path) {
      this.pagePath.splice(this.pagePath.indexOf(path), 1)
    },
    setUserInfo(data) {
      this.user = data.user
      this.pages = data.pages
      this.tokenValue = data.token
      this.unit = data.unit
      this.unitRoles = data.userRoleUnits
      // 根据data.pages构建tree
        this.treePages = buildTree(data.pages)
    },
    setPages(pages) {
      this.pages = pages
      this.treePages = buildTree(pages)
    },
    loadUserInfo() {
      axios.get("/api/login/userinfo")
          .then((response) => {
            if (response.data.success) {
              this.setUserInfo(response.data.result)
            }
          })
    },
    async logout(){
        await axios.get("/api/login/dologout")
        this.reset()
    },
    logoutConfirm() {
      ElMessageBox.confirm("是否退出本系统？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: 'warning'
      })
        .then(async () => {
            axios.post("/scan/disconnect", {},{baseURL: Config.localScannerUrl,showLoading: false})
                .then((r) => {}).catch((e) => {
                console.log(e)
            })
          this.logout()
        })
        .catch(() => {})
    },
    changeUnitRole(user_type,unit_id){
        axios.post("/api/login/changeUnitRole",{user_type:user_type,unit_id:unit_id})
            .then((response) => {
                if (response.data.success) {
                    this.pages = []
                    this.loadUserInfo()
                    // 返回主页/main/cardMenu
                    router.replace({name: "cardMenu"})
                }
                ElMessage.success("已切换单位角色")
            })
    },
    reset() {
      this.setUserInfo({
        user: {},
        pages:[],
        unit: {}
      })
      router.replace({name: "login"})
    },
    setWebSocket(socket){
      this.websocket = socket
    },
    addWebSocketOnMessageFunc(func){
      this.websocketOnMessageFunc.push(func)
    },
    delWebSocketOnMessageFunc(func){
      this.websocketOnMessageFunc.splice(this.websocketOnMessageFunc.indexOf(func), 1)
    },
      // 建立websocket连接
      initWebSocket()  {
          if (!this.websocket) {
              const socket = new WebSocket(Config.backendWS+"/"+this.tokenValue)
              // 监听socket连接
              socket.onopen = () => {
                  console.log("socket连接成功")
                  this.setWebSocket(socket)
              }
              // 监听socket错误信息
              socket.onerror = (err) => {
                  console.error("连接错误")
                  console.error(err)
                  this.$message.warning("您的浏览器无法连接至ws,这将导致进度条无法正常显示！")
              }
              //监听socket消息
              socket.onmessage = (msg) => {
                  for (let i = 0; i < this.websocketOnMessageFunc.length; i++) {
                      this.websocketOnMessageFunc[i](msg)
                  }
              }
              // 监听socket关闭信息
              socket.onclose = (e) => {
                  console.error("socket已经关闭")
                  console.error(e)
                  this.setWebSocket(null)
              }
          }
      }
  },
  persist: false
})

export const useUserStoreWithOut = () => {
  return useUserStore(store)
}
