import { defineStore } from 'pinia'
import { store } from '../index'
import axios from "axios";


export const useTagStore = defineStore('tag', {
    state: () => {
        return {
            tags: {},
        }
    },
    getters: {},
    actions: {
        async getTag (tag) {
            let code = this.tags[tag]
            if (code){
                return code
            }else {
                const response = await axios.post("/api/common/loadtagbytype", {type: tag})
                if (response.data.success) {
                    this.tags[tag] = response.data.result
                    return response.data.result
                }
            }
        },
    },
    persist: false
})

export const useTagStoreWithOut = () => {
    return useTagStore(store)
}
