<script>
import axios from "axios";
import {useAppStoreWithOut} from "@/store/modules/app";





const addSubjectQuestionPerm = (row,subjectQuestionInfo,qIdMap) => {

  if (row.subpermission.length !== 0){
    row.subpermission.forEach(item => {
      addSubjectQuestionPerm(item,subjectQuestionInfo,qIdMap)
    })
  }else {
    if (row.data_scope){
      const dataScopePerms = []
      if (row.data_scope == 2){
        // 科目权限
        subjectQuestionInfo.subjects.forEach((i) => {
          dataScopePerms.push({permission_name:i.subject_name,...i,permission_id:row.permission_id + "-" + i.subject_id,scope: '2'})
        })
      }else if (row.data_scope == 3){
        // 题组权限
        subjectQuestionInfo.questions.forEach((i) => {
          const subject = qIdMap[i.question_id].subject_name
          dataScopePerms.push({permission_name:subject+"："+i.question_name,...i,permission_id:row.permission_id + "-" + i.subject_id + "-" + i.question_id,scope: '3'})
        })
      }else if(row.data_scope == 4){
        // 题组轮次权限
        subjectQuestionInfo.questions.forEach((i) => {
          const turnPerms = []
          if (i.mark_mode == 1){
            // 1评
            turnPerms.push({permission_name:'第一轮',turn:'1',permission_id:row.permission_id + "-" + i.subject_id + "-" + i.question_id+ "-1",scope: '4'})
          }else if (i.mark_mode == 2 || i.mark_mode == 3 || i.mark_mode == 5){
            // 2评、2+1,3+1
            turnPerms.push({permission_name:'第一轮',turn:'1',permission_id:row.permission_id + "-" + i.subject_id+ "-" + i.question_id+ "-1",scope: '4'})
            turnPerms.push({permission_name:'第二轮',turn:'2',permission_id:row.permission_id + "-" + i.subject_id + "-" + i.question_id+ "-2",scope: '4'})
          }else if (i.mark_mode == 4){
            // 1+1+1
            turnPerms.push({permission_name:'第一轮',turn:'1',permission_id:row.permission_id + "-" + i.subject_id + "-" + i.question_id+ "-1",scope: '4'})
            turnPerms.push({permission_name:'第二轮',turn:'2',permission_id:row.permission_id + "-" + i.subject_id + "-" + i.question_id+ "-2",scope: '4'})
            turnPerms.push({permission_name:'第三轮',turn:'3',permission_id:row.permission_id + "-" + i.subject_id + "-" + i.question_id+ "-3",scope: '4'})
          }
          turnPerms.push({permission_name:'最终轮',turn:'8',permission_id:row.permission_id + "-" + i.subject_id + "-" + i.question_id+ "-8",scope: '4'})
          turnPerms.push({permission_name:'异常轮',turn:'9',permission_id:row.permission_id + "-" + i.subject_id + "-" + i.question_id+ "-9",scope: '4'})

          const subject = qIdMap[i.question_id].subject_name
          dataScopePerms.push({permission_name:subject+"："+i.question_name,...i,subpermission:turnPerms,permission_id:row.permission_id + "-" + i.subject_id + "-" + i.question_id})
        })
      }
      row.subpermission = dataScopePerms
    }
  }
}

export default {
  name: "ExamPermission",
  computed:{
    permsTree(){
      const tree = []
      let qIdMap = {}
      this.subjectQuestionInfo.questions.forEach(i=>{
        qIdMap[i.question_id] = i
      })
      this.permissionTree.forEach(item => {
        tree.push(item)
        addSubjectQuestionPerm(item,this.subjectQuestionInfo,qIdMap)
      })
      return tree
    },
    idToSubjectMap(){
      const res  = {}
      this.subjectQuestionInfo.subjects.forEach(i=>{
        res[i.subject_id] = i
      })
      return res
    },
    idToQuestionMap(){
      const res  = {}
      this.subjectQuestionInfo.questions.forEach(i=>{
        res[i.question_id] = i
      })
      return res
    },
    examManagerUserIdMap(){
      const res = {}
      this.examManagers.forEach( i => {
        res[i.user_id] = i
      })
      return res
    },
    examSubjectResearcherUserIdMap(){
      const res = {}
      this.examSubjectResearchers.forEach( i => {
        res[i.user_id] = i
      })
      return res
    }
  },
  data(){
    return {
      permissionTree:[],
      exam_id:"",
      subjectQuestionInfo:{
        questions:[],
        subjects:[]
      },
      users:[],
      userSearch:{
        page: 1,
        pagesize: 100,
        search: ""
      },
      userTotal: 0,
      totalpage: 0,
      clickUsers:{},
      multi: false,
      userPerms:[],

      permsType:'阅卷权限',
      examManagers:[], // 考试管理员
      examSubjectResearchers: [], //考试学科教研员
      examSubjectResearcherSubjectId: null
    }
  },
  methods:{

    // 选择条改变
    segmentedChange(val){
      this.clickUsers = {}
      this.multi = false
      this.examManagers = []
      if (val !== '阅卷权限'){
        this.getAllUsers()
      }
      if (val === "考试管理员"){
        this.getExamManager()
      }else if (val === "考试学科教研员"){
        this.getExamSubjectResearcher()
      }
    },
    // 获取考试的考试学科教研员
    async getExamSubjectResearcher(){
      const resp = await axios.post("/api/exam/examPermission/getExamSubjectResearcher",{
        exam_id: parseInt(this.exam_id),
        subject_id: this.examSubjectResearcherSubjectId
      })
      if (resp.data.success){
        this.examSubjectResearchers = resp.data.result
      }
    },
    // 添加考试的考试学科教研员
    async addExamSubjectResearcher(userId){
      const resp = await axios.post("/api/exam/examPermission/addExamSubjectResearcher",{
        exam_id: parseInt(this.exam_id),
        user_id:userId,
        subject_id: this.examSubjectResearcherSubjectId
      })
      if (resp.data.success){
        await this.getExamSubjectResearcher()
      }
    },
    // 删除考试的学科教研员
    async delExamSubjectResearcher(user_id){
      const resp = await axios.post("/api/exam/examPermission/delExamSubjectResearcher",{
        exam_id: parseInt(this.exam_id),
        user_id:user_id,
        subject_id: this.examSubjectResearcherSubjectId
      })
      if (resp.data.success){
        await this.getExamSubjectResearcher()
      }
    },

    // 获取考试的考试管理员
    async getExamManager(){
      const resp = await axios.post("/api/exam/examPermission/getExamManagers",{
        exam_id: parseInt(this.exam_id)
      })
      if (resp.data.success){
        this.examManagers = resp.data.result
      }
    },
    // 添加考试的管理员
    async addExamManager(userId){
      const resp = await axios.post("/api/exam/examPermission/addExamManager",{
        exam_id: parseInt(this.exam_id),
        user_id:userId
      })
      if (resp.data.success){
        await this.getExamManager()
      }
    },
    // 删除考试管理员
    async delExamManager(user_id){
      const resp = await axios.post("/api/exam/examPermission/delExamManager",{
        exam_id: parseInt(this.exam_id),
        user_id:user_id
      })
      if (resp.data.success){
        await this.getExamManager()
      }
    },
    // 获取所有考试可以分配的权限
    async getAllPermission() {
      const resp = await axios.post("/api/exam/examPermission/list",{})
      if (resp.data.success){
        this.permissionTree = resp.data.result
      }
    },
    // 根据考试id获取这个考试的科目和题组
    async getSubjectAndQuestionsByExamId() {
      const resp = await axios.post("/api/exam/examPermission/getSubjectAndQuestionsByExamId", {
        exam_id: parseInt(this.exam_id)
      })
      if (resp.data.success){
        this.subjectQuestionInfo = resp.data.result
        if (this.subjectQuestionInfo.subjects.length > 0){
          this.examSubjectResearcherSubjectId = this.subjectQuestionInfo.subjects[0].subject_id
        }
      }
    },
    // 获取选中的节点
    getTreeSelect(){
      return this.$refs.tree.getCheckedNodes(true)
    },
    // 获取关于本次考试下单位的普通用户
    async getUsers(){
      const resp = await axios.post("/api/exam/examPermission/getUsers", {...this.userSearch,exam_id:parseInt(this.exam_id)})
      if (resp.data.success){
        this.userTotal = resp.data.result.totalrecords
        this.totalpage =  resp.data.result.totalpage
      }
      return resp.data.result.datalist
    },
    // 获取本次考试下单位的所有用户以及没有单位的用户
    async getAllUsers(){
      const resp = await axios.post("/api/exam/examPermission/getAllUsers", {...this.userSearch,exam_id:parseInt(this.exam_id)})
      if (resp.data.success){
        this.userTotal = resp.data.result.totalrecords
        this.totalpage =  resp.data.result.totalpage
      }
      return resp.data.result.datalist
    },
    async scrollLoad(){
      if (this.userSearch.page < this.totalpage){
        this.userSearch.page = this.userSearch.page + 1
        for (let user in (await this.getUsers())) {
          this.users.push(user)
        }
      }
    },
    // 点击用户
    async clickUser(user){
      if (this.permsType === '阅卷权限'){
        if (this.multi){
          if (this.clickUsers[user.user_id]){
            // 删除这个元素
            delete this.clickUsers[user.user_id]
          }else {
            this.clickUsers[user.user_id] = user
          }
        }else {
          this.clickUsers = []
          this.clickUsers[user.user_id] = user
          this.getUserPerms()
        }
      } else if (this.permsType === '考试管理员' && !this.examManagerUserIdMap[user.user_id]){
        this.addExamManager(user.user_id)
      }else if (this.permsType === '考试学科教研员' && !this.examSubjectResearcherUserIdMap[user.user_id]){
        this.addExamSubjectResearcher(user.user_id)
      }

    },
    // 获取某个用户的菜单权限
    async getUserPerms(){
      // 获取this.clickUsers的第一个元素
      const user = this.clickUsers[Object.keys(this.clickUsers)[0]]


      const resp = await axios.post("/api/exam/examPermission/getUserPermission",{
        user_id: user.user_id,
        exam_id: parseInt(this.exam_id)
      })
      if (resp.data.success){
        this.userPerms = resp.data.result
        this.$refs.tree.setCheckedKeys([],false)
        let keys = []
        this.userPerms.forEach((p) => {
          let pid = p.permission_id
          if (p.subject_ids && p.subject_ids.length !== 0){
            p.subject_ids.forEach(i => {
              keys.push(pid+'-'+i)
            })
          }else if (p.question_ids && p.question_ids.length !== 0){
            p.question_ids.forEach(i => {
              const subjectId = this.idToQuestionMap[i]?.subject_id
              if (subjectId){
                keys.push(pid+"-"+subjectId+"-"+i)
              }
            })
          }else if (p.question_turn && p.question_turn.length !== 0){
            p.question_turn.forEach(i => {
              const qId = i.question_id
              const turns = i.turn
              const subjectId = this.idToQuestionMap[qId]?.subject_id
              if (subjectId){
                turns.forEach( t => {
                  keys.push(pid+"-"+subjectId+"-"+qId+"-"+t)
                })
              }


            })
          }else {
            keys.push(pid)
          }
        })


        // 展开
        const expand = (node) => {
          node.expanded = true
          if(node.parent){
            expand(node.parent)
          }
        }
        // 折叠
        const fold = (node) => {
          node.expanded = false
          if(node.parent){
            fold(node.parent)
          }
        }

        // 先全部折叠，然后在展开选中的结点
        const keysSet = new Set()
        for (let i = 0; i < keys.length; i++) {
          this.$refs.tree.setChecked(keys[i],true,false)
          keysSet.add(keys[i])
        }
        const nodesMap = this.$refs.tree.store.nodesMap
        const allTreeNodeKeys = Object.keys(nodesMap)
        allTreeNodeKeys.forEach(k => {
          fold(nodesMap[k])
        })

        allTreeNodeKeys.forEach(k => {
          const node = nodesMap[k]
          if (keysSet.has(k)){
            expand(node)
          }
        })
      }
    },
    // 保存某个用户的权限
    async saveUserPerms(){
      if (this.multi === false){
        const perms = this.getTreeSelect()
        const idToPerm = {}
        perms.forEach( p => {
          let permId = undefined

          let questionId = undefined
          let turn = undefined
          let subjectId = undefined


          const arr = (p.permission_id + "").split("-")
          permId = parseInt(arr[0])
          if (p.scope == 4){
            // 题组轮次权限
            questionId = parseInt(arr[2])
            turn = arr[3]
          }else if (p.scope == 3){
            // 题组权限
            questionId = p.question_id
          }else if (p.scope == 2){
            // 科目权限
            subjectId = p.subject_id
          }else if (p.scope == 1){
            // 考试权限，即单个功能的权限
          }

          let row = idToPerm[permId]
          if (!row){
            row = {
              exam_id: parseInt(this.exam_id),
              user_id: this.clickUsers[Object.keys(this.clickUsers)[0]].user_id,
              permission: permId,
              subject_ids:[],
              question_ids:[],
              question_turn:[]
            }
            idToPerm[permId] = row
          }

          if (p.scope == 4){
            // 题组轮次权限
            questionId = parseInt(arr[2])
            turn = arr[3]

            let question = undefined
            for (let i = 0; i < row.question_turn.length; i++) {
              if (row.question_turn[i].question_id == questionId){
                question = row.question_turn[i]
                break
              }
            }
            if (question){
              question.turn.push(turn)
            }else {
              row.question_turn.push({question_id: questionId,turn: [turn]})
            }

          }else if (p.scope == 3){
            // 题组权限
            row.question_ids.push(questionId)
          }else if (p.scope == 2){
            // 科目权限
            row.subject_ids.push(subjectId)
          }

        })

        const toSavePerms = []
        Object.keys(idToPerm).forEach(k => {
          toSavePerms.push(idToPerm[k])
        })

        const resp = await axios.post("/api/exam/examPermission/saveUserPermission",{
          user_ids: [this.clickUsers[Object.keys(this.clickUsers)[0]].user_id],
          exam_id: parseInt(this.exam_id),
          permissions: toSavePerms
        })
        if (resp.data.success){
          this.$message.success("保存成功")
        }
      }
      else {
        const perms = this.getTreeSelect()
        const idToPerm = {}
        const userIds = Object.keys(this.clickUsers)
        perms.forEach( p => {
          let permId = undefined

          let questionId = undefined
          let turn = undefined
          let subjectId = undefined


          const arr = (p.permission_id + "").split("-")
          permId = parseInt(arr[0])
          if (p.scope == 4){
            // 题组轮次权限
            questionId = parseInt(arr[2])
            turn = arr[3]
          }else if (p.scope == 3){
            // 题组权限
            questionId = p.question_id
          }else if (p.scope == 2){
            // 科目权限
            subjectId = p.subject_id
          }else if (p.scope == 1){
            // 考试权限，即单个功能的权限
          }

          let row = idToPerm[permId]
          if (!row){
            row = {
              exam_id: parseInt(this.exam_id),
              permission: permId,
              subject_ids:[],
              question_ids:[],
              question_turn:[]
            }
            idToPerm[permId] = row
          }

          if (p.scope == 4){
            // 题组轮次权限
            questionId = parseInt(arr[2])
            turn = arr[3]

            let question = undefined
            for (let i = 0; i < row.question_turn.length; i++) {
              if (row.question_turn[i].question_id == questionId){
                question = row.question_turn[i]
                break
              }
            }
            if (question){
              question.turn.push(turn)
            }else {
              row.question_turn.push({question_id: questionId,turn: [turn]})
            }

          }else if (p.scope == 3){
            // 题组权限
            row.question_ids.push(questionId)
          }else if (p.scope == 2){
            // 科目权限
            row.subject_ids.push(subjectId)
          }

        })

        const toSavePerms = []
        Object.keys(idToPerm).forEach(k => {
          toSavePerms.push(idToPerm[k])
        })

        const resp = await axios.post("/api/exam/examPermission/saveUserPermission",{
          user_ids: userIds,
          exam_id: parseInt(this.exam_id),
          permissions: toSavePerms
        })
        if (resp.data.success){
          this.$message.success("保存成功")
          this.clickUsers = {}
        }
      }
    }
  },
  setup(){
    const appStore = useAppStoreWithOut()

    return {appStore}
  },
  mounted() {
    this.exam_id = this.appStore.exam_id
    this.getSubjectAndQuestionsByExamId()
    this.getAllPermission()
    this.getUsers().then((users) => {
      this.users = users
    })
  }
}
</script>

<template>
  <el-container class="main-container">
    <el-aside width="580px" style="padding: 15px">
      <el-segmented v-model="permsType" :options="['阅卷权限','考试管理员','考试学科教研员']"  style="margin-bottom: 5px" @change="segmentedChange"/>
      <el-space style="margin-bottom: 5px">
        <el-input placeholder="输入手机号或者姓名" v-model="userSearch.search"/>
        <el-button bg
                   text
                   type="primary"
                   icon="Search"
                   @click="async () => {
                     userSearch.page = 1
                     users = await getUsers()
                   }"
        >
          查找
        </el-button>
        <el-button bg
                   text
                   type="success"
                   icon="Edit"
                   @click="saveUserPerms"
                   v-show="permsType === '阅卷权限'"
        >
          保存权限
        </el-button>
        <el-switch
            v-show="permsType === '阅卷权限'"
            v-model="multi"
            :active-value="false"
            :inactive-value="true"
            @change="(val) => {
              if(val){
                clickUsers = {}
                this.$refs.tree.setCheckedKeys([])
              }
            }"
            class="ml-2"
            inline-prompt
            style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
            active-text="单个用户"
            inactive-text="多个用户"
        />
      </el-space>
      <ul class="infinite-list" style="overflow: auto">
        <li v-for="i in users"
            :key="i.user_id"
            class="infinite-list-item"
            v-infinite-scroll="scrollLoad"
            @click="clickUser(i)"
            :class="{'clickUser': clickUsers[i.user_id],'disabledUser': (permsType !== '阅卷权限' && examManagerUserIdMap[i.user_id] || examSubjectResearcherUserIdMap[i.user_id]) }"
        >
          [{{i.unit_name}}][{{i.user_account}}]{{i.user_name}}
        </li>
      </ul>
    </el-aside>
    <el-main>
      <!--阅卷权限-->
      <el-tree
        ref="tree"
        style="max-width: 600px"
        :data="permsTree"
        show-checkbox
        node-key="permission_id"
        :props="{children: 'subpermission',label: 'permission_name'}"
        v-if="permsType === '阅卷权限'"
    >
      <template #default="{ node, data }">
        <span>
           <span v-if="data.data_scope == 2" style="color: var(--el-color-primary)">
            [科目权限]
          </span>
          <span v-else-if="data.data_scope == 3" style="color: var(--el-color-success)">
            [题组权限]
          </span>
          <span v-else-if="data.data_scope == 4" style="color: var(--el-color-warning)">
            [题组轮次权限]
          </span>
          <span>{{ node.label }}</span>
        </span>
      </template>
    </el-tree>

      <!--考试管理员-->
      <ul class="infinite-list" style="overflow: auto;" v-else-if="permsType === '考试管理员'">
        <li v-for="i in examManagers"
            :key="i.user_id"
            class="infinite-list-item"
            @click="delExamManager(i.user_id)"
            v-infinite-scroll="scrollLoad"
        >
          [{{i.user_account}}]{{i.user_name}}
        </li>
      </ul>
      <!--考试学科教研员-->
      <div v-else>
        <el-radio-group v-model="examSubjectResearcherSubjectId"  @change="getExamSubjectResearcher">
          <el-radio-button
              v-for="item in subjectQuestionInfo.subjects"
              :key="item.subject_id"
              :label="item.subject_name"
              :value="item.subject_id"
          />
        </el-radio-group>
        <ul class="infinite-list" style="overflow: auto;">
          <li v-for="i in examSubjectResearchers"
              :key="i.user_id"
              class="infinite-list-item"
              @click="delExamSubjectResearcher(i.user_id)"
              v-infinite-scroll="scrollLoad"
          >

            {{idToSubjectMap[i.subject_id]?.subject_name}} [{{i.user_account}}]{{i.user_name}}
          </li>
        </ul>
      </div>
    </el-main>

  </el-container>
  <div>

  </div>
</template>

<style scoped>
.infinite-list {
  height: calc(100% - 100px);
  padding: 0;
  margin: 0;
  list-style: none;
}
.infinite-list .infinite-list-item {
  display: flex;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border: var(--el-color-primary) solid 1px !important;
  margin: 10px;
  color: var(--el-color-primary);
  cursor: pointer;
}
.clickUser{
  background: var(--el-color-primary-light-9);
}
.disabledUser{
  background: var(--el-color-info-light-9) !important;
  border: var(--el-color-info) solid 1px !important ;
  cursor: not-allowed !important;
  color: var(--el-color-info) !important;
}
</style>