<!--
带图层画布组件
最后编辑人：李正华
最后编辑时间：2022-3-21
最后编辑细节：增加固定放大缩小方法
-->
<template>
  <div class="canvas-container" :ref="uid + '_container'" @mousemove="handleContainerMove">
    <div class="canvas-holder">
      <canvas style="margin: 0;"
        @mousedown.stop.prevent="handleMouseDown"
        @mouseup.stop.prevent="handleMouseUp"
        @mousemove.stop.prevent="handleMouseMove"
        @mouseleave.stop.prevent="handleMouseLeave"
        @contextmenu.prevent
        :ref="uid + '_display_canvas'"></canvas>
      <div class="zoom-panel" v-if="isInZoom">
        <div>{{ Math.round(showScale * 100) }}%</div>
        <el-button class="zoom-button" icon="el-icon-zoom-in" @click="(ev) => {increaseScale(ev, 0.1)}"></el-button>
        <el-button class="zoom-button" icon="el-icon-zoom-out" @click="(ev) => {increaseScale(ev, -0.1)}"></el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayerCanvas",
  props: {
    /**
     * 图层列表，列表每个对象应该包含如下数据：
     * name：图层名称，可以为空，为空时无法根据图层名称获取到
     * image：Image或HTMLCanvasElement对象，不能为空
     * x: 图层左上角横坐标
     * y: 图层左上角纵坐标
     * w：图层宽度
     * h：图层高度
     * opacity：不透明度，数值从0.0-1.0，非必填，默认为1，不透明
     * selectable: 是否允许被选中，非必填，默认true
     */
    layerData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    validArea: {  //有效区域，用于限制缩放框
      type: Array,
      default: function() {
        return []
      }
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    //是否可以拖拽框选
    dragable: {
      type: Boolean,
      default: true
    },
    //图层是否可以缩放
    layerResizable: {
      type: Boolean,
      default: true
    },
    //是否可以缩放显示
    zoomable: {
      type: Boolean,
      default: false
    },
    afterZoom: {
      type: Function
    },
    beforeRender: {
      type: Function
    },
    afterRender: {
      type: Function
    }
  },
  data () {
    return {
      uid: null,  //组件id，自动生成
      timeID: null,  //定时器id
      showScale: 1.0, //显示比例
      displayCanvas: null,  //显示画布对象
      displayContext: null, //显示画布交互对象
      backCanvas: null,   //隐藏画布对象，用于绘制图层
      backContext: null,   //隐藏画布交互对象
      isMouseDown: false,
      isChanging: false,
      isChanged: false,
      isRendering: false,
      isInZoom: false,
      mDownPos: {x: 0, y: 0},
      downInResize: false,
      dragOrgPos: {x: 0, y: 0},
      dragNodeSize: 6,
      dragMode: "",
      selectLayers: [],
      forbidColor: "rgba(179,0,0,0.4)",
      forbidImage: null,
      onlyMove: false,  //限制只能移动，不能缩放
      isScrolling: false
    }
  },
  watch: {
    layerData: {
      immediate: true,
      handler() {
        this.isChanging = true;
        this.isChanging = false;
        this.isChanged = true;
      }
    },
    validArea: {
      immediate: true,
      handler() {
        this.isChanging = true;
        this.isChanging = false;
        this.drawForbidArea();
      }
    }
  },
  mounted() {
    this.uid = this.generateUUID(8, 16)
    this.$nextTick(() => {
      this.displayCanvas = this.$refs[this.uid + "_display_canvas"];
      this.displayContext = this.displayCanvas.getContext("2d");
      let body = this.displayCanvas.parentNode;
      this.displayCanvas.width = this.width > 0 ? this.width : body.clientWidth - 5;
      this.displayCanvas.height = this.height > 0 ? this.height : body.clientHeight - 5;
      this.backCanvas = document.createElement("canvas");
      this.backContext = this.backCanvas.getContext("2d");
      if (this.layerData.length > 0)
        this.isChanged = true;
      this.timeID = setInterval(this.checkChanged, 33);
    })
  },
  beforeUnmount() {
    clearInterval(this.timeID);
  },
  methods: {
    //生成UUID方法
    generateUUID (len, radix) {
      const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
      const uuid = []
      let i
      const rx = radix || chars.length

      if (len) {
        for (i = 0; i < len; i++)
          uuid[i] = chars[0 | Math.random() * rx]
      } else {
        let r
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
        uuid[14] = '4'
        for (i = 0; i < 36; i++) {
          if (!uuid[i]) {
            r = 0 | Math.random()*16
            uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r]
          }
        }
      }
      return uuid.join('');
    },
    handleContainerMove (ev) {
      const body = this.$refs[this.uid + "_container"];
      const mx = ev.clientX - body.offsetLeft;
      const my = ev.clientY - body.offsetTop;
      this.isInZoom = mx < 45 && my < 115 && this.zoomable;
    },
    //获取鼠标在画布里的坐标
    getClientPos(ev) {
      const rect = ev.target.getBoundingClientRect();
      const mx = ev.clientX - rect.x;
      const my = ev.clientY - rect.y;
      return {x: parseInt(mx / this.showScale), y: parseInt(my / this.showScale)};
    },
    handleMouseDown (ev) {
      const mPos = this.getClientPos(ev);
      const isValid = this.isInValidArea(mPos);
      if (isValid) {
        if (!this.isMouseDown && ev.button === 0) {
          this.mDownPos = mPos;
          this.isMouseDown = true;

          this.setDragMode(this.mDownPos);
        }
        this.$emit("mousedown", ev);
      }
    },
    handleMouseUp (ev) {
      const mPos = this.getClientPos(ev);
      const isValid = this.isInValidArea(mPos);
      if (isValid) {
        if (this.isMouseDown && this.dragable && this.dragMode === "" && Math.abs(mPos.x - this.mDownPos.x) > 8 && Math.abs(mPos.y - this.mDownPos.y) > 8) {
          const box = this.calcSelectBox(this.mDownPos.x, this.mDownPos.y, mPos.x, mPos.y);
          if (this.layerResizable) {
            this.setResizeBox(box.x, box.y, box.w, box.h);
          } else {
            this.selectLayers = []
            this.layerData.forEach(layer => {
              if (layer.x > box.x && layer.x + layer.w < box.x + box.w && layer.y > box.y && layer.y + layer.h < box.y + box.h)
                this.selectLayers.push(layer)
            })
          }
        }
        this.isMouseDown = false;
        this.downInResize = false;
        this.dragMode = "";
        if (this.dragable && this.dragMode === "" && Math.abs(mPos.x - this.mDownPos.x) < 2 && Math.abs(mPos.y - this.mDownPos.y) < 2) {
          this.removeResizeBox();
          this.$emit("click", ev);
        }
      }
      this.$emit("mouseup", ev);
    },
    handleMouseMove (ev) {
      const mPos = this.getClientPos(ev);
      const isValid = this.isInValidArea(mPos);
      if (isValid) {
        if (this.isMouseDown) {
          if (!this.dragable && this.layerResizable && this.hasResizeBox()) {
            if (this.dragMode !== "") {
              this.setDragLayer(mPos);
            }
            // else if (this.downInResize && Math.abs(mPos.x - this.mDownPos.x) > this.dragNodeSize * 3 && Math.abs(mPos.y - this.mDownPos.y) > this.dragNodeSize * 3) {
            //   console.log("inresize")
            //   const box = this.calcSelectBox(this.mDownPos.x, this.mDownPos.y, mPos.x, mPos.y);
            //   this.setResizeBox(box.x, box.y, box.w, box.h);
            // }
          } else if (this.dragable) {
            if (Math.abs(mPos.x - this.mDownPos.x) > this.dragNodeSize * 3 && Math.abs(mPos.y - this.mDownPos.y) > this.dragNodeSize * 3) {
              const box = this.calcSelectBox(this.mDownPos.x, this.mDownPos.y, mPos.x, mPos.y);
              this.drawSelectBox(box.x, box.y, box.w, box.h);
              const layer = this.findLayerByName("__select_box_layer");
              if (typeof layer === "undefined") {
                // eslint-disable-next-line vue/no-mutating-props
                this.layerData.push({
                  name: "__select_box_layer",
                  x: box.x,
                  y: box.y,
                  w: box.w,
                  h: box.h,
                  image: this.backCanvasToImage()
                })
              } else {
                layer.x = box.x;
                layer.y = box.y;
                layer.w = box.w;
                layer.h = box.h;
                layer.image = this.backCanvasToImage();
                this.isChanged = true;
              }
            }
          }
        }
        this.switchMouseCursor(mPos);
        this.$emit("mousemove", ev);
      }
      else {
        this.displayCanvas.style.cursor = "not-allowed";
      }
    },
    handleMouseLeave (ev) {
      this.$emit("mouseleave", ev);
    },
    //移除指定名称图层
    removeLayerByName (name) {
      if (typeof name === "string") {
        this.isChanging = true;
        for (let i = 0; i < this.layerData.length; i++){
          if (typeof this.layerData[i].name !== "undefined" && this.layerData[i].name === name)
              // eslint-disable-next-line vue/no-mutating-props
            this.layerData.splice(i, 1);
        }
        this.isChanging = false
      }
    },
    //计算两个坐标构成的方形
    calcSelectBox (x1, y1, x2, y2) {
      let x, y, w, h;
      if (x1 > x2) {
        x = x2;
        w = x1 - x2;
      }
      else {
        x = x1;
        w = x2 - x1;
      }
      if (y1 > y2) {
        y = y2;
        h = y1 - y2;
      }
      else {
        y = y1;
        h = y2 - y1;
      }
      return {x: x, y: y, w: w, h: h};
    },
    backCanvasToImage () {
      const img = new Image();
      img.src = this.backCanvas.toDataURL("image/png");
      return img;
    },
    //根据名称获取图层
    findLayerByName (name) {
      let layer;
      for (let i = this.layerData.length - 1; i >= 0; i--) {
        layer = this.layerData[i];
        if (layer.name === name)
          return layer;
      }
      return undefined
    },
    //获取指定坐标所在最上图层
    getPointLayer (x, y) {
      let layer;
      for (let i = this.layerData.length - 1; i >= 0; i--) {
        layer = this.layerData[i];
        if (layer.selectable === false)
          continue;
        if (layer.x <= x && layer.x + layer.w > x && layer.y <= y && layer.y + layer.h > y)
          return layer;
      }
      return undefined
    },
    //判断鼠标位置是否在有效区域内
    isInValidArea (mPos) {
      if (this.validArea.length === 0)
        return true;
      const valid = this.validArea.find(area => {
        return mPos.x >= area.x && mPos.x < area.x + area.w && mPos.y >= area.y && mPos.y < area.y + area.h
      });
      return typeof valid !== "undefined"
    },
    //根据坐标切换鼠标样式
    switchMouseCursor (mPos) {
      let allowed = this.isInValidArea(mPos);
      let pointLayer = this.getPointLayer(mPos.x, mPos.y);
      if (allowed) {
        if (typeof pointLayer !== "undefined" && pointLayer.name === "__select_box_layer") {
          const nodeSize = this.dragNodeSize / this.showScale
          if (!this.onlyMove) {
            if ((mPos.x >= pointLayer.x && mPos.x <= pointLayer.x + nodeSize && mPos.y >= pointLayer.y && mPos.y <= pointLayer.y + nodeSize) ||
                (mPos.x >= pointLayer.x + pointLayer.w - nodeSize && mPos.x <= pointLayer.x + pointLayer.w && mPos.y >= pointLayer.y + pointLayer.h - nodeSize && mPos.y <= pointLayer.y + pointLayer.h))
              this.displayCanvas.style.cursor = "nw-resize";
            else if ((mPos.x >= pointLayer.x + pointLayer.w - nodeSize && mPos.x <= pointLayer.x + pointLayer.w && mPos.y >= pointLayer.y && mPos.y <= pointLayer.y + nodeSize) ||
                (mPos.x >= pointLayer.x && mPos.x <= pointLayer.x + nodeSize && mPos.y >= pointLayer.y + pointLayer.h - nodeSize && mPos.y <= pointLayer.y + pointLayer.h))
              this.displayCanvas.style.cursor = "sw-resize";
            else if ((mPos.x > pointLayer.x + nodeSize && mPos.x < pointLayer.x + pointLayer.w - nodeSize && mPos.y >= pointLayer.y && mPos.y <= pointLayer.y + nodeSize) ||
                (mPos.x > pointLayer.x + nodeSize && mPos.x < pointLayer.x + pointLayer.w - nodeSize && mPos.y >= pointLayer.y + pointLayer.h - nodeSize && mPos.y <= pointLayer.y + pointLayer.h))
              this.displayCanvas.style.cursor = "n-resize";
            else if ((mPos.x >= pointLayer.x && mPos.x <= pointLayer.x + nodeSize && mPos.y >= pointLayer.y + nodeSize && mPos.y < pointLayer.y + pointLayer.h - nodeSize) ||
                (mPos.x >= pointLayer.x + pointLayer.w - nodeSize && mPos.x <= pointLayer.x + pointLayer.w && mPos.y > pointLayer.y + nodeSize && mPos.y < pointLayer.y + pointLayer.h - nodeSize))
              this.displayCanvas.style.cursor = "w-resize";
            else if (mPos.x >= pointLayer.x + nodeSize && mPos.x < pointLayer.x + pointLayer.w - nodeSize && mPos.y >= pointLayer.y + nodeSize && mPos.y < pointLayer.y + pointLayer.h - nodeSize)
              this.displayCanvas.style.cursor = "move";
          }
          else {
            if (mPos.x >= pointLayer.x + nodeSize && mPos.x < pointLayer.x + pointLayer.w - nodeSize && mPos.y >= pointLayer.y + nodeSize && mPos.y < pointLayer.y + pointLayer.h - nodeSize)
              this.displayCanvas.style.cursor = "move";
          }
        } else if (this.dragMode !== "") {
          if (this.dragMode === "n_w_resize" || this.dragMode === "s_e_resize")
            this.displayCanvas.style.cursor = "nw-resize";
          else if (this.dragMode === "n_e_resize" || this.dragMode === "s_w_resize")
            this.displayCanvas.style.cursor = "sw-resize";
          else if (this.dragMode === "n_resize" || this.dragMode === "s_resize")
            this.displayCanvas.style.cursor = "n-resize";
          else if (this.dragMode === "e_resize" || this.dragMode === "w_resize")
            this.displayCanvas.style.cursor = "w-resize";
          else if (this.dragMode === "move")
            this.displayCanvas.style.cursor = "move";
        } else
          this.displayCanvas.style.cursor = "default";
      }
      else
        this.displayCanvas.style.cursor = "not-allowed";
    },
    //设定拖拽模式
    setDragMode (mPos) {
      let pointLayer = this.getPointLayer(mPos.x, mPos.y);
      if (typeof pointLayer !== "undefined" && pointLayer.name === "__select_box_layer") {
        if (mPos.x >= pointLayer.x && mPos.x < pointLayer.x + pointLayer.w && mPos.y >= pointLayer.y && mPos.y < pointLayer.y +pointLayer.h) {
          const nodeSize = this.dragNodeSize / this.showScale
          if (!this.onlyMove && mPos.x >= pointLayer.x && mPos.x <= pointLayer.x + nodeSize && mPos.y >= pointLayer.y && mPos.y <= pointLayer.y + nodeSize)
            this.dragMode = "n_w_resize"; //左上缩放
          else if (!this.onlyMove && mPos.x > pointLayer.x + nodeSize && mPos.x < pointLayer.x + pointLayer.w - nodeSize && mPos.y >= pointLayer.y && mPos.y <= pointLayer.y + nodeSize)
            this.dragMode = "n_resize";  //上缩放
          else if (!this.onlyMove && mPos.x >= pointLayer.x + pointLayer.w - nodeSize && mPos.x <= pointLayer.x + pointLayer.w && mPos.y >= pointLayer.y && mPos.y <= pointLayer.y + nodeSize)
            this.dragMode = "n_e_resize"; //右上缩放
          else if (!this.onlyMove && mPos.x >= pointLayer.x + pointLayer.w - nodeSize && mPos.x <= pointLayer.x + pointLayer.w && mPos.y > pointLayer.y + nodeSize && mPos.y <= pointLayer.y + pointLayer.h - nodeSize)
            this.dragMode = "e_resize"; //右缩放
          else if (!this.onlyMove && mPos.x >= pointLayer.x + pointLayer.w - nodeSize && mPos.x <= pointLayer.x + pointLayer.w && mPos.y >= pointLayer.y + pointLayer.h - nodeSize && mPos.y <= pointLayer.y + pointLayer.h)
            this.dragMode = "s_e_resize"; //右下缩放
          else if (!this.onlyMove && mPos.x > pointLayer.x + nodeSize && mPos.x < pointLayer.x + pointLayer.w - nodeSize && mPos.y >= pointLayer.y + pointLayer.h - nodeSize && mPos.y <= pointLayer.y + pointLayer.h)
            this.dragMode = "s_resize"; //下缩放
          else if (!this.onlyMove && mPos.x >= pointLayer.x && mPos.x <= pointLayer.x + nodeSize && mPos.y >= pointLayer.y + pointLayer.h - nodeSize && mPos.y <= pointLayer.y + pointLayer.h)
            this.dragMode = "s_w_resize"; //左下缩放
          else if (!this.onlyMove && mPos.x >= pointLayer.x && mPos.x <= pointLayer.x + nodeSize && mPos.y > pointLayer.y + nodeSize && mPos.y <= pointLayer.y + pointLayer.h - nodeSize)
            this.dragMode = "w_resize"; //左缩放
          else if (mPos.x > pointLayer.x + nodeSize && mPos.x < pointLayer.x + pointLayer.w - nodeSize && mPos.y > pointLayer.y + nodeSize && mPos.y < pointLayer.y + pointLayer.h - nodeSize) {
            this.dragMode = "move";    //移动
            this.dragOrgPos = {x: pointLayer.x, y: pointLayer.y};
          }
          this.downInResize = true;
        }
      }
      else
        this.dragMode = "";
    },
    //计算拖动后选框大小
    setDragLayer (mPos) {
      let pointLayer = this.findLayerByName("__select_box_layer");
      let ex = pointLayer.x + pointLayer.w;
      let ey = pointLayer.y + pointLayer.h;
      let box;
      if (this.dragMode !== "move") {
        const nodeSize = this.dragNodeSize / this.showScale
        if (this.dragMode === "n_w_resize") {
          let sx = (mPos.x + nodeSize * 3 < ex) ? mPos.x : ex - nodeSize * 3;
          let sy = (mPos.y + nodeSize * 3 < ey) ? mPos.y : ey - nodeSize * 3;
          box = this.calcSelectBox(sx, sy, ex, ey);
        } else if (this.dragMode === "n_resize") {
          let sy = (mPos.y + nodeSize * 3 < ey) ? mPos.y : ey - nodeSize * 3;
          box = this.calcSelectBox(pointLayer.x, sy, ex, ey);
        } else if (this.dragMode === "n_e_resize") {
          let sy = (mPos.y + nodeSize * 3 < ey) ? mPos.y : ey - nodeSize * 3;
          ex = (pointLayer.x + nodeSize * 3 < mPos.x) ? mPos.x : pointLayer.x + nodeSize * 3;
          box = this.calcSelectBox(pointLayer.x, sy, ex, ey);
        } else if (this.dragMode === "e_resize") {
          let ex = (pointLayer.x + nodeSize * 3 < mPos.x) ? mPos.x : pointLayer.x + nodeSize * 3;
          box = this.calcSelectBox(pointLayer.x, pointLayer.y, ex, ey);
        } else if (this.dragMode === "s_e_resize") {
          ex = (pointLayer.x + nodeSize * 3 < mPos.x) ? mPos.x : pointLayer.x + nodeSize * 3;
          ey = (pointLayer.y + nodeSize * 3 < mPos.y) ? mPos.y : pointLayer.y + nodeSize * 3;
          box = this.calcSelectBox(pointLayer.x, pointLayer.y, ex, ey);
        } else if (this.dragMode === "s_resize") {
          ey = (pointLayer.y + nodeSize * 3 < mPos.y) ? mPos.y : pointLayer.y + nodeSize * 3;
          box = this.calcSelectBox(pointLayer.x, pointLayer.y, ex, ey);
        } else if (this.dragMode === "s_w_resize") {
          let sx = (mPos.x + nodeSize * 3 < ex) ? mPos.x : ex - nodeSize * 3;
          ey = (pointLayer.y + nodeSize * 3 < mPos.y) ? mPos.y : pointLayer.y + nodeSize * 3;
          box = this.calcSelectBox(sx, pointLayer.y, ex, ey);
        } else if (this.dragMode === "w_resize") {
          let sx = (mPos.x + nodeSize * 3 < ex) ? mPos.x : ex - nodeSize * 3;
          box = this.calcSelectBox(sx, pointLayer.y, ex, ey);
        }
        this.drawResizeBox(box.x, box.y, box.w, box.h);
        pointLayer.x = box.x;
        pointLayer.y = box.y;
        pointLayer.w = box.w;
        pointLayer.h = box.h;
        pointLayer.image = this.backCanvasToImage();
      }
      else {
        const vArea = this.validArea.find(area => {
          return this.mDownPos.x >= area.x && this.mDownPos.x < area.x + area.w && this.mDownPos.y >= area.y && this.mDownPos.y < area.y + area.h
        });
        pointLayer.x = this.dragOrgPos.x + mPos.x - this.mDownPos.x;
        if (this.validArea.length > 0 && pointLayer.x < vArea.x)
          pointLayer.x = vArea.x + 1;
        if (this.validArea.length > 0 && pointLayer.x + pointLayer.w >= vArea.x + vArea.w)
          pointLayer.x = vArea.x + vArea.w - pointLayer.w - 1;
        if (pointLayer.x < 0)
          pointLayer.x = 0;
        if (pointLayer.x > this.displayCanvas.width / this.showScale - pointLayer.w)
          pointLayer.x = this.displayCanvas.width / this.showScale - pointLayer.w;
        pointLayer.y = this.dragOrgPos.y + mPos.y - this.mDownPos.y;
        if (this.validArea.length > 0 && pointLayer.y < vArea.y)
          pointLayer.y = vArea.y + 1
        if (this.validArea.length > 0 && pointLayer.y + pointLayer.h >= vArea.y + vArea.h)
          pointLayer.y = vArea.y + vArea.h - pointLayer.h - 1
        if (pointLayer.y < 0)
          pointLayer.y = 0;
        if (pointLayer.y >  this.displayCanvas.height / this.showScale - pointLayer.h)
          pointLayer.y = this.displayCanvas.height / this.showScale - pointLayer.h;
      }
      this.isChanged = true;
    },
    //增加显示比例，负值为减小
    increaseScale (ev, mount) {
      ev.stopPropagation()
      this.setScale(mount)
    },
    //设置显示比例
    setScale(mount) {
      if (typeof mount === "number") {
        this.showScale += mount;
        if (this.showScale > 2)
          this.showScale = 2;
        if (this.showScale < 0.2)
          this.showScale = 0.2;
        let layer = this.getResizeLayer()
        if (typeof layer !== "undefined") {
          this.drawResizeBox(layer.x, layer.y, layer.w, layer.h);
          layer.image = this.backCanvasToImage();
        }
        this.isChanged = true;
        // this.drawForbidArea();
        if (typeof this.afterZoom === "function")
          this.afterZoom()
      }
    },
    //放大图像
    zoomIn () {
      this.setScale(0.1)
    },
    //缩小图像
    zoomOut () {
      this.setScale(-0.1)
    },
    //缩放至宽度适合父元素
    zoomFitWidth () {
      const body = this.displayCanvas.parentNode;
      const bodyWidth = body.clientWidth - 5;
      let layerWidth = 0;
      if (this.layerData.length > 0) {
        this.layerData.forEach((layer) => {
          const w = (layer.x + layer.w);
          if (w > layerWidth)
            layerWidth = w;
        });
      }
      if (layerWidth > 0) {
        this.showScale = Number((bodyWidth / layerWidth).toFixed(2));
        this.isChanged = true;
        if (typeof this.afterZoom === "function")
          this.afterZoom()
      }
    },
    checkChanged () {
      if (this.isChanged && !this.isChanging && !this.isRendering) {
        this.renderLayers();
      }
    },
    //重设画布尺寸
    resizeCanvasByLayers () {
      if (this.displayCanvas == null)
        return;
      const body = this.displayCanvas.parentNode;
      const bodyWidth = body.clientWidth - 5;
      const bodyHeight = body.clientHeight - 5;
      let layerWidth = 0, layerHeight = 0;
      if (this.layerData.length > 0) {
        this.layerData.forEach((layer) => {
          const w = (layer.x + layer.w) * this.showScale;
          const h = (layer.y + layer.h) * this.showScale;
          if (w > layerWidth)
            layerWidth = w;
          if (h > layerHeight)
            layerHeight = h;
        });
      }
      this.displayCanvas.width = bodyWidth > layerWidth ? bodyWidth : layerWidth;
      this.displayCanvas.height = bodyHeight > layerHeight ? bodyHeight : layerHeight;
    },
    //绘制所有图层
    renderLayers () {
      if (this.displayCanvas == null || this.isRendering)
        return;
      let allLoaded = true;
      this.layerData.forEach(layer => {
        if (layer.image != null && !layer.image.complete)
          allLoaded = false
      });
      if (!allLoaded) {
        return;
      }
      if (typeof this.beforeRender === "function")
        this.beforeRender();
      this.isRendering = true;
      this.isChanged = false;
      this.resizeCanvasByLayers();
      this.displayContext.fillStyle = "#ffffff";
      this.displayContext.fillRect(0, 0, this.displayCanvas.width, this.displayCanvas.height);
      this.layerData.forEach(layer => {
        if (typeof layer !== "undefined" && layer.image != null && layer.w > 0 && layer.h > 0) {
          this.displayContext.globalAlpha = (typeof layer.opacity === "number") ? layer.opacity : 1.0;
          this.displayContext.drawImage(layer.image, 0, 0, layer.w, layer.h,
              layer.x * this.showScale, layer.y * this.showScale, layer.w * this.showScale, layer.h * this.showScale);
        }
      });
      if (this.validArea.length > 0 && this.forbidImage != null && this.forbidImage.complete) {  //如果有效区域存在，怎暗化非有效区域
        this.displayContext.drawImage(this.forbidImage, 0, 0, this.displayCanvas.width, this.displayCanvas.height);
      }
      this.isRendering = false;
      this.isChanged = false;
      if (typeof this.afterRender === "function")
        this.afterRender();
    },
    //绘制禁止区域
    drawForbidArea () {
      if (this.validArea.length > 0 && this.layerData.length > 0) {
        this.backCanvas.width = this.displayCanvas.width;
        this.backCanvas.height = this.displayCanvas.height;
        this.backContext.clearRect(0, 0, this.backCanvas.width, this.backCanvas.height);
        this.backContext.fillStyle = this.forbidColor;
        this.backContext.fillRect(0, 0, this.backCanvas.width, this.backCanvas.height);
        this.validArea.forEach(area => {
          this.backContext.clearRect(area.x * this.showScale, area.y * this.showScale, area.w * this.showScale, area.h * this.showScale);
        })
        this.forbidImage = this.backCanvasToImage()
        this.forbidImage.onload = () => {
          this.isChanged = true;
        }
      }
      else
        this.forbidImage = null;
    },
    //绘制选择框
    drawSelectBox (x, y, w, h) {
      this.backCanvas.width = w;
      this.backCanvas.height = h;
      let lw = Math.ceil(1 / this.showScale);
      if (lw === 0)
        lw = 1;
      let hlw = lw === 1 ? 1 : Math.round(lw / 2);
      this.backContext.lineWidth = lw
      this.backContext.clearRect(0, 0, w, h);
      this.backContext.fillStyle = "rgba(44, 128, 197, 0.3)";
      this.backContext.fillRect(0, 0, w, h);
      this.backContext.strokeStyle = "#2C80C5";
      this.backContext.strokeRect(hlw, hlw, w - hlw * 2, h - hlw * 2);
    },
    //绘制缩放框
    drawResizeBox (x, y, w, h) {
      if (w > 10 && h > 10) {
        this.backCanvas.width = w;
        this.backCanvas.height = h;
        this.backContext.clearRect(0, 0, w, h);
        let lw = Math.ceil(1 / this.showScale);
        if (lw === 0)
          lw = 1;
        let hlw = lw === 1 ? 1 : Math.round(lw / 2);
        const nodeSize = this.dragNodeSize / this.showScale
        this.backContext.lineWidth = lw
        this.backContext.strokeStyle = "#2C80C5";
        this.backContext.strokeRect(hlw, hlw, w - hlw * 2, h - hlw * 2);
        if (this.onlyMove) {  //限定只能移动时添加遮盖层
          this.backContext.fillStyle = "rgba(44, 128, 197, 0.15)";
          this.backContext.fillRect(0, 0, w, h);
        }
        else {  //绘制缩放操作点
          this.backContext.fillStyle = "#2C80C5";
          this.backContext.fillRect(lw, lw, nodeSize, nodeSize);
          this.backContext.strokeRect(lw, lw, nodeSize, nodeSize);
          this.backContext.fillRect(w / 2 - nodeSize / 2, lw, nodeSize, nodeSize);
          this.backContext.strokeRect(w / 2 - nodeSize / 2, lw, nodeSize, nodeSize);
          this.backContext.fillRect(w - nodeSize - lw, lw, nodeSize, nodeSize);
          this.backContext.strokeRect(w - nodeSize - lw, lw, nodeSize, nodeSize);
          this.backContext.fillRect(lw, h / 2 - nodeSize / 2, nodeSize, nodeSize);
          this.backContext.strokeRect(lw, h / 2 - nodeSize / 2, nodeSize, nodeSize);
          this.backContext.fillRect(w - nodeSize - lw, h / 2 - nodeSize / 2, nodeSize, nodeSize);
          this.backContext.strokeRect(w - nodeSize - lw, h / 2 - nodeSize / 2, nodeSize, nodeSize);
          this.backContext.fillRect(lw, h - nodeSize - lw, nodeSize, nodeSize);
          this.backContext.strokeRect(lw, h - nodeSize - lw, nodeSize, nodeSize);
          this.backContext.fillRect(w / 2 - nodeSize / 2, h - nodeSize - lw, nodeSize, nodeSize);
          this.backContext.strokeRect(w / 2 - nodeSize / 2, h - nodeSize - lw, nodeSize, nodeSize);
          this.backContext.fillRect(w - nodeSize - lw, h - nodeSize - lw, nodeSize, nodeSize);
          this.backContext.strokeRect(w - nodeSize - lw, h - nodeSize - lw, nodeSize, nodeSize);
        }
      }
    },
    //设置缩放框
    setResizeBox (x, y, w, h) {
      this.drawResizeBox(x, y, w, h);
      const layer = this.findLayerByName("__select_box_layer");
      if (typeof layer === "undefined") {
        // eslint-disable-next-line vue/no-mutating-props
        this.layerData.push({
          name: "__select_box_layer",
          x: x,
          y: y,
          w: w,
          h: h,
          image: this.backCanvasToImage()
        })
      }
      else {
        layer.x = x;
        layer.y = y;
        layer.w = w;
        layer.h = h;
        layer.image = this.backCanvasToImage();
        this.isChanged = true;
      }
    },
    //移除缩放框
    removeResizeBox () {
      this.removeLayerByName("__select_box_layer")
    },
    //获取缩放图层
    getResizeLayer () {
      return this.findLayerByName("__select_box_layer")
    },
    //获取缩放图层所在位置
    getResizeLayerIndex () {
      const rl = this.findLayerByName("__select_box_layer")
      return typeof rl === "undefined" ? -1 : this.layerData.indexOf(rl)
    },
    //判断是否有缩放图层
    hasResizeBox () {
      const find = this.getResizeLayer()
      return typeof find !== "undefined"
    },
    setOnlyMove () {
      this.onlyMove = true
    },
    cancelOnlyMove () {
      this.onlyMove = false
    },
    //滚动到缩放框
    scrollToResize () {
      const holder = this.displayCanvas.parentNode;
      const layer = this.getResizeLayer();
      if (typeof layer !== "undefined") {
        const x = layer.x * this.showScale
        const y = layer.y * this.showScale
        let l = x >= holder.scrollWidth && x < holder.scrollWidth + holder.clientWidth ? holder.scrollWidth : x - 20;
        if (l < 0)
          l = 0;
        let t = y >= holder.scrollHeight && y < holder.scrollHeight + holder.clientHeight ? holder.scrollHeight : y - 20;
        if (t < 0)
          t = 0;
        holder.scrollTo({left: l, top: t, behavior: "smooth"});
      }
    },
    //滚动目标距离
    scrollBy (left, top) {
      if (!this.isScrolling) {
        this.isScrolling = true
        const holder = this.displayCanvas.parentNode;
        holder.scrollBy(-left, -top);
        setTimeout(() => {this.isScrolling = false;}, 30)
      }

    }
  }
}
</script>

<style scoped>
.canvas-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
}

.canvas-holder {
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: auto;
}

.zoom-panel {
  position: absolute;
  top: 5px;
  left: 5px;
  display: flex;
  flex-direction: column;
  opacity: 0.8;
}

.zoom-button {
  margin: 0;
  padding: 5px;
  height: 30px;
  width: 30px;
  font-size: 18px;
}
.zoom-button+.zoom-button {
  margin-top: 10px;
  margin-left: 0;
}
</style>